import { StyleSheet, Text, View } from "react-native";
import React from "react";
import Layout from "~components/atoms/Layout/index.web";
import Heading from "~components/atoms/Heading";
import TextInputs from "~components/atoms/TextInputs";
import { useCustomFonts } from "~helpers/fonts";
import AppLoading from "expo-app-loading";

const ActivityDetail = () => {
  const [fontsLoaded, error] = useCustomFonts();
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  return (
    <Layout>
    <View style={styles.container}>
      <Heading>NEW ACTIVITY</Heading>
    </View>
    </Layout>
  );
};

export default ActivityDetail;

const styles = StyleSheet.create({
  container: {
   
  },
  labels: {
    fontFamily: "OpenSans_400Regular",
    fontWeight: 'bold'
  },
});
