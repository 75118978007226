import { StyleSheet, Text, View, ViewStyle } from "react-native";
import React from "react";

interface Props {
  children: any;
  style?: ViewStyle;
}

const Heading: React.FunctionComponent<Props> = ({ children, style }) => {
  return <Text style={[styles.headerStyle, style]}>{children}</Text>;
};

export default Heading;

const styles = StyleSheet.create({
  headerStyle: {
    fontSize: 20,
    fontWeight: "700",
    color: "#413E3E",
    paddingBottom: 10,
    backgroundColor: "white",
  },
});
