import * as Localization from "expo-localization";
import { I18n } from "i18n-js";

// Set the key-value pairs for the different languages you want to support.
const translations = {
  en: {
    createCampaign: {
      heading: "New Campaign",
      campaignName: "Campaign Name",
      aboutCampaign: "About Campaign",
      uploadBanner: "Upload Campaign Banner",
      browse: "Browse",
      goal: "Goal",
      org: "Organizations",
      roles: "Roles",
      rewardScheme: "Reward Scheme",
      select: "Select",
      create: "Create",
      schemeName: "Scheme Name",
      selectEvent: "Select Event",
      creatorPoints: "Creator Points",
      ownerPoints: "Owner Points",
      add: "Add",
      campaignSchedule: "Campaign Schedule",
      cancel: "Cancel",
      save: "Save",
      startAndEnd: "Start And End Manually",
      schedule: "Schedule",
      start: "Start",
      end: "End",
      action: "Action",
      nonSalesPoints: "Non-Sales Points",
      salesPoints: "Sales Points",
    },
    campaignHome: {
      leads: "Leads",
      won: "Won",
      inProgress: "In Progress",
      success: "Success",
      leader: "Leader",
      fromDate: "Campaign Running From ",
      tillDate: "till",
    },
    campaignDetails: {
      startOn: "Started On: ",
      endOn: "Ends On: ",
      successRate: "Success Rate",
      performance: "Performance",
      totalLeadsGenerated: "Total Leads Generated",
      weeklyLeadGeneration: "Weekly Lead Generation",
      leadsWon: "Leads Won",
      leadsLost: "Leads Lost",
      leadClosureRate: "Lead Closure Rate",
      revenue: "Revenue",
      aboutCampaign: "About Campaign",
      leaderboard: "Leaderboard",
      wins: "Wins",
      points: "Points",
      leads: "Leads",
    },
    login: {
      // userName: "Enter your username",
      noAccount: " No Account ?",
      password: "Password",
      forgot: "Forgot",
      proceed: "Proceed",
      welcome: "Welcome to ",
      sign: "Sign in",
      signUp: "Sign Up",
      userName: "Email address",
      placeHolder1: "Enter email address",
      placeHolder2: "Enter password",
      login: "Login",
      signIn: "Sign in to your account",
      placeholder: "Enter email address",
      placeholder3: "Enter password",
      forgot1: "Forgot",
      link: "Password?",
      register: "Register new account",
      registerLink: "Register",
      clickRegisterLink: "Click here to ",
    },
    signUp: {
      welcome: "Welcome to ",
      sign: "Sign up",
      account: "Have an Account ?",
      signIn: "Sign in",
      emailAdd: "Email address",
      name: "Name",
      placeHolder1: "Enter email address",
      placeHolder2: "Enter Name",
      accept: " I accept the",
      terms: "Terms and Conditions",
      proceed: "Proceed",
      details: "Please enter your details",
      anaccount: "Already have an account?",
    },
    registerSuccess: {
      thankYou: "Thank you for registering!",
      confirmation:
        "Your request is accepted and subject to approval. You will receive an email from leadx with further communication.",
      signIn: "Sign In",
    },
    forgotPass: {
      forgot: "Forgot password?",
      instruction:
        "Enter your Registered Email Address To Receive Reset Password Link ",
      email: "Email Address",
      placeholder: "Enter your email address",
      proceed: "Request email to reset password",
      resend: "Resend Email",
    },
    resetSuccess: {
      title: "Congratulations!",
      message: "You have successfully reset your password.",
      signIn: "Sign In",
    },
    resetPass: {
      welcome: "Welcome to",
      sign: "Sign up",
      password: "Enter a new password",
      reEnter: "Re-enter your Password",
      placeholder: "Enter email address",
      button: "Send",
      reset: "ResetPassword",
    },
    signInSuccess: {
      success: "You Have Logged In Successfully",
      redirect: "Redirecting To Home",
    },
    mfa: {
      welcome: "Welcome to",
      code: "Enter code",
      enterCode: "Please enter the 6 digit code sent to your registered",
      email: "email id ",
      enter: "Please enter the 6 digit code sent to your registered email id",
      code1: "Enter the code sent on your Email id",
      placeHolder: "Enter your code",
      valid: "Code is valid for 15 min",
      code2: "Did not receive code?",
      resendCode: "Resend Code",
      verify: "Verify",
    },
    home: {
      hello: "Hello",
      feeds: "Feeds",
      leadprojects: "Lead Projects",
      news: "News",
      salescommunity: "Sales Community",
      workspaces: "Lead Workspaces",
      shop: "Shop @ Leadx",
      dashboard: "Dashboards",
      mypipeline: "My Pipeline",
      activities: "Activities",
      kpi: "KPI",
      leads: "Leads",
      contacts: "Contacts",
      campaigns: "Campaigns",
    },
    leftmenu: {
      home: "Home",
      mypipeline: "My Pipeline",
      salescommunity: "Sales Community",
      activities: "Activities",
      pulse: "Pulse",
      administration: "Administration",
      contacts: "Contacts",
      companies: "Companies",
      campaigns: "Campaigns",
      shop: "Shop",
      newsfeeds: "News & Feed",
      lead: "Lead",
    },
    bottomnavigation: {
      home: "Home",
      create: "Create",
      createlead: "Lead",
      createactivity: "Activity",
      createcampaign: "Campaign",
      createsalesnews: "Sales News",
      createcompany: "Company",
      createcontact: "Contact",
      notification: "Notifications",
    },
    lead: {
      lead_title: "Lead Title",
      company: "Company",
      contact_person_name: "Contact person name",
      phone_number: "Phone number",
      email_address: "Email address",
      linkedin: "LinkedIn URL",
      upload_photo_document: "Upload Photo/Document",
      handover_to_sales: "Handover to sales",
      note: "Note",
      expected_closing_date: "Expected closing date",
      category: "Category",
      project_location: "Project location",
      lead_source: "Lead source",
      lead_value: "Lead value",
      currency: "Currency",
      lead_value_confidential: " Lead Value Is Confidential",
      browse: "Browse",
      accept_as_lead: "Accept as lead",
      assign_to: "Assign to",
      team: " Team Member",
      select_Team_Member: "Select Team Member",
      pipeline_stage: "Pipeline stage",
      org: "Organization",
      contact_person_phone: "Contact person phone",
      sales_value: "Sales value",
      lead_creation_date: "Lead creation date",
      created_by: "Created by",
      assigned_to: "Assigned to",
      most_recent_note: "Most recent note",
      yes: "Yes",
      no: "No",
      won: "Won",
      lost: "Lost",
      parked: "Parked",
      edit_lead: "Update / Edit lead",
      new_lead: "New lead",
      prev: "Prev",
      next: "Next",
      lead: "Lead",
      contact: "Contact",
      save: "Save",
      update: "Update",
      unpark: "Unpark",
      planned_events: "Planned events",
      history: "History",
      notes: "Notes",
      opportunity: "Opportunity",
      proposal: "Proposal",
      negotiation: "Negotiation",
      closed: "Closed",
      confirm: "Confirmation",
      existing: "Use Existing",
      create_new: "Create New",
      capture: "Capture photo",
      select_lead: "Select lead source",
      lead_info: "Lead info",
      select_stage: "Select Stage",
    },
  },
  ja: { welcome: "こんにちは" },
};
export const i18n = new I18n(translations);

// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;

// When a value is missing from a language it'll fallback to another language with the key present.
i18n.enableFallback = true;
