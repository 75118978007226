import React, { useState } from "react";
import { StyleSheet, Image, Text, View } from "react-native";
import Button from "~components/atoms/Button";
import Input from "~components/atoms/TextInput";
import AppLoading from "expo-app-loading";
import Link from "~components/atoms/Link";
import styles from "./styleWeb";
import { useResolution } from "~helpers/utilities";

// import i18n from "~components/molecules/i18n";
import { i18n } from "~Utils/i18n";
import { useCustomFonts } from "~helpers/fonts";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .required("Please enter your email address"),
  password: Yup.string()
    .min(8, "Password should be at least 8 character long")
    .max(50, "Password is too long")
    .required("Password is required"),
});

interface Props {
  handleSignUp: () => void;
  handleForgotPassword: () => void;
  handleProceed: () => void;
}

const LoginRenderer: React.FC<Props> = ({
  handleSignUp,
  handleForgotPassword,
  handleProceed,
  isLoading,
  showRegister,
}) => {
  const [aspectRatio, setAspectRatio] = useState(0);
  const resolution = useResolution();
  const handleOnLoad = (e) => {
    setAspectRatio(
      e.nativeEvent.path[0].naturalWidth / e.nativeEvent.path[0].naturalHeight
    );
  };
  const [fontsLoaded, error] = useCustomFonts();
  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={LoginSchema}
      onSubmit={(values) => console.log("values", values)}
    >
      {({
        handleChange,
        setFieldValue,
        isValid,
        handleBlur,
        setFieldTouched,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
        <View style={styles.container}>
          <View style={resolution !== "small" ? [{ marginVertical: 30 }] : []}>
            <Image
              onLoad={handleOnLoad}
              source={{
                uri: "https://s3.eu-north-1.amazonaws.com/live-media.leadx.no/Leadx-staging/users/1655113413413_users.png",
              }}
              style={[
                styles.logoStyle,
                {
                  aspectRatio: aspectRatio,
                },
              ]}
              resizeMode={"contain"}
            />
          </View>
          <View>
            <View style={styles.mainView}>
              <Text style={styles.text}>{i18n.t("login.userName")}</Text>
              <View style={styles.inputStyle}>
                <Input
                  style={styles.input}
                  placeholder={i18n.t("login.placeHolder1")}
                  value={values.email}
                  onChangeText={handleChange("email")}
                  onBlur={() => setFieldTouched("email")}
                />
                {touched.email && errors.email && (
                  <Text style={{ color: "red" }}>{errors.email}</Text>
                )}
              </View>
              <Text style={styles.text}>{i18n.t("login.password")}</Text>
              <Input
                style={styles.input}
                placeholder={i18n.t("login.placeHolder2")}
                secureTextEntry={true}
                value={values.password}
                onChangeText={handleChange("password")}
                onBlur={() => setFieldTouched("password")}
              />
              {touched.password && errors.password && (
                <Text style={{ color: "red" }}>{errors.password}</Text>
              )}
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.test}>{i18n.t("login.forgot")}</Text>
                <Link
                  style={[styles.linkStyle]}
                  title={i18n.t("login.link")}
                  onPress={handleForgotPassword}
                />
              </View>
            </View>

            <View style={styles.buttonView}>
              <Button
                loading={isLoading}
                style={[styles.button]}
                title={i18n.t("login.proceed")}
                onPress={() => handleProceed(values.email, values.password)}
                disabled={
                  !isValid || values.email === "" || values.password === ""
                }
              />
            </View>
            {showRegister && (
              <View style={styles.viewLink}>
                <Text>{i18n.t("login.clickRegisterLink")}</Text>
                <Link
                  style={[styles.linkStyle, { fontWeight: "700" }]}
                  title={i18n.t("login.registerLink")}
                  onPress={handleSignUp}
                />
              </View>
            )}
          </View>
        </View>
      )}
    </Formik>
  );
};

export default LoginRenderer;
