import { StyleSheet, Text, View, Image } from "react-native";
import React from "react";
import Layout from "~components/atoms/Layout/index.web";
import Heading from "~components/atoms/Heading";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "~components/atoms/Button";
import { useNavigation } from "@react-navigation/native";
import Input from "~components/atoms/TextInput";
import style from "../../../../storybook/stories/CenterView/style";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useCustomFonts } from "~helpers/fonts";
import AppLoading from "expo-app-loading";

const companyFormSchema = Yup.object().shape({
  acitivity: Yup.string(),
  type: Yup.string(),
  selectCompany: Yup.string(),
  selectLead: Yup.string(),
  note: Yup.string(),
});

const newActivity = [
  {
    lable: "Activity",
    index: 1,
    formId: "acitivity",
    placeholder: " Call Adam Smith",
  },
  {
    lable: "Type",
    index: 2,
    formId: "type",
    placeholder: "Phone Call",
  },
  {
    lable: "Select Company",
    index: 3,
    formId: "selectCompany",
    placeholder: "ABC Crop",
  },
  {
    lable: "Select Lead",
    index: 4,
    formId: "selectLead",
    placeholder: "",
  },
  {
    lable: "Note",
    index: 5,
    formId: "note",
    placeholder: "",
  },
];

const date = [{ name: "start" }, { name: "End" }];

const CreateActivity = () => {
  const navigation = useNavigation();
  const [fontsLoaded, error] = useCustomFonts();

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <Formik
      validationSchema={companyFormSchema}
      initialValues={{
        companyName: "",
        companyRegNumber: "",
      }}
      onSubmit={(values) => {
        console.log("values", values);
      }}
    >
      {({
        handleChange,
        setFieldValue,
        isValid,
        handleBlur,
        setFieldTouched,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
        <Layout>
          <View style={styles.container}>
            <View style={styles.cancelBtn}>
              <Text style={styles.cancel}>Cancel</Text>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("Activities");
                }}
              >
                {/* <Image 
              style={styles.cancelImg} 
              source={require('../../../Assets/Images/cancel.png')}
            /> */}
              </TouchableOpacity>
            </View>
            <View style={styles.box}>
              <Heading style={styles.heading}>NEW ACTIVITY</Heading>

              {newActivity.map((item) => (
                <View style={styles.card}>
                  <Text style={styles.inputs}>{item.lable}</Text>
                  <Input
                    style={{ height: 30 }}
                    placeholder={item.placeholder}
                    value={values[item.formId]}
                    onChangeText={handleChange(`${item.formId}`)}
                    onBlur={handleBlur(`${item.formId}`)}
                  />
                  {touched[item.formId] && errors[item.formId] && (
                    <Text style={{ color: "red" }}>{errors[item.formId]}</Text>
                  )}
                </View>
              ))}
              <View style={styles.date}>
                {date.map((item) => (
                  <View style={styles.dateRow}>
                    <Text style={styles.input}>{item.name}</Text>
                    <Input style={{ height: 30 }} />
                  </View>
                ))}
              </View>
              <View style={styles.btn}>
                <Button
                  style={[styles.oneBtn, { backgroundColor: "#D9D9D9" }]}
                  title="Cancel"
                />
                <Button style={styles.oneBtn} title="Save" />
                <Button
                  style={[styles.oneBtn, { backgroundColor: "#06949E" }]}
                  title="Edit"
                  onPress={() => {
                    navigation.navigate("EditActivity");
                  }}
                />
              </View>
            </View>
          </View>
        </Layout>
      )}
    </Formik>
  );
};

export default CreateActivity;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: "25%",
  },
  cancel: {
    marginRight: 10,
    marginBottom: 15,
    alignItems: "center",
    fontFamily: "OpenSans_400Regular",
  },
  cancelBtn: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  cancelImg: {
    height: 15,
    width: 15,
  },
  card: {
    marginRight: 30,
    fontWeight: 400,
    marginLeft: 30,
  },
  box: {
    backgroundColor: "white",
    borderWidth: 0.5,
    borderRadius: 15,
    paddingHorizontal: 5,
    paddingVertical: 3,
  },
  heading: {
    paddingLeft: "40%",
    fontFamily: "OpenSans_400Regular",
  },
  inputs: {
    borderColor: "#DCDADA",
    fontWeight: "bold",
    marginHorizontal: 10,
    margin: 5,
    fontFamily: "OpenSans_400Regular",
  },
  input: {
    borderColor: "#DCDADA",
    fontWeight: "bold",
    marginHorizontal: 5,
    margin: 5,
    marginTop: 12,
    fontFamily: "OpenSans_400Regular",
  },
  date: {
    flexDirection: "row",
    marginVertical: 25,
    marginHorizontal: 12,
  },
  dateRow: {
    flexDirection: "row",
    paddingHorizontal: 15,
  },
  btn: {
    flexDirection: "row",
    marginVertical: 15,
    marginHorizontal: 30,
    justifyContent: "flex-end",
  },
  oneBtn: {
    marginHorizontal: 10,
    width: "20%",
  },
});
