import { IResopnse } from "~Types/ApiResponse";
import SecureStorage from "~Utils/SecureStorage";
import ApiCall, { ACCESS_TOKEN_KEY } from "./ApiCall";

export default class Api {
  static axios = ApiCall;

  static async get(url: string): Promise<IResopnse<any>> {
    const response = await Api.axios.get(url);
    return response.data;
  }

  static async post(url: string, data: object): Promise<IResopnse<any>> {
    const response = await Api.axios.post(url, data);
    if (response.status !== 200) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  }

  static async postWithToken(
    url: string,
    data: object
  ): Promise<IResopnse<any>> {
    const token = await SecureStorage.getItem(ACCESS_TOKEN_KEY);
    console.log("token", token);
    const response = await Api.axios.request({
      method: "post",
      url: url,
      data: data,
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
    if (response.status !== 200) {
      throw new Error(response.data.errorMessage);
    }
    return response.data;
  }

  static async getWithToken(url: string): Promise<IResopnse<any>> {
    const token = await SecureStorage.getItem(ACCESS_TOKEN_KEY);
    const response = await Api.axios.request({
      method: "get",
      url: url,
    });
    return response.data;
  }
}
