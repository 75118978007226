import { Image, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import Common from "~components/molecules/commonpages/index.web";
import { useNavigation } from "@react-navigation/native";
import Input from "~components/atoms/TextInput";
import Button from "~components/atoms/Button";
import styles from "./style.web.js";
import { i18n } from "~Utils/i18n";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password should be at least 8 character long")
    .max(50, "Password is too long")
    .required("Password is required"),

  confirmPassword: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("password")], "Passwords do not match."),
});

const ResetPassword = () => {
  const navigation = useNavigation();
  const [aspectRatio, setAspectRatio] = useState(0);
  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={ResetPasswordSchema}
      onSubmit={(values) => {
        console.log("values", values);
        navigation.navigate("ResetSuccess");
      }}
    >
      {({
        handleChange,
        setFieldValue,
        isValid,
        handleBlur,
        setFieldTouched,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
        <Common>
          <View style={styles.container}>
            <View style={{ marginVertical: 30 }}>
              <Image
                onLoad={(e) => {
                  setAspectRatio(
                    e.nativeEvent.path[0].naturalWidth /
                      e.nativeEvent.path[0].naturalHeight
                  );
                }}
                source={{
                  uri: "https://s3.eu-north-1.amazonaws.com/live-media.leadx.no/Leadx-staging/users/1655113413413_users.png",
                }}
                style={[
                  styles.logoStyle,
                  {
                    aspectRatio: aspectRatio,
                  },
                ]}
                resizeMode={"contain"}
              />
            </View>

            <View style={styles.viewContainer}>
              <View style={styles.mainView}>
                <Text style={styles.text}>{i18n.t("resetPass.password")}</Text>
                <View style={styles.inputstyle}>
                  <Input
                    style={styles.input}
                    placeholder={i18n.t("resetPass.password")}
                    secureTextEntry={true}
                    value={values.password}
                    onChangeText={handleChange("password")}
                    onBlur={() => setFieldTouched("password")}
                  />
                  {touched.password && errors.password && (
                    <Text style={styles.error}>{errors.password}</Text>
                  )}
                </View>
                <Text style={styles.text}>Re-enter your Password</Text>
                <Input
                  style={styles.input}
                  placeholder={i18n.t("resetPass.reEnter")}
                  secureTextEntry={true}
                  value={values.confirmPassword}
                  onChangeText={handleChange("confirmPassword")}
                  onBlur={() => setFieldTouched("confirmPassword")}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <Text style={styles.error}>{errors.confirmPassword}</Text>
                )}
              </View>

              <View style={styles.buttonView}>
                <Button
                  style={styles.button}
                  title={i18n.t("resetPass.button")}
                  onPress={handleSubmit}
                  disabled={
                    !isValid ||
                    values.password === "" ||
                    values.confirmPassword === ""
                  }
                />
              </View>
            </View>
          </View>
        </Common>
      )}
    </Formik>
  );
};

export default ResetPassword;
