import React from "react";
import Entry from "~Screens/Entry";
import store from "~Redux/store";
import { Provider } from "react-redux";

import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "~Contexts/Theme";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { NavigationContainer } from "@react-navigation/native";
import { AppNavigation } from "~navigation";
import { AuthProvider } from "~Contexts/Auth";
import { PermissionProvider } from "~Contexts/Permission";

const queryClient = new QueryClient();

const linking = {
  prefixes: ["https://mychat.com", "mychat://"],
  config: {
    screens: {
      Home: "home",
      Login: "login",
      MFA: "mfa",
      SignUp: "register",
      Adminstartion: "adminstartion",
      MyPipeline: "myPipeline",
    },
  },
};

export default function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <SafeAreaProvider>
            <AuthProvider>
              <PermissionProvider>
                <NavigationContainer linking={linking}>
                  <AppNavigation />
                </NavigationContainer>
              </PermissionProvider>
            </AuthProvider>
          </SafeAreaProvider>
          {/* <AuthProvider>
            <PermissionProvider>
              <Entry />
            </PermissionProvider>
          </AuthProvider> */}
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}
