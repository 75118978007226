import { StyleSheet, Text, View } from "react-native";
import React from "react";
import Icon from "../Icon";
import Pulse from "react-native-pulse";

const NotificationMobile = (props) => {
  return (
    <View style={styles.iconView}>
      <Pulse
        color="cyan"
        numPulses={1}
        diameter={40}
        initialDiameter={30}
        speed={100}
        duration={200}
      />
      <Icon
        name="bell"
        family="feather"
        color={props.focused ? "#ffa500" : "#AAB7B8"}
        style={{ backgroundColor: "white", borderRadius: 22 }}
      />
      <View style={styles.textContainer}>
        <Text style={styles.textValue}>{props.value}</Text>
      </View>
    </View>
  );
};

export default NotificationMobile;

const styles = StyleSheet.create({
  iconView: {
    paddingHorizontal: 60,
  },
  textContainer: {
    width: 14,
    height: 14,
    borderRadius: 7,
    position: "absolute",
    alignSelf: "center",
    transform: [
      {
        translateX: 10,
      },
    ],
    backgroundColor: "red",
  },
  textValue: {
    fontSize: 8,
    textAlign: "center",
    textAlignVertical: "center",
    color: "white",
  },
});
