import { FlatList, StyleSheet, Text, View, ViewStyle, Image } from "react-native";
import React, { useState } from "react";
import Layout from "~components/atoms/Layout/index.web";
import AppLoading from "expo-app-loading";
import { useCustomFonts } from "~helpers/fonts";
import { TouchableOpacity } from "react-native-gesture-handler";
import Heading from "~components/atoms/Heading";
import { useNavigation } from "@react-navigation/native";

const activities =[
  {
    image : require('../../Assets/Images/Call.png'),
    name: "Call Adam Smith", desc: "Late", 
    color: '#F36B72' , index: 1
  },
  {
    image : require('../../Assets/Images/Mail.png'),
    name: "Send Email To John", desc: "Due Today", 
    color: '#E59F54', index: 2
  },
  {
    image : require('../../Assets/Images/Meeting.png'),
    name: "Meeting With Will Smith", desc: "Tomorrow - 22nd Sept 2022", 
    color: '#58D8A2', index: 3
  },
  {
    image : require('../../Assets/Images/Call.png'),
    name: "Call Jonathan Regarding Proposal", desc: "Last project: Enterprise Services", 
    color: '#F36B72', index: 4
  },
  {
    image : require('../../Assets/Images/Mail.png'),
    name: "Send email to Ramon with quotation", desc: "Due Today", 
    color: '#E59F54', index: 5
  },
  {
    image : require('../../Assets/Images/Meeting.png'),
    name: "Meeting Adrian", desc: "Tomorrow - 22nd Sept 2022", 
    color: '#58D8A2', index: 6
  },
];

const flatlists = [
  { id: "1", title: "All Activities" },
  { id: "2", title: "Sales Activities"},
  { id: "3", title: "  Tasks  "},
];
interface Props {
  item: string;
  onPress?: () => void;
  backgroundColor: ViewStyle;
  textColor: ViewStyle;
}

const Item: React.FC<Props> = ({
  item,
  onPress,
  backgroundColor,
  textColor,
}) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
    <Text style={[styles.title, textColor]}>{item.title}</Text>
  </TouchableOpacity>
);

const Activities = () => {

  const [selectedId, setSelectedId] = useState();
  const [fontsLoaded, error] = useCustomFonts();

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  const renderItem = ({ item }) => {

    const backgroundColor = item.id === selectedId ? "#06949E" : "#D9D9D9";
    const color = item.id === selectedId ? "white" : "black";

    return (
      
      <Item
        item={item}
        onPress={() => setSelectedId(item.id)}
        backgroundColor={{ backgroundColor }}
        textColor={{ color }}
      />
      
    );
  };
  const navigation = useNavigation();
  return (
    <Layout>
      <View style={styles.container}>
      <Heading style={{ backgroundColor: '#f2f2f2' }}>ACTIVITIES</Heading>
          <View style={styles.flatlist}>      
            <FlatList
              horizontal
              data={flatlists}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
              extraData={selectedId}
            />   
            {selectedId === "2" && (
              <View>
                <Text>Hello Hi Bye</Text>
              </View>
             )}
            {selectedId === "3" && (
            <View>
              <Text>Hello Bye Bye</Text>
            </View>
            )}
            <Text style={styles.date}>
              <Image
                style={styles.calender}
                source={require('../../Assets/Images/calendar.png')}
              />  
            </Text>  
          </View>
            {activities.map((data)=>(
              <TouchableOpacity
              onPress={() => {
                navigation.navigate("ActivityDetail");
              }}
              >
              <View style={styles.oneCard}>
                <View style={[styles.colors,{backgroundColor:data.color}]}></View>
                <View style={styles.imgView}>
                  <Image 
                    style={styles.img}
                    source={data.image}
                  />
                  <View style={styles.desc}>
                    <Text style={styles.name}>{data.name}</Text>
                    <Text>{data.desc}</Text>
                  </View>
                </View>
              </View>
              </TouchableOpacity>
            ))}
      </View>
    </Layout>
  );
};

export default Activities;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: 30
  },
  heading: {
    fontFamily: "OpenSans_400Regular",
    paddingLeft: 30, 
    backgroundColor: '#f2f2f2',
  },
  item: {
    padding: 10,
    marginVertical: 12,
    marginHorizontal: 30,
    width: "90%",
    borderRadius: 20,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "OpenSans_400Regular",
    marginHorizontal: 30,
  },
  flatlist: {
    borderRadius: 5,
    marginRight: 30,
    flexDirection: 'row'
  },
  date: {
    backgroundColor: 'white',
    width: '15%',
    height: 35,
    marginTop: 15,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    paddingHorizontal: 5,
    marginRight: 40
  },
  calender: {
    height: 30,
    width: 30,
    alignSelf: 'flex-end',
    marginLeft: '85%'
  },
  colors: {
    backgroundColor: '#F36B72',
    height: '100%',
    width: '2%',
    marginRight: 20,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  oneCard: {
    backgroundColor: "white",
    marginVertical: 10,
    marginRight: 50,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    fontWeight: 400,
    shadowColor: "#03030320",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 5,
    borderRadius: 10,
    flexDirection: 'row',
  },
  img: {
    height: 30,
    width: 30
  },
  imgView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
  },
  name: {
    fontFamily: "OpenSans_400Regular",
    fontWeight: 'bold',
    fontSize: 15
  },
  desc: {
    marginLeft: 18,
  }
});
