import { StyleSheet, Dimensions } from 'react-native'

const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: Dimensions.get('window').width - 280,
    justifyContent: 'center',
  },
  mainContainer: {
    maxWidth: Dimensions.get('window').width - 280,
  },
  heading: {
    marginLeft: 62,
    fontFamily: 'OpenSans_400Regular',
    fontWeight: '600',
    fontSize: 25,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tabContainer: {
    marginLeft: 62,
    marginBottom: 15,
  },
  item: {
    padding: 10,
    marginVertical: 12,
    marginRight: 10,
    paddingHorizontal: 35,
    borderRadius: 20,
  },
})

export default styles
