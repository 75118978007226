import {
  Dimensions,
  Image,
  Linking,
  ScrollView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import React, { useState } from "react";
import { Searchbar } from "react-native-paper";
import Drawer from "~components/molecules/Drawer/index.web";
import { useResolution, getInitials } from "~helpers/utilities";
import Card from "~components/atoms/Card";
import Profile from "~components/atoms/Avatar";
import { useNavigation } from "@react-navigation/native";
import styles from "./styleWeb";
import CardItem from "~components/atoms/CardItem/index.web";
import News from "~Screen/News/index.web";
import MyPipeline from "~Screen/MyPipeline/index.web";
// import SalesCommunity from "~Screen/SalesFeed/index.web";
import Activities from "~Screen/Activities/index.web";
import Pulse from "~Screen/Dashboard/index.web";
import Lead from "~Screen/Lead/index.web";
import ProfileScreen from "~Screen/ProfileScreen/index.web";
import LeftMenu from "~components/molecules/LeftMenu/index.web";
import { horizontalScale } from "~helpers/basicStyles";
import Header from "~components/molecules/Header/index.web";
import Campaigns from "~Screen/Campaigns/index.web";
import LeadWorkspaces from "~Screen/LeadProject/index.web";
import Shop from "~Screen/Shop/index.web";
import Contacts from "~Screen/Contacts/index.web";
import { i18n } from "~Utils/i18n";
import { useCustomFonts } from "~helpers/fonts";
import AppLoading from "expo-app-loading";
import Layout from "~components/atoms/Layout/index.web";
import Heading from "~components/atoms/Heading";

const cards = [
  {
    title: i18n.t("home.news"),
    index: 1,
    label: "All news",
    nav: "NewsDetail",
  },
  {
    title: i18n.t("home.feeds"),
    index: 2,
    label: "All feeds",
    nav: "FeedDetail",
  },
  {
    title: i18n.t("home.activities"),
    index: 3,
    label: "All activities",
    nav: "ActivityDetail",
  },
  {
    title: i18n.t("home.leads"),
    index: 4,
    label: "All leads",
    nav: "LeadDetail",
  },
  {
    title: "Lead Projects",
    index: 5,
    label: "All Projects",
    nav: "ProjectDetail",
  },
  {
    title: i18n.t("home.campaigns"),
    index: 6,
    label: "All campaigns",
    nav: "CampaignDetail",
  },
  {
    title: "Dashboard",
    index: 7,
    label: "View dashboard",
    nav: "Dashboard",
  },
];
const cardItems = [
  {
    newsTitle: "Sales News",
    newsDescription: "sdvavavfahfjhfvjdv",
  },
  {
    newsTitle: "News",
    newsDescription: "sdvavavfahfjhfvjdv",
  },
  {
    newsTitle: "LeadX News",
    newsDescription: "sdvavavfahfjhfvjdv",
  },
];

const screenMappings = {
  1: "News",
  2: "Sales",
  3: "Activities",
  4: "Leads",
  5: "LeadProjects",
  6: "Campaigns",
  7: "Dashboard",
};

const Home = () => {
  const res = useResolution();
  const navigation = useNavigation();
  const [fontsLoaded, error] = useCustomFonts();
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  const handleSelection = (id) => {
    console.log("id", id);
    navigation.navigate(screenMappings[id]);
  };

  const handleDetailScreen = (screen) => {
    navigation.navigate(screen);
  };
  return (
    <Layout>
      <Heading style={{ paddingLeft: 60, backgroundColor: "#f1f1f1" }}>
        Home
      </Heading>
      <ScrollView
        // style={{ backgroundColor: "white" }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={[
          styles.containerStyle,
          res === "xsmall" && {
            maxWidth: Dimensions.get("window").width,
          },
        ]}
      >
        {cards.map((card) => (
          <Card
            viewStyle={[
              styles.cardViewStyle,
              {
                backgroundColor: "#5674B8",

                width: Dimensions.get("window").width / 3,
              },
              res !== "large" && {
                width: Dimensions.get("window").width / 1.7,
              },
              res === "small" && {
                width: Dimensions.get("window").width / 2.2,
              },
            ]}
            key={card.title}
            title={card.title}
            label={card.label}
            onPress={() => handleSelection(card.index)}
          >
            {cardItems.map((cardItem, index) => (
              <CardItem
                onPress={() => handleDetailScreen(card.nav)}
                key={cardItem.newsTitle + index}
                name={cardItem.newsTitle}
              />
            ))}
          </Card>
        ))}
      </ScrollView>
    </Layout>
  );
};

export default Home;
