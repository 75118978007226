import { StyleSheet, Text, View } from "react-native";
import React from "react";
import Layout from "~components/atoms/Layout/index.web";

const CreatePerson = () => {
  return (
    <Layout>
      <Text>CreatePerson</Text>
    </Layout>
  );
};

export default CreatePerson;

const styles = StyleSheet.create({});
