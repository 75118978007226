import { StyleSheet, Text, View, Image } from 'react-native'
import Link from '~components/atoms/Link'
import Common from '~components/molecules/commonpages/index.web'
import React, { useState, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { i18n } from '~Utils/i18n'
import { useResolution } from '~helpers/utilities'
// import styles from './styleWeb.js'
import { horizontalScale } from '~helpers/basicStyles'

const SignUpSuccess = () => {
  const resolution = useResolution()
  const navigation = useNavigation()
  const [aspectRatio, setAspectRatio] = useState(0)
  const handleOnLoad = (e) => {
    setAspectRatio(
      e.nativeEvent.path[0].naturalWidth / e.nativeEvent.path[0].naturalHeight
    )
  }

  return (
    <Common>
      <View style={styles.container}>
        <View style={resolution !== 'small' ? [{ marginVertical: 30 }] : []}>
          <Image
            onLoad={handleOnLoad}
            source={{
              uri: 'https://s3.eu-north-1.amazonaws.com/live-media.leadx.no/Leadx-staging/users/1655113413413_users.png',
            }}
            style={[
              styles.logoStyle,
              {
                aspectRatio: aspectRatio,
              },
            ]}
            resizeMode={'contain'}
          />
          <Text style={[styles.title, styles.textCenter]}>
            {i18n.t('registerSuccess.thankYou')}
          </Text>
          <Text style={[styles.confirmationText, styles.textCenter]}>
            {i18n.t('registerSuccess.confirmation')}
          </Text>
          <Link
            style={[styles.textCenter]}
            title={i18n.t('registerSuccess.signIn')}
            onPress={() => {
              navigation.navigate('Login')
            }}
          />
        </View>
      </View>
    </Common>
  )
}

export default SignUpSuccess

const styles = StyleSheet.create({
  logoStyle: {
    width: horizontalScale(70),
    alignSelf: 'center',
  },
  container: {
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    marginHorizontal: 15,
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: 25,
    marginVertical: 10,
    elevation: 8,
    width: '70%',
    height: '85%',
  },
  heading: {
    fontSize: 25,
    fontFamily: 'OpenSans_400Regular',
    fontWeight: '400',
    color: 'rgba(0,0,0,1)',
    marginBottom: 2,
  },
  title: {
    fontSize: '24px',
    fontFamily: 'OpenSans_400Regular',
    fontWeight: '400',
    color: 'rgba(0,0,0,1)',
    marginBottom: 25,
    marginTop: 45,
    lineHeight: '40.85px',
  },
  confirmationText: {
    marginBottom: 50,
    fontWeight: '400',
    fontSize: '16px',
    fontFamily: 'OpenSans_400Regular',
  },
  textCenter: {
    textAlign: 'center',
  },
})
