import Api from "./Api";
import apiUrl from "../constants/apiURLs";
import { IRowsLightWithCount } from "~Types/ApiResponse";
import {  IFeedFilter, IFeed } from "./Types";

export interface IFeedInterface{

    getCurrentFeed(payload:IFeedFilter): Promise<IRowsLightWithCount<IFeed>>
}

export default class Feed implements IFeedInterface{
    async getCurrentFeed(payload:IFeedFilter): Promise<IRowsLightWithCount<IFeed>> {
        const result = await Api.post(apiUrl.feed.getCurrentFeed, payload);
        return result.data;
    }
}