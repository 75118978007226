import { StyleSheet } from "react-native";
import { horizontalScale } from "~helpers/basicStyles";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    paddingHorizontal: 20,
    marginHorizontal: 15,
    justifyContent: "center",
    alignSelf: "center",
    borderRadius: 25,
    marginVertical: 10,
    elevation: 8,
    width: "70%",
  },
  headerStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 35,
    marginHorizontal: 20,
  },

  viewStyle: {
    flexDirection: "row",
  },
  heading: {
    fontSize: 25,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    color: "rgba(0,0,0,1)",
    marginBottom: 2,
  },
  logoStyle: {
    width: horizontalScale(58),
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    color: "rgba(0,0,0,1)",
    marginBottom: 20,
  },
  input: {
    marginHorizontal: 22,
  },
  buttonView: {
    marginTop: 60,
    marginBottom: 30,
  },
  button: {
    marginHorizontal: 20,
  },
  imageView: {
    alignSelf: "center",
  },
  imageStyle: {
    width: 190,
    marginBottom: 10,
    height: 190,
  },
  centerHeading: {
    textAlign: "center",
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    color: "#898A8D",
    marginHorizontal: 20,
  },
  inputText: {
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "700",
    marginHorizontal: 17,
    marginTop: 40,
    marginBottom: 10,
  },
  viewCenter: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
  },
  validationStyle: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    color: "#898A8D",
    marginHorizontal: 25,
  },
  linkStyle: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    color: "#0ab58b",
    alignSelf: "flex-end",
    marginHorizontal: 25,
    textDecorationLine: "underline",
  },
  error: {
    color: "red",
    marginHorizontal: 23,
  },
});

export default styles;
