import { IRowsLightWithCount, IRowsWithCount } from "~Types/ApiResponse";
import Api from "./Api";
import apiUrl from "../constants/apiURLs";
import {ISalesNews, ISalesNewsFilter} from "./Types";

export interface ISalesNewsInterface{
    getSalesNewsByQuery(payload:ISalesNewsFilter): Promise<IRowsLightWithCount<ISalesNews>>
}

export default class SalesNews implements ISalesNewsInterface{

    async getSalesNewsByQuery(payload:ISalesNewsFilter): Promise<IRowsLightWithCount<ISalesNews>> {
        const result = await Api.post(apiUrl.salesnews.getSalesNewsByQuery, payload);
        return result.data;
    }

}