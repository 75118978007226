import Api from "./Api";
import apiUrl from "../constants/apiURLs";
import { IRowsLightWithCount } from "~Types/ApiResponse";
import { ICompanyFilter, IContact, IContactFilter } from "./Types";

/// This is the interface for company and contact
export interface ICompanyInterface {}

export interface ICompanySearchResponse {
  id: number;
  account_id: number;
  company_name: string;
  company_code: string;
  office_address_line_1: string;
  office_address_line_2: any;
  zipcode: string;
  country_id: number;
  state_id: number;
  city_id: any;
  website: any;
  contact_person_name: any;
  contact_country_code: any;
  contact_person_phone: any;
  contact_person_email: any;
  notes: any;
  is_deleted: boolean;
  created_by: number;
  created_at: string;
  updated_at: string;
  user: User;
  country: Country;
  state: any;
  city: any;
  countryCode: any;
  from_brreg: boolean;
}

interface User {
  initials: string;
  first_name: string;
  last_name: string;
  name: string;
  name_without_initials: string;
  gender: string;
  user_avatar: string;
}

interface Country {
  name: string;
  iso_code: string;
  is_city: string;
}

export default class Company implements ICompanyInterface {
  async getCompaniesByQuery(
    company_name: string
  ): Promise<ICompanySearchResponse[]> {
    const result = await Api.postWithToken(apiUrl.company.getCompaniesByQuery, {
      arrayFilters: [{}],
      selectFilters: [],
      searchFilter: [{ company_name }],
      sort: { field: "id", sortOrder: "DESC" },
    });
    return result.data.rows;
  }

  async getContactsByQuery(
    payload: IContactFilter
  ): Promise<IRowsLightWithCount<IContact>> {
    const result = await Api.postWithToken(
      apiUrl.company.contact.getContactsByQuery,
      payload
    );
    return result.data;
  }
}
