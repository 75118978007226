import { StyleSheet, Text, View, Image, ScrollView } from 'react-native'
import React from 'react'
import Layout from '~components/atoms/Layout/index.web'
import { LinearGradient } from 'expo-linear-gradient'
// import style from '../../../storybook/stories/CenterView/style'
import { i18n } from '~Utils/i18n'
import { DataTable } from 'react-native-paper'
import AppLoading from 'expo-app-loading'
import { useCustomFonts } from '~helpers/fonts'

const campaign = {
  name: 'Winter Campaign',
  startDate: '1 Sept 2022',
  endDate: '1 Dec 2022',
  about: 'Content about campaign ',
  successRate: '35%',
  totalLeadsGenerated: '55',
  weeklyLeadGeneration: '7',
  leadsWon: '5',
  leadsLost: '4',
  leadClosureRate: '35%',
  revenue: '1.5M',
}

const users = [
  {
    name: 'John Smith',
    leads: '10',
    wins: '3',
    points: '750',
  },
  {
    name: 'Alica Hopes',
    leads: '8',
    wins: '2',
    points: '500',
  },
  {
    name: 'Emma Watson',
    leads: '6',
    wins: '0',
    points: '200',
  },
  {
    name: 'Phillip Anderson',
    leads: '6',
    wins: '0',
    points: '200',
  },
]

const CampaignDetails = () => {
  // Fonts Loading
  const [fontsLoaded, error] = useCustomFonts()
  if (!fontsLoaded) {
    return <AppLoading />
  }

  return (
    <Layout>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.container}>
          {/* Top Container */}
          <LinearGradient
            style={[styles.topContainer]}
            colors={['#0000FF', '#0000FF', '#983795', '#F27121']}
            locations={[0, 0.552, 0.8333, 1]}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 1 }}
          >
            <Image
              style={{ width: 50, height: 50 }}
              source={require('../../../Assets/Icons/target.png')}
            />
            <View style={{ marginLeft: 30 }}>
              <Text style={styles.title}>{campaign.name}</Text>
              <Text style={styles.duration}>
                {i18n.t('campaignDetails.startOn')}
                {campaign.startDate} {i18n.t('campaignDetails.endOn')}
                {campaign.endDate}
              </Text>
            </View>
            <View
              style={{
                marginLeft: 'auto',
                alignItems: 'center',
              }}
            >
              <Text style={styles.success}>
                {i18n.t('campaignDetails.successRate')}
              </Text>
              <View style={styles.successCircle}>
                <Text style={styles.successNumber}>{campaign.successRate}</Text>
              </View>
            </View>
          </LinearGradient>

          {/* Middle Container */}
          <View style={styles.middleContainer}>
            {/* Left Mid */}
            <View
              style={{
                width: '40%',
                backgroundColor: '#fff',
                marginRight: 15,
                borderRadius: 9,
                padding: 15,
              }}
            >
              <Text style={styles.headingPrimary}>
                {i18n.t('campaignDetails.performance')}
              </Text>

              {/* Performance Details */}
              <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                {/* Title */}
                <Text style={styles.performanceDetails}>
                  {i18n.t('campaignDetails.totalLeadsGenerated')}
                </Text>

                {/* Value */}
                <Text
                  style={[styles.performanceDetails, styles.marginLeftAuto]}
                >
                  {campaign.totalLeadsGenerated}
                </Text>
              </View>
              <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                {/* Title */}
                <Text style={styles.performanceDetails}>
                  {i18n.t('campaignDetails.weeklyLeadGeneration')}
                </Text>

                {/* Value */}
                <Text
                  style={[styles.performanceDetails, styles.marginLeftAuto]}
                >
                  {campaign.weeklyLeadGeneration}
                </Text>
              </View>
              <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                {/* Title */}
                <Text style={styles.performanceDetails}>
                  {i18n.t('campaignDetails.leadsWon')}
                </Text>
                {/* Value */}
                <Text
                  style={[styles.performanceDetails, styles.marginLeftAuto]}
                >
                  {campaign.leadsWon}
                </Text>
              </View>
              <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                {/* Title */}
                <Text style={styles.performanceDetails}>
                  {i18n.t('campaignDetails.leadsLost')}
                </Text>

                {/* Value */}
                <Text
                  style={[styles.performanceDetails, styles.marginLeftAuto]}
                >
                  {campaign.leadsLost}
                </Text>
              </View>
              <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                {/* Title */}
                <Text style={styles.performanceDetails}>
                  {i18n.t('campaignDetails.leadClosureRate')}
                </Text>

                {/* Value */}
                <Text
                  style={[styles.performanceDetails, styles.marginLeftAuto]}
                >
                  {campaign.leadClosureRate}
                </Text>
              </View>

              <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                {/* Title */}
                <Text style={styles.performanceDetails}>
                  {i18n.t('campaignDetails.revenue')}
                </Text>

                {/* Value */}
                <Text
                  style={[styles.performanceDetails, styles.marginLeftAuto]}
                >
                  {campaign.revenue}
                </Text>
              </View>
            </View>

            {/* Right Mid */}
            <View
              style={{
                width: '60%',
                backgroundColor: '#fff',
                padding: 15,
                borderRadius: 9,
              }}
            >
              <Text style={styles.headingPrimary}>
                {i18n.t('campaignDetails.leaderboard')}
              </Text>
              <DataTable>
                <DataTable.Header
                  style={{
                    borderBottomWidth: 0,
                  }}
                >
                  {/* <DataTable.Title> </DataTable.Title> */}
                  <DataTable.Title style={{ flex: 0.4 }}> </DataTable.Title>
                  <DataTable.Title> </DataTable.Title>
                  <DataTable.Title style={{ flex: 2.5 }}> </DataTable.Title>
                  <DataTable.Title style={styles.center}>
                    <Text style={styles.tableColumnHeader}>
                      {i18n.t('campaignDetails.leads')}
                    </Text>
                  </DataTable.Title>
                  <DataTable.Title style={styles.center}>
                    <Text style={styles.tableColumnHeader}>
                      {i18n.t('campaignDetails.wins')}
                    </Text>
                  </DataTable.Title>
                  <DataTable.Title style={styles.center}>
                    <Text style={styles.tableColumnHeader}>
                      {i18n.t('campaignDetails.points')}
                    </Text>
                  </DataTable.Title>
                </DataTable.Header>

                {users.map((user, index) => (
                  <DataTable.Row
                    style={{
                      borderBottomWidth: 0,
                    }}
                  >
                    <DataTable.Cell style={{ flex: 0.4 }}>
                      {index === 0 && (
                        <Image
                          style={{ width: 20, height: 20 }}
                          source={require('../../../Assets/Icons/star.png')}
                        />
                      )}
                    </DataTable.Cell>
                    <DataTable.Cell>
                      <Image
                        source={require('../../../Assets/temp/Ellipse.png')}
                        style={{ height: 70, width: 70 }}
                      />
                    </DataTable.Cell>
                    <DataTable.Cell style={{ flex: 2.5 }}>
                      {user.name}
                    </DataTable.Cell>
                    <DataTable.Cell style={styles.center}>
                      {user.leads}
                    </DataTable.Cell>
                    <DataTable.Cell style={styles.center}>
                      {user.wins}
                    </DataTable.Cell>
                    <DataTable.Cell style={styles.center}>
                      {user.points}
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}
              </DataTable>
            </View>
          </View>
          {/* Bottom Container */}
          <View style={styles.bottomContainer}>
            <Text style={styles.headingPrimary}>
              {i18n.t('campaignDetails.aboutCampaign')}
            </Text>
            <Text>{campaign.about}</Text>
          </View>
        </View>
      </ScrollView>
    </Layout>
  )
}

export default CampaignDetails

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 30,
  },
  title: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 25,
    fontWeight: '600',
    color: '#fff',
    marginBottom: 6,
  },
  duration: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    fontWeight: '300',
    color: '#fff',
  },
  topContainer: {
    flexDirection: 'row',
    marginBottom: 15,
    alignItems: 'center',
    borderRadius: 9,
    padding: 15,
    width: '100%',
  },
  middleContainer: {
    flexDirection: 'row',
    marginBottom: 15,
    // backgroundColor: '#fff',
  },
  bottomContainer: {
    backgroundColor: '#fff',
    borderRadius: 9,
    padding: 15,
    marginBottom: 30,
  },
  successCircle: {
    height: 40,
    width: 40,
    borderRadius: 100,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
  },
  success: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    fontWeight: '600',
    color: '#fff',
    marginBottom: 5,
  },
  successNumber: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    fontWeight: '600',
    color: '#000',
  },
  headingPrimary: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 22,
    fontWeight: '700',
    marginBottom: 35,
  },
  performanceDetails: {
    fontFamily: 'OpenSans_400Regular',
    fontWeight: '600',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  center: {
    justifyContent: 'center',
  },
  tableColumnHeader: {
    fontFamily: 'OpenSans_400Regular',
    fontWeight: '700',
    fontSize: 15,
    color: '#000',
  },
})
