import { StyleSheet } from "react-native";
import { horizontalScale } from "~helpers/basicStyles";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    paddingHorizontal: 20,
    marginHorizontal: 15,
    justifyContent: "center",
    alignSelf: "center",
    borderRadius: 25,
    marginVertical: 10,
    elevation: 8,
    width: "70%",
  },
  viewContainer: {
    alignItems: "center",
  },
  viewContainer: {
    marginHorizontal: 20,
  },
  heading: {
    fontSize: 25,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    color: "rgba(0,0,0,1)",
    marginBottom: 2,
  },
  title: {
    fontSize: 20,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    color: "rgba(0,0,0,1)",
    marginBottom: 10,
  },
  text: {
    marginBottom: 10,
    fontWeight: "700",
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
  },
  inputStyle: {
    marginBottom: 10,
  },
  accountStyle: {
    color: "#8D8D8D",
    fontSize: 16,
  },
  mainView: {
    marginBottom: 20,
    // marginHorizontal: 20,
  },
  input: {
    paddingHorizontal: 15,
  },
  linkStyle: {
    textDecorationLine: "none",
    fontSize: 14,
    marginTop: -10,
    marginHorizontal: 14,
  },
  viewLink: {
    alignSelf: "flex-end",
  },
  buttonView: {
    marginTop: 30,
    marginBottom: 30,
  },
  button: {
    marginHorizontal: 20,
  },
  imageStyle: {
    width: 190,
    marginBottom: 10,
    height: 190,
  },
  imageView: {
    alignSelf: "center",
  },
  logoStyle: {
    width: horizontalScale(58),
    alignSelf: "center",
  },
  checkBoxView: {
    flexDirection: "row",
    marginTop: 30,
    marginLeft: 3,
  },

  checkBox: {
    alignSelf: "center",
  },
  dropDownView: {
    marginTop: 20,
  },
  text1: { fontSize: 16 },
  error: {
    color: "red",
  },
  textEnd: {
    textAlign: "right",
    marginRight: 12,
    marginBottom: 25,
  },
});

export default styles;
