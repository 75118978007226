import React from "react";
import { defaultTheme } from "./theme";

export const ThemeContext = React.createContext(null);

const ThemeProvider = ({ theme, children }) => {
  // const isDarkMode = useIsDarkMode(); // need to create useIsDarkMode hook
  const isDarkMode = false;
  return (
    <ThemeContext.Provider value={theme?.(isDarkMode)}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.defaultProps = {
  theme: defaultTheme,
};

export { ThemeProvider };
