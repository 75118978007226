import apiURLs from "~constants/apiURLs";
import { IRowsLightWithCount, IRowsWithCount } from "~Types/ApiResponse";
import Api from "./Api";
import { ILabels } from "./Types";

export interface IOrg {
  id: number;
  name: string;
  parent_account_id: number;
  Parent?: IOrg;
}

export interface IOrgOption {
  disabled: boolean;
  options:  OrgOption[];
}

export interface OrgOption {
  value:     number;
  label:     string;
  is_parent: boolean;
  self:      boolean;
}



export interface IAccount {
  getFinYearStartingMonth(): Promise<number>;
  getOrganizationList(): Promise<IRowsLightWithCount<IOrg>>;
  getOrgaionzationOptions(): Promise<IRowsLightWithCount<IOrgOption>>;
}

export default class Account implements IAccount {
  async getFinYearStartingMonth(): Promise<number> {
    const result = await Api.post("/account/getFinYearStartingMonth", {});
    return result.data;
  }

  async getOrganizationList(): Promise<IRowsLightWithCount<IOrg>> {
    const result = await Api.post("/account/getOrganizationList", {});
    return result.data;
  }

  async getOrgaionzationOptions() : Promise<IRowsLightWithCount<IOrgOption>> {
    const result = await Api.post(apiURLs.account.getOrganizationOptions, {});
    return result.data;
  }

  async getAllLabels() : Promise<IRowsLightWithCount<ILabels>> {
    const result = await Api.post(apiURLs.account.getAllLabels, {});
    return result.data;
  }
}
