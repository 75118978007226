import {
  Dimensions,
  Image,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import { Searchbar } from "react-native-paper";
import styles from "./styles.web";
import { horizontalScale } from "~helpers/basicStyles";
import { getInitials, useResolution } from "~helpers/utilities";
import Profile from "~components/atoms/Avatar";
import Transparent from "~components/atoms/Modals/Transparent";
import { useNavigation } from "@react-navigation/native";
import Pulse from "react-native-pulse";
import useNotificationCountLiveSync from "~Screens/Entry/useNotificatinCountLiveSync";
import SecureStorage from "~Utils/SecureStorage";
import { useEffect } from "react";
import useHeader from "./useHeader";

const imageNames = [
  { name: "news", screen: "CreateNews", title: "News" },
  { name: "activity", screen: "CreateActivity", title: "Activity" },
  { name: "lead", screen: "CreateLead", title: "Lead" },
  { name: "campaigns", screen: "CreateCampaign", title: "Campaign" },
  { name: "contact", screen: "CreateContact", title: "Contact" },
  { name: "company", screen: "CreateCompany", title: "Company" },
];

const Header = ({ handleProfile }) => {
  const notification = useNotificationCountLiveSync();
  const [paused, setPaused] = useState(false);
  console.log("notification", notification);
  const res = useResolution();
  const navigation = useNavigation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleSelection = (id) => {
    setShowCreateModal((prev) => !prev);
    navigation.navigate(imageNames[id].screen);
  };
  const { bonusPoint, user } = useHeader();
  return (
    <>
      <View
        style={[
          styles.topContainer,
          {
            minWidth:
              Dimensions.get("window").width - (250 + horizontalScale(8)),
          },
        ]}
      >
        <View
          style={[
            styles.searchView,
            {
              paddingLeft: horizontalScale(8),
            },
          ]}
        >
          {res !== "xsmall" && (
            <Searchbar
              placeholder="Search"
              onChangeText={() => {}}
              style={[styles.searchStyle, res === "small" && { width: "85%" }]}
            />
          )}
          {res === "xsmall" && (
            <Image
              source={{
                uri: "https://s3.eu-north-1.amazonaws.com/live-media.leadx.no/Leadx-staging/users/1655113413413_users.png",
              }}
              style={[
                styles.iconStyle,

                { width: 75, height: 90, marginHorizontal: 5 },
              ]}
              resizeMode={"contain"}
            />
          )}
        </View>
        <View style={styles.rightContainer}>
          <TouchableOpacity
            onPress={() => {
              setShowCreateModal((prev) => !prev);
            }}
          >
            <Image
              source={require("../../../Assets/Images/create.png")}
              style={[
                styles.iconStyle,
                res === "xsmall"
                  ? { width: 20, height: 20, marginHorizontal: 5 }
                  : res === "small" && {
                      width: 25,
                      height: 25,
                      marginHorizontal: 5,
                    },
              ]}
              resizeMode={"contain"}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setPaused(true)}>
            {!paused && (
              <View
                style={{
                  position: "absolute",
                  alignSelf: "center",
                  justifyContent: "center",
                  top: 15,
                }}
              >
                <Pulse
                  color="blue"
                  numPulses={1}
                  diameter={60}
                  speed={50}
                  duration={100}
                />
              </View>
            )}
            <View
              style={{
                position: "absolute",
                width: 12,
                height: 12,
                borderRadius: 12,
                backgroundColor: "red",
                alignSelf: "center",
                zIndex: 1,
                transform: [{ translateX: 5 }],
              }}
            >
              <Text
                style={{ fontSize: 8, color: "white", textAlign: "center" }}
              >
                {notification.value}
              </Text>
            </View>
            <Image
              source={require("../../../Assets/Images//notification.png")}
              style={[
                styles.iconStyle,
                res === "xsmall"
                  ? { width: 20, height: 20, marginHorizontal: 5 }
                  : res === "small" && {
                      width: 25,
                      height: 25,
                      marginHorizontal: 5,
                    },
              ]}
              resizeMode={"contain"}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL(
                "https://leadx.atlassian.net/servicedesk/customer/portal/2"
              )
            }
          >
            <Image
              source={require("../../../Assets/Images/help.png")}
              style={[
                styles.iconStyle,
                res === "xsmall"
                  ? { width: 20, height: 20, marginHorizontal: 5 }
                  : res === "small" && {
                      width: 25,
                      height: 25,
                      marginHorizontal: 5,
                    },
              ]}
              resizeMode={"contain"}
            />
          </TouchableOpacity>
          {/* <View style={styles.verticleLine}></View> */}
          <TouchableOpacity onPress={handleProfile} style={styles.profile}>
            <Profile
              imageURL={user?.user_avatar}
              size={30}
              style={{}}
              initials={getInitials(user?.name)}
            />
            <Text
              style={{
                backgroundColor: "#232F45",
                fontSize: 8,
                color: "white",
                borderRadius: 4,
                textAlign: "center",
                padding: 2,
              }}
            >
              {bonusPoint}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ backgroundColor: "white" }}>
        {res === "xsmall" && (
          <Searchbar
            placeholder="Search"
            onChangeText={() => {}}
            inputStyle={{ fontSize: 14 }}
            style={[
              styles.searchStyle,
              {
                width: "70%",
                height: 20,
                alignSelf: "center",
                marginTop: 10,
                marginBottom: 10,
              },
            ]}
          />
        )}
        <Transparent isVisible={showCreateModal} setter={setShowCreateModal}>
          <View
            style={{
              position: "absolute",
              top: 0,
              right: 220,
              backgroundColor: "white",
              borderRadius: 8,
              shadowColor: "grey",
              shadowOpacity: 0.8,
              shadowOffset: { width: 2, height: 2 },
              shadowRadius: 8,
              paddingVertical: 20,
              paddingLeft: 20,
              paddingRight: 30,
            }}
          >
            {imageNames.map((image, index) => (
              <View key={image.name}>
                <TouchableOpacity onPress={() => handleSelection(index)}>
                  <View style={{ flexDirection: "row", marginVertical: 10 }}>
                    <Image
                      source={require(`../../../Assets/Images/${image.name}.png`)}
                      style={{ height: 20, width: 20, marginRight: 40 }} // Left menu icons
                      resizeMode={"contain"}
                    />
                    <Text style={styles.textStyle}>{image.title}</Text>
                  </View>
                </TouchableOpacity>
              </View>
            ))}
          </View>
        </Transparent>
      </View>
    </>
  );
};

export default Header;
