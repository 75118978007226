import React, { useRef } from "react";

import { useNavigation } from "@react-navigation/native";
import Common from "~components/molecules/commonpages/index.web";
import LoginRenderer from "./LoginRenderer";
import { useMutation } from "react-query";
import Services, { ICred } from "~Services";
import { Text } from "react-native";
import Alert from "~components/atoms/Alert";
import { useAuth } from "~Contexts/Auth";
import { useState } from "react";
import { useEffect } from "react";
import { handleLogin } from "~Utils/handleLogin";

export default function Login() {
  const navigation = useNavigation();
  const emailRef = useRef("");
  const auth = useAuth();
  const [isRegister, setIsRegister] = useState(true);
  const { mutate: isRegistered, data: isRegisterData } = useMutation(
    () => Services.auth.isRegistered(),
    {
      onSuccess: (res) => {
        console.log("data", res);
      },
    }
  );
  const {
    error,
    mutate: login,
    data,
    isLoading,
    isError,
  } = useMutation((data: ICred) => Services.auth.login(data), {
    onSuccess: (data) => {
      console.log("data", data);
      if (data.status === true) {
        if (data.mfa_enabled) {
          navigation.navigate("MFA", {
            email: emailRef.current,
          });
        } else {
          handleLogin(data.data);
          auth.makeUserLogin();
        }
      }
    },
  });
  const handleSignUp = () => {
    navigation.navigate("SignUp");
  };

  const handleForgotPassword = () => {
    navigation.navigate("ForgotPassword");
  };

  const handleProceed = (email, password) => {
    console.log("values", email, password);
    emailRef.current = email;
    login({
      username: email,
      password: password,
    });
    // navigation.navigate("MFA");
  };

  useEffect(() => {
    isRegistered();
  }, []);

  return (
    <Common>
      <LoginRenderer
        handleProceed={handleProceed}
        handleForgotPassword={handleForgotPassword}
        handleSignUp={handleSignUp}
        isLoading={isLoading}
        showRegister={isRegister}
      />
      {isError && error instanceof Error && (
        <Alert visible={isError} text={error.message} />
      )}
    </Common>
  );
}
