import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import { useNavigation } from "@react-navigation/native";
interface Props {
  item: any;
}
const LeadItem: React.FunctionComponent<Props> = ({ item }) => {
  const navigation = useNavigation();
  return (
    <View style={[styles.items]}>
      <View
        style={[styles.bordercolor, { backgroundColor: item.colors }]}
      ></View>
      <View style={styles.itemImg}>
        <Text style={{ fontSize: 15 }}>{item.Header}</Text>
        <View style={{ flexDirection: "row" }}>
          <Image
            style={styles.sync}
            source={require("../../../Assets/Images/sync.png")}
          />
        </View>
      </View>
      <View style={styles.names}>
        <Text style={{ fontSize: 13 }}>{item.CompanyName}</Text>
        <Text style={{ fontSize: 13 }}>{item.Stages}</Text>
      </View>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("LeadDetail");
        }}
      >
        <Image
          style={styles.arrow}
          source={require("../../../Assets/Images/Arrow.png")}
        />
      </TouchableOpacity>
    </View>
  );
};

export default LeadItem;

const styles = StyleSheet.create({
  itemImg: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bordercolor: {
    width: "1%",
  },
  items: {
    backgroundColor: "white",
    marginVertical: 10,
    marginRight: 40,
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    fontWeight: 400,
    shadowColor: "#03030320",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 8,
    borderRadius: 10,
    marginLeft: 30,
  },
  names: {
    flexDirection: "row",
    backgroundColor: "white",
    color: "#000000",
    fontWeight: 200,
    justifyContent: "space-between",
  },
  arrow: {
    justifyContent: "flex-end",
    height: 25,
    width: 15,
    marginTop: 20,
    marginLeft: "98%",
  },
  sync: {
    height: 15,
    width: 15,
    flexDirection: "row",
    margin: 4,
  },
  delete: {
    height: 26,
    width: 19,
    margin: 3,
    marginTop: 20,
  },
});
