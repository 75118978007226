import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import React, { useState } from "react";
import Layout from "~components/atoms/Layout/index.web";
import { LinearGradient } from "expo-linear-gradient";
import { FlatList } from "react-native-gesture-handler";
import { useCustomFonts } from "~helpers/fonts";
import Button from "~components/atoms/Button";
import { Checkbox } from "react-native-paper";
import AppLoading from "expo-app-loading";
import PopUp from "~components/atoms/PopUp";

const flatlists = [
  { id: "1", title: "Lead Information" },
  { id: "2", title: "Notes" },
  { id: "3", title: "Activities" },
  { id: "4", title: "Documents" },
];

const flatlistActivity = [
  { name: "All Activity", id: "5" },
  { name: "My Activity", id: "6" },
];

const data = [
  { name: "Call Adam Smith", color: "#F36B72", desc: "Late", images: "Call" },
  {
    name: "Send Email To John",
    color: "#E59F54",
    desc: "Due Today",
    images: "Mail",
  },
  {
    name: "Meeting With Will Smith",
    color: "#58D8A2",
    desc: "Tomorrow - 22nd Sept 2022",
    images: "Meeting",
  },
];

const information = [
  { heading: "Lead Title", desc: "Title of The Lead" },
  { heading: "Company", desc: "ABC crop" },
  { heading: "Contact", desc: "Admin Smith" },
  { heading: "Contact - Phone", desc: "+47 1234568998" },
  { heading: "Contact-Email", desc: "Adamsmith@Abccorp.com" },
  { heading: "Lead Catagory", desc: "Catagory of The Lead" },
  { heading: "Expected Closing Date", desc: "31-Dec-2022" },
  { heading: "Project Location", desc: "Norway" },
  { heading: "Lead Value", desc: "25000000.00 NOK" },
];

const notes = [
  {
    name: "This is a great opportunity",
    desc: "01-sep-2022 | by : John Smith",
  },
  {
    name: "This is a great opportunity",
    desc: "01-sep-2022 | by : John Smith",
  },
  {
    name: "This is a great opportunity",
    desc: "01-sep-2022 | by : John Smith",
  },
  {
    name: "This is a great opportunity",
    desc: "01-sep-2022 | by : John Smith",
  },
  {
    name: "This is a great opportunity",
    desc: "01-sep-2022 | by : John Smith",
  },
];

const images = [
  { image: require("../../../Assets/Images/LeadProjectImg.png") },
  { image: require("../../../Assets/Images/LeadProjectImg.png") },
  { image: require("../../../Assets/Images/LeadProjectImg.png") },
];
interface Props {
  item: string;
  onPress?: () => void;
  backgroundColor: ViewStyle;
  textColor: ViewStyle;
}

const Item: React.FC<Props> = ({
  item,
  onPress,
  backgroundColor,
  textColor,
}) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
    <Text style={[styles.title, textColor]}>{item.title}</Text>
  </TouchableOpacity>
);

const LeadDetail = () => {
  const [selectedId, setSelectedId] = useState();
  const [fontsLoaded, error] = useCustomFonts();
  const [accept, setAccept] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openCongratsPopup, setOpenCongratsPopup] = useState(false);

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  const renderItem = ({ item }) => {
    const backgroundColor = item.id === selectedId ? "#06949E" : "#D9D9D9";
    const color = item.id === selectedId ? "white" : "black";
    return (
      <Item
        item={item}
        onPress={() => setSelectedId(item.id)}
        backgroundColor={{ backgroundColor }}
        textColor={{ color }}
      />
    );
  };
  return (
    <Layout>
      <View style={styles.oneBox}>
        <LinearGradient
          style={styles.container}
          start={{ x: 0.5, y: 0.5 }}
          // colors={["#439368", "#3C93B8"]}
          colors={["#3C93B8", "#439368"]}
          // colors={["#439368", "#3b5998"]}
        >
          <View style={styles.headingCard}>
            <Image
              style={styles.img}
              source={require("../../../Assets/Images/leadDetails.png")}
            />
            <View style={{ marginLeft: 10 }}>
              <Text style={styles.heading}>Enterprise Services</Text>
              <View style={styles.titles}>
                <Text style={styles.subTitle}>
                  Project Status : In progress |
                </Text>
                <Text style={styles.subTitle}>Lead Stage : Negotiation</Text>
              </View>
            </View>
          </View>
        </LinearGradient>
        <View style={styles.flatlist}>
          <TouchableOpacity>
            <FlatList
              horizontal
              data={flatlists}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
              extraData={selectedId}
            />
          </TouchableOpacity>
        </View>
        <View>
          {selectedId === "1" && (
            <View style={styles.lastCard}>
              {information.map((item) => (
                <View style={styles.leadInfo}>
                  <Text style={styles.names}>{item.heading}</Text>
                  <Text style={styles.desc}>{item.desc}</Text>
                </View>
              ))}
              <View style={styles.leadBtn}>
                <Button
                  onPress={() => setOpenPopup(true)}
                  title="Update"
                  style={styles.updateBtn}
                />
                <Button title="Save" style={styles.saveBtn} />
              </View>
            </View>
          )}
          <View>
            <PopUp toggle={openCongratsPopup} setToggle={setOpenCongratsPopup}>
              <View
                style={{
                  backgroundColor: "white",
                  padding: 20,
                  alignItems: "center",
                  borderRadius: 15,
                  marginHorizontal: 35,
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    marginBottom: 30,
                    fontWeight: "700",
                    fontSize: 20,
                  }}
                >
                  {" "}
                  {"Congratulations!"}
                </Text>
              </View>
            </PopUp>
          </View>
          {selectedId === "2" && (
            <View style={styles.notes}>
              {notes.map((item) => (
                <View style={styles.leadInfoImg}>
                  <Image
                    style={styles.img}
                    source={require("../../../Assets/Images/LeadProjectImg.png")}
                  />
                  <View>
                    <Text style={styles.names}>{item.name}</Text>
                    <Text style={styles.desc}>{item.desc}</Text>
                  </View>
                </View>
              ))}
            </View>
          )}
          {selectedId === "3" && (
            <View style={styles.notes}>
              <View style={styles.flatlist}>
                <FlatList
                  horizontal
                  data={flatlistActivity}
                  renderItem={renderItem}
                  keyExtractor={(item) => item.id}
                  extraData={selectedId}
                />
              </View>
              {data.map((item) => (
                <View style={styles.cards}>
                  <View
                    style={[styles.colors, { backgroundColor: item.color }]}
                  ></View>
                  <Image
                    source={require(`../../../Assets/Images/${item.images}.png`)}
                    style={styles.image}
                    resizeMode={"contain"}
                  />
                  <View>
                    <Text style={{ fontWeight: "bold", marginTop: 22 }}>
                      {item.name}
                    </Text>
                    <Text>{item.desc}</Text>
                  </View>
                  <Image
                    style={styles.imgs}
                    source={require("../../../Assets/Images/LeadProjectImg.png")}
                  />
                  <Checkbox
                    style={styles.checkboxImg}
                    value={accept}
                    onValueChange={() => setAccept(!accept)}
                  />
                </View>
              ))}
            </View>
          )}
          {selectedId === "4" && (
            <View style={styles.notes}>
              <Button title="Edit" style={styles.btn} />
              {images.map((data) => (
                <View style={styles.documentImg}>
                  <Image source={data.image} style={styles.img} />
                  <View style={{ flexDirection: "row" }}>
                    <View style={styles.circle}></View>
                    <View style={styles.circle}></View>
                    <View style={styles.circle}></View>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      </View>
    </Layout>
  );
};

export default LeadDetail;

const styles = StyleSheet.create({
  oneBox: {},
  container: {
    borderRadius: 15,
    marginHorizontal: 5,
    marginTop: 20,
    shadowColor: "#03030340",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 3,
    marginBottom: 25,
  },
  heading: {
    color: "white",
    fontWeight: "bold",
    paddingHorizontal: 10,
    fontSize: 24,
  },
  img: {
    height: 40,
    width: 40,
    marginTop: 8,
    marginLeft: 10,
  },
  headingCard: {
    flexDirection: "row",
    marginVertical: 10,
    marginHorizontal: 10,
  },
  subTitle: {
    color: "white",
  },
  titles: {
    flexDirection: "row",
    marginHorizontal: 10,
  },
  flatlist: {
    backgroundColor: "white",
    borderRadius: 5,
    marginRight: 30,
    marginLeft: 15,
  },
  item: {
    padding: 10,
    marginVertical: 12,
    marginHorizontal: 30,
    width: "90%",
    borderRadius: 20,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "OpenSans_400Regular",
  },
  leadInfo: {
    margin: 5,
    marginBottom: 10,
  },
  leadBtn: {
    flexDirection: "row",
    marginRight: 10,
  },
  saveBtn: {
    marginLeft: 10,
  },
  names: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 15,
    fontWeight: "bold",
    margin: 3,
  },
  desc: {
    fontFamily: "OpenSans_400Regular",
  },
  notes: {
    backgroundColor: "white",
    marginVertical: 10,
    marginRight: 30,
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    borderRadius: 5,
    height: "90%",
    flexWrap: "wrap",
    marginBottom: 50,
  },
  lastCard: {
    backgroundColor: "white",
    marginVertical: 10,
    marginRight: 30,
    marginLeft: 10,
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    shadowColor: "#03030320",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 5,
    borderRadius: 5,
    height: "60%",
    flexWrap: "wrap",
    marginBottom: 50,
  },
  leadInfoImg: {
    margin: 5,
    marginBottom: 10,
    flexDirection: "row",
  },
  updateBtn: {
    backgroundColor: "#06949E",
  },
  documentImg: {
    margin: 5,
    marginBottom: 10,
    marginTop: 2,
  },
  circle: {
    borderRadius: 30,
    backgroundColor: "#D9D9D9",
    height: 50,
    width: 50,
    marginLeft: "5%",
  },
  colors: {
    backgroundColor: "#F36B72",
    height: "100%",
    width: "1%",
    marginRight: 20,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  btn: {
    marginLeft: "95%",
    width: "5%",
  },
  imgs: {
    height: 50,
    width: 60,
  },
  cards: {
    backgroundColor: "white",
    marginVertical: 7,
    marginRight: 50,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    fontWeight: 400,
    shadowColor: "#03030320",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 5,
    borderRadius: 10,
    flexDirection: "row",
    height: 80,
  },
  image: {
    height: 25,
    width: 25,
    marginTop: 30,
    marginRight: 10,
  },
  checkbox: {
    flexDirection: "row",
    marginLeft: "60%",
    marginTop: 20,
  },
  checkboxImg: {
    marginRight: 18,
  },
});
