import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Animated,
  Image,
  FlatList,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { translate } from "i18n-js";
import Heading from "~components/atoms/Heading";
import Icon from "~components/molecules/Icon";
import { LinearGradient } from "expo-linear-gradient";
import BaseScreen from "~Screen/BaseScreen";

const Notifications = () => {
  const navigation = useNavigation();
  const [selectedId, setSelectedId] = useState(true);

  const notifications = [
    {
      name: "Activity",
      image: "activity",
      desc: "There is a new task / activity send email to john created in project",
      read: true,
    },
    {
      name: "News",
      image: "news",
      desc: "A New Sales News has been added Click here to view the news.",
      read: false,
    },
    {
      name: "Feed",
      image: "leadproject",
      desc: "test lite has added a lead to be approved by sales.",
    },
    {
      name: "Feed",
      image: "leadproject",
      desc: "you have won contract with AGARWAL INFO SOLUTIONS",
    },
    {
      name: "Leads",
      image: "lead",
      desc: "Congratulations for winning you first lead ! ",
    },
    {
      name: "Campaign",
      image: "company",
      desc: "A new Campaign super earner is started  ",
    },
  ];
  const flatlist = [
    { title: " All ", id: "1" },
    { title: "Unread", id: "2" },
    { title: " Read ", id: "3" },
  ];
  const Item = ({ item, onPress, backgroundColor, textColor }) => (
    <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
      <Text style={[styles.title, textColor]}>{item.title}</Text>
    </TouchableOpacity>
  );

  const renderItem = ({ item }) => {
    const backgroundColor = item.id === selectedId ? "#06949E" : "#D9D9D9";
    const color = item.id === selectedId ? "white" : "black";
    return (
      <TouchableOpacity>
        <Item
          item={item}
          onPress={() => setSelectedId(item.id)}
          backgroundColor={{ backgroundColor }}
          textColor={{ color }}
        />
      </TouchableOpacity>
    );
  };
  const topDistance = useRef(new Animated.Value(500));

  const startAnimation = () => {
    Animated.timing(topDistance.current, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start();
  };
  const descendAnimation = () => {
    topDistance.current.setValue(500);
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () => {
      descendAnimation();
    });
    const unsubscribe2 = navigation.addListener("focus", () => {
      startAnimation();
    });

    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, []);

  return (
    <BaseScreen header={true}>
      <Animated.View
        style={{ transform: [{ translateY: topDistance.current }] }}
      >
        <Heading style={{ marginLeft: 16 }}>NOTIFICATIONS</Heading>
        <ScrollView>
          <FlatList
            horizontal
            data={flatlist}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            extraData={selectedId}
          />
          {notifications.map((item, index) => (
            <TouchableOpacity key={index.toString()}>
              <LinearGradient
                style={styles.container}
                start={{ x: 0.5, y: 0.5 }}
                //colors={["#439368", "#3C93B8"]}
                colors={item.read ? ["grey", "grey"] : ["#3C93B8", "#439368"]}
              >
                <View
                  style={{
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    marginVertical: 10,
                    marginHorizontal: 20,
                    borderRadius: 10,
                  }}
                >
                  <Icon
                    color={"white"}
                    size={24}
                    family="custom"
                    name={item.image}
                    style={{ marginRight: 10 }}
                  />
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    {item.name}
                  </Text>
                  <Text
                    style={{
                      fontSize: 13,
                    }}
                  >
                    {item.desc}
                  </Text>
                </View>
              </LinearGradient>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </Animated.View>
    </BaseScreen>
  );
};

export default Notifications;

const styles = StyleSheet.create({
  item: {
    padding: 10,
    margin: 7,
    borderRadius: 20,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginHorizontal: 26,
  },
  container: {
    borderRadius: 15,
    marginHorizontal: 5,
    marginTop: 20,
    shadowColor: "#03030340",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 3,
    marginBottom: 25,
  },
});
