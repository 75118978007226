import * as React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { List, DataTable } from "react-native-paper";
import Switch from "~components/atoms/Switch";
import { i18n } from "~Utils/i18n";
import Input from "~components/atoms/TextInput";
import Button from "~components/atoms/Button";
// import styles from '~Screen/Home/styleWeb'

const goals = {
  data: {
    data: [
      {
        goal_id: 1,
        Goal: {
          goal: "goal1",
          GoalEventMappings: [
            {
              event_id: 1,
              Event: {
                event: "create lead",
                sale_person_points: "200.00",
                non_sale_person_points: "100.00",
              },
            },
            {
              event_id: 2,
              Event: {
                event: "won lead",
                sale_person_points: "500.00",
                non_sale_person_points: "200.00",
              },
            },
            {
              event_id: 3,
              Event: {
                event: "test action",
                sale_person_points: "500.00",
                non_sale_person_points: "200.00",
              },
            },
          ],
        },
      },
      {
        goal_id: 2,
        Goal: {
          goal: "goal2",
          GoalEventMappings: [
            {
              event_id: 3,
              Event: {
                event: "event3",
                sale_person_points: "100.00",
                non_sale_person_points: "50.00",
              },
            },
            {
              event_id: 4,
              Event: {
                event: "event4",
                sale_person_points: "200.00",
                non_sale_person_points: "100.00",
              },
            },
          ],
        },
      },
    ],
    status: true,
    msg: "Data found.",
  },
  errorMessage: "",
  dataRedisOrDB: "Data fetched from DB",
};

const CampaignAccordion = () => {
  const [expanded, setExpanded] = React.useState(true);

  const handlePress = () => setExpanded(!expanded);

  return (
    <List.Section>
      {goals.data.data.map((x) => (
        <List.Accordion title={x.Goal.goal} left={(props) => <Switch />}>
          <>
            {/* Table Header */}
            <DataTable.Header
              style={{
                borderBottomWidth: 0,
              }}
            >
              <DataTable.Title style={{ flex: 2, justifyContent: "center" }}>
                <Text style={styles.header}>
                  {i18n.t("createCampaign.action")}
                </Text>
              </DataTable.Title>
              <DataTable.Title style={{ flex: 1, justifyContent: "center" }}>
                <Text style={styles.header}>
                  {i18n.t("createCampaign.nonSalesPoints")}
                </Text>
              </DataTable.Title>
              <DataTable.Title style={{ flex: 1, justifyContent: "center" }}>
                <Text style={styles.header}>
                  {i18n.t("createCampaign.salesPoints")}
                </Text>
              </DataTable.Title>
            </DataTable.Header>

            {x.Goal.GoalEventMappings.map((action, index) => (
              <DataTable.Row style={{ borderBottomWidth: 0 }}>
                {/* Actions */}
                <DataTable.Cell style={{ flex: 2, justifyContent: "center" }}>
                  <Button
                    title={action.Event.event}
                    style={{
                      backgroundColor:
                        index === 0
                          ? "#2F774A"
                          : index === 1
                          ? "#39A14C"
                          : "#D9D9D9",
                      borderRadius: 0,
                      width: 350,
                    }}
                  />
                </DataTable.Cell>
                {/* Non Sales Points */}
                <DataTable.Cell style={{ flex: 1, justifyContent: "center" }}>
                  <Input
                    value={action.Event.non_sale_person_points}
                    style={styles.pointsInput}
                  />
                </DataTable.Cell>
                {/* Sales Points */}
                <DataTable.Cell style={{ flex: 1, justifyContent: "center" }}>
                  <Input
                    value={action.Event.sale_person_points}
                    style={styles.pointsInput}
                  />
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </>
        </List.Accordion>
      ))}
    </List.Section>
  );
};

export default CampaignAccordion;

const styles = StyleSheet.create({
  header: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 15,
    fontWeight: "600",
    borderBottomWidth: 0,
    color: "#000",
  },
  pointsInput: {
    width: 100,
  },
});
