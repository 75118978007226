import React, { createContext, useEffect } from "react";
import { DeviceEventEmitter } from "react-native";
import { ACCESS_TOKEN_KEY } from "~Services/Api/constants";
import SecureStorage from "~Utils/SecureStorage";

interface IAuthContext {
  isLoggedIn: boolean;
  logout: () => void;
  makeUserLogin: () => void;
}

const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(true);

  useEffect(() => {
    SecureStorage.getItem(ACCESS_TOKEN_KEY).then((value) => {
      if (!!value) login();
      else logout();
    });
    const a = DeviceEventEmitter.addListener("logout", () => {
      logout();
    });
    () => a.remove();
  }, []);

  const logout = () => {
    setIsLoggedIn(false);
  };

  const login = () => {
    setIsLoggedIn(true);
  };

  const api = {
    logout,
    makeUserLogin: login,
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, ...api }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const ctx = React.useContext(AuthContext);

  if (!ctx) {
    throw new Error("useAuth must be used within the AuthProvider");
  }

  return ctx;
};
