import { StyleSheet, Text, View, ViewStyle } from "react-native";
import React from "react";
import Drawer from "../Drawer/index.web";

interface LeftMenuProps {
  handleSelection: any;
  style?: ViewStyle;
  res: string;
}

const LeftMenu: React.FunctionComponent<LeftMenuProps> = ({
  handleSelection,
  style,
  res,
}) => {
  return (
    <>
      <View style={style}>
        {res !== "xsmall" && (
          <Drawer
            setter={(id) => {
              handleSelection(id);
            }}
          />
        )}
      </View>
    </>
  );
};

export default LeftMenu;

const styles = StyleSheet.create({});
