import { StyleSheet, View, TextInput, TextInputProps } from "react-native";

import React from "react";
import Icon from "react-native-vector-icons/Feather";
import { useTheme } from "~Contexts/Theme";

interface Props extends TextInputProps {
  leftIcon?: string;
  iconSize?: number;
  iconColor?: string;
}

const Input: React.FC<Props> = ({
  style,
  leftIcon,
  iconSize,
  iconColor,
  ...rest
}) => {
  const { theme } = useTheme();

  return (
    <View style={styles.viewStyle}>
      {leftIcon ? (
        <Icon
          name={leftIcon}
          size={iconSize || 20}
          color={iconColor || "#000"}
          style={styles.iconStyle}
        />
      ) : null}
      <TextInput
        style={[styles.inputStyle, style]}
        placeholderTextColor={"#808080"}
        autoCapitalize={"none"}
        {...rest}
      />
    </View>
  );
};

export default Input;
const styles = StyleSheet.create({
  viewStyle: {
    flexDirection: "row",
    paddingVertical: 5,
    backgroundColor: "white",
  },
  iconStyle: {
    paddingLeft: 18,
  },
  inputStyle: {
    alignSelf: "center",
    width: "100%",
    padding: 18,
    borderWidth: 1,
    borderRadius: 9,
    borderColor: "#979797",
  },
});
