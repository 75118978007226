import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
  Image,
  FlatList,
  ScrollView,
} from "react-native";
import React, { useState } from "react";
import Layout from "~components/atoms/Layout/index.web";
import { useNavigation } from "@react-navigation/native";
import ProjectDetail from "~Screen/DetailScreens/ProjectDetail";

const Project = [
  {
    title: "Enterprise Services",
    date: "Last Updated: 09-Sep-2022",
    team: "Team:",
  },
  { title: "Research SME", date: "Last Updated: 07-Sep-2022" },
  {
    title: "Lead For Leadx By Devendra Phadnis",
    date: "Last Updated: 05-Sep-2022",
  },
  { title: "Architecture And Planning", date: "Last Updated: 06-Sep-2022" },
  { title: "Consulting Requirement", date: "Last Updated: 05-Sep-2022" },
  { title: "Testing-Lang", date: "Last Updated: 05-Sep-2022" },
  { title: "Teat Language", date: "Last Updated: 05-Sep-2022" },
  { title: "Sme-Package-New", date: "Last Updated: 04-Sep-2022" },
];

const flatlists = [
  { id: "1", title: "All Projects" },
  { id: "2", title: "Running Projects" },
  { id: "3", title: "Closed Projects" },
];
interface Props {
  item: string;
  onPress?: () => void;
  backgroundColor: ViewStyle;
  textColor: ViewStyle;
}

const Item: React.FC<Props> = ({
  item,
  onPress,
  backgroundColor,
  textColor,
}) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
    <Text style={[styles.title, textColor]}>{item.title}</Text>
  </TouchableOpacity>
);

const LeadProjects = () => {
  const [selectedId, setSelectedId] = useState(null);
  const navigation = useNavigation();

  const renderItem = ({ item }) => {
    const backgroundColor = item.id === selectedId ? "#06949E" : "#D9D9D9";
    const color = item.id === selectedId ? "white" : "black";
    return (
      <Item
        item={item}
        onPress={() => setSelectedId(item.id)}
        backgroundColor={{ backgroundColor }}
        textColor={{ color }}
      />
    );
  };
  return (
    <Layout>
      <ScrollView style={styles.container}>
        <Text style={styles.heading}>LEAD PROJECTS</Text>
        <TouchableOpacity>
          <FlatList
            horizontal
            data={flatlists}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            extraData={selectedId}
          />
        </TouchableOpacity>

        {Project.map((data) => (
          <View style={styles.box}>
            <View style={styles.cardHeading}>
              <Image
                style={styles.img}
                source={require("../../Assets/Images/LeadProjectImg.png")}
              />
              <View>
                <Text style={styles.name}>{data.title}</Text>
                <Text style={styles.dates}>{data.date}</Text>
              </View>
            </View>
            <TouchableOpacity 
                style={styles.arrowImg}
                onPress={() => {
                  navigation.navigate("ProjectDetail");
                }}
                >
              <Image
                style={styles.arrow}
                source={require("../../Assets/Images/Arrow.png")}
              />
            </TouchableOpacity>
            <View style={styles.teamImg}>
              <Text style={styles.teamName}>Team: </Text>
              <Image
                style={styles.img}
                source={require("../../Assets/Images/LeadProjectImg.png")}
              />
              <Image
                style={styles.img}
                source={require("../../Assets/Images/LeadProjectImg.png")}
              />
              <Image
                style={styles.img}
                source={require("../../Assets/Images/LeadProjectImg.png")}
              />
              <Image
                style={styles.img}
                source={require("../../Assets/Images/LeadProjectImg.png")}
              />
            </View>
          </View>
        ))}
      </ScrollView>
    </Layout>
  );
};

export default LeadProjects;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 8,
  },
  colorBorder: {
    height: 10,
    width: 10,
    backgroundColor: "pink",
    justifyContent: "flex-start",
    marginVertical: 10,
    paddingVertical: 20,
  },
  heading: {
    fontWeight: "bold",
    fontSize: 20,
    marginHorizontal: 8,
    color: "#413E3E",
  },
  item: {
    padding: 10,
    marginVertical: 12,
    marginHorizontal: 30,
    width: "90%",
    borderRadius: 20,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
  },
  box: {
    backgroundColor: "white",
    marginVertical: 10,
    marginRight: 30,
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    fontWeight: 400,
    shadowColor: "#03030320",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 5,
    borderRadius: 5,
  },
  cardHeading: {
    flexDirection: "row",
  },
  img: {
    height: 60,
    width: 60,
    padding: 3,
  },
  name: {
    fontWeight: "bold",
    fontSize: 15,
    marginLeft: 10,
    marginTop: 5,
  },
  dates: {
    marginLeft: 10,
    fontSize: 14,
  },
  arrow: {
    height: 25,
    width: 25,
    alignItems: "flex-end",
  },
  arrowImg: {
    alignItems: "flex-end",
    marginRight: 8,
  },
  teamName: {
    fontWeight: "bold",
    fontSize: 15,
    marginLeft: 10,
    marginTop: 16,
    marginRight: 8,
  },
  teamImg: {
    flexDirection: "row",
    height: 40,
    width: 60,
  },
});
