import {
  Alert,
  PermissionsAndroid,
  Platform,
  useWindowDimensions,
} from "react-native";
import ImagePicker from "react-native-image-crop-picker";
import DocumentPicker from "react-native-document-picker";
import { ALERT_STRINGS } from "~constants/stringConstants";

export const formatPhoneNumber = (value) => {
  if (!value) {
    return value;
  }
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber?.length;
  if (phoneNumberLength < 4) {
    return phoneNumber;
  }
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const useResolution = () => {
  const { width, height } = useWindowDimensions();
  console.log("width", width);
  console.log("width", height);
  if (width < 428) {
    return "xsmall";
  } else if (width < 768) {
    return "small";
  } else if (width < 1200) {
    return "medium";
  } else {
    return "large";
  }
};

export const getInitials = (name = "") => {
  if (!name) return "";
  const arr = name.split(" ");
  const first = arr[0].charAt(0).toUpperCase();
  const last = arr[1].charAt(0).toUpperCase();
  return first + last;
};

export const maskEmail = (email) => {
  return email.replace(
    /^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, "*") + c
  );
};
