import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import React from "react";
import { useCustomFonts } from "~helpers/fonts";
import AppLoading from "expo-app-loading";
import Link from "../Link";

interface Props {
  title: string;
  label: string;
  onPress?: () => void;
  menuPress?: () => void;
  viewStyle: ViewStyle;
  children?: any;
}

const Card: React.FC<Props> = ({
  title,
  label,
  onPress,
  viewStyle,
  children,
  menuPress,
}) => {
  const [fontsLoaded, error] = useCustomFonts();
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  return (
    <TouchableOpacity onPress={onPress} style={[styles.container, viewStyle]}>
      <View style={styles.card}>
        <Text style={styles.title}>{title}</Text>
        <TouchableOpacity onPress={menuPress}>
          <View
            style={{
              height: 20,
              width: 20,
              borderRadius: 10,
              backgroundColor: "#ED464F",
              borderColor: "white",
              borderWidth: 1,
            }}
          >
            <Text
              style={{
                alignSelf: "center",
                color: "white",
                fontSize: 12,
                fontFamily: "OpenSans_400Regular",
              }}
            >
              6
            </Text>
          </View>
        </TouchableOpacity>
      </View>
      <View>{children}</View>

      <Link
        style={{
          color: "white",
          alignSelf: "flex-end",
          marginHorizontal: 12,
          paddingBottom: 5,
          fontSize: 12,
        }}
        title={label}
      ></Link>
    </TouchableOpacity>
  );
};

export default Card;

const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: "#00000080",
    borderStyle: "solid",
    borderRadius: 15,
    marginHorizontal: 10,
    marginTop: 10,
    shadowColor: "#03030340",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 3,
  },
  card: {
    paddingHorizontal: 22,
    paddingVertical: 10,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  title: {
    fontFamily: "OpenSans_400Regular",
    // fontStyle: normal,
    fontWeight: "400",
    fontSize: 16,
    color: "white",
  },
});
