import {
  Dimensions,
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
} from "react-native";
import React from "react";
import style from "../../../../storybook/stories/CenterView/style";
import { ScrollView } from "react-native-gesture-handler";
import moment from "moment";
import { useResolution } from "~helpers/utilities";
import { horizontalScale, verticalScale } from "~helpers/basicStyles";

interface CardProps {
  data: any;
}

const NewsCards: React.FunctionComponent<CardProps> = ({ data }) => {
  const res = useResolution();
  return (
    <TouchableOpacity
      style={[
        styles.viewStyle,
        res !== "large" && {
          width: Dimensions.get("window").width / 1.7,
        },
        res === "small" && {
          width: Dimensions.get("window").width / 2.2,
        },
      ]}
    >
      {!data.read && (
        <View
          style={{
            height: 20,
            width: 20,
            borderRadius: 20,
            backgroundColor: "green",
            position: "absolute",
            top: 10,
            right: 10,
          }}
        />
      )}
      <Text style={styles.cardHeading}>{data.heading}</Text>
      <Image
        style={styles.img}
        source={require("../../../Assets/Images/newsCard.png")}
      />
      <Text numberOfLines={2} style={styles.desc}>
        Vedanta’s risk-reward more favourable now. Test Professional has entered
        Vedanta’s risk-reward more favourable now. Test Professional has entered
        Vedanta’s risk-reward more favourable now. Test Professional has entered
        Vedanta’s risk-reward more favourable now. Test Professional has entered
        Vedanta’s risk-reward more favourable now. Test Professional has entered
      </Text>
      <Text style={styles.timestamp}>
        {moment("20220922", "YYYYMMDD").fromNow()}
      </Text>
    </TouchableOpacity>
  );
};

export default NewsCards;

const styles = StyleSheet.create({
  viewStyle: {
    width: Dimensions.get("window").width / 2.8,
    marginBottom: verticalScale(15),
    marginRight: horizontalScale(20),
    shadowColor: "black",
    shadowOpacity: 0.25,
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 15,
    borderRadius: 15,
    padding: 20,
  },
  containerStyle: {
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: Dimensions.get("window").width - 280,
    paddingLeft: 50,
  },
  cardHeading: {
    fontWeight: "bold",
    fontSize: 15,
    marginBottom: 10,
  },
  img: {
    height: 200,
    width: "100%",
    borderRadius: 5,
    alignSelf: "center",
  },
  desc: {
    color: "grey",
    marginVertical: 10,
  },
  timestamp: {
    color: "grey",
    fontSize: 12,
  },
});
