export default {
        lead:{
                createLead              :   "/lead/add",                        //Implemented
                getSections             :   "/sections/getSections/lead",       //Implemented
                addLeadDocument         :   "/lead/addLeadDocument",            //Implemented
                getCardView             :   "/lead/kpis/cardview",              //Implemented
                getLeadsByFilter        :   "/lead/getList",                    //Implemented
                getLeadsByCompany       :   "/lead/getLeadList"                 //Implemented
        },
        master:{
                getLeadSource           :   "/master/getMstLeadSource",         //Implemented      
                getCurrencies           :   "/master/getMstCurrency",           //Implemented
                getCategories           :   "/master/getCategories",            //Implemented
                addLeadCategory         :   "/master/addCategory",              //Implemented
                getLicenseTypes         :   "/master/getLicenseType",           //Implemented
                getActivityTypes        :   "/master/getMstActivityType"        //Implemented
        },
        account:{
                getOrganizationOptions  :   "/account/getOrganizationOptions",  //Implemented
                getAllLabels            :   "/account/getMyLabels",             //Implemented  
        },
        user:{
                getUserList             :   "/users/getUserList",               //Implemented    
                additionalAttributes    :   "/users/additional_attributes/get", //Implemented
                getUsersByAccount       :   "/users/getUserListByAccountId",    //Implemented
                getAllPermissions       :   "/users/getMyPermissions",          //Implemented
                getAllAccounts           :   "/users/getAllMyAccounts",          //Implemented
        },
        auth:{
                accessToken             :   "/users/access_token",              
        },
        company:{
                getCompaniesByQuery              :   "/contact/company/get",     //Implemented            
                createCompany                    :   "/contact/company/add",             
                contact:{
                    getContactsByQuery           :   "/contact/person/get",      //Implemented
                    getContactsList              :   "/contact/person/getList",      
                    createContactPerson          :   "/contact/person/add",
                }

        },
        salesnews:{
                getSalesNewsByQuery     :   "/salesNews/get",                       //Implemented             
        },
        feed:{
                getCurrentFeed          :   "/salesFeed/get",                       //Implemented                  
        },
        activity:{
                getActivitiesByQuery    :   "/salesActivity/getActivityTaskList",   //Implemented
        },
        

}