import Layout from '~components/atoms/Layout/index.web'
import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  ScrollView,
  ViewStyle,
  TouchableOpacity,
  FlatList,
} from 'react-native'
import React, { useState } from 'react'
import LinearGradient from 'react-native-linear-gradient'
import { i18n } from '~Utils/i18n'
import CampaignCard from '~components/molecules/CampaignCard/index.web'
import { useResolution, getInitials } from '~helpers/utilities'
import { horizontalScale } from '~helpers/basicStyles'
import { useNavigation } from '@react-navigation/native'
import { useCustomFonts } from '~helpers/fonts'
import AppLoading from 'expo-app-loading'
import styles from './styleWeb'

// use moment to format date
const campaigns = [
  {
    id: '1',
    title: 'Super Earner',
    leads: '15',
    won: '5',
    inProgress: '10',
    success: '35',
    startDate: '1 September 2022',
    endDate: '31 December 2022',
    leader: 'Sahil',
    winner: '',
  },
  {
    id: '2',
    title: 'Camapign 2',
    leads: '25',
    won: '15',
    inProgress: '15',
    success: '48',
    startDate: '1 October 2022',
    endDate: '30 November 2022',
    leader: 'Ellie',
    winner: '',
  },
  {
    id: '3',
    title: 'Campaign 3',
    leads: '35',
    won: '7',
    inProgress: '12',
    success: '20',
    startDate: '1 January 2022',
    endDate: '31 December 2022',
    leader: 'Molly',
    winner: '',
  },
  {
    id: '4',
    title: 'Campaign 4',
    leads: '15',
    won: '5',
    inProgress: '10',
    success: '35',
    startDate: '1 September 2022',
    endDate: '31 December 2022',
    leader: 'Test',
    winner: '',
  },
]

const campaignType = [
  { id: '1', title: 'All Campaigns' },
  { id: '2', title: 'Running Campaigns' },
  { id: '3', title: 'Upcoming Campaigns' },
  { id: '4', title: 'Closed Campaigns' },
]

interface Props {
  item: string
  onPress?: () => void
  backgroundColor: ViewStyle
  textColor: ViewStyle
}

const Item: React.FC<Props> = ({
  item,
  onPress,
  backgroundColor,
  textColor,
}) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
    <Text style={[styles.title, textColor]}>{item.title}</Text>
  </TouchableOpacity>
)

const CampaignHome = () => {
  const navigation = useNavigation()
  const [selectedId, setSelectedId] = useState(null)
  const res = useResolution()

  const renderItem = ({ item }) => {
    const backgroundColor = item.id === selectedId ? '#06949E' : '#D9D9D9'
    const color = item.id === selectedId ? 'white' : 'black'
    return (
      <Item
        item={item}
        onPress={() => setSelectedId(item.id)}
        backgroundColor={{ backgroundColor }}
        textColor={{ color }}
      />
    )
  }
  const handleSelection = (id) => {
    console.log('id', id)
    navigation.navigate('CampaignDetail')
  }

  // Fonts Loading
  const [fontsLoaded, error] = useCustomFonts()
  if (!fontsLoaded) {
    return <AppLoading />
  }

  return (
    <Layout>
      <Text style={styles.heading}>Campaigns</Text>
      <View style={styles.tabContainer}>
        <FlatList
          showsHorizontalScrollIndicator={false}
          style={{
            maxWidth:
              Dimensions.get('window').width - (250 + horizontalScale(8)),
          }}
          horizontal
          data={campaignType}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          extraData={selectedId}
        />
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={[
          styles.containerStyle,
          res === 'xsmall' && {
            maxWidth: Dimensions.get('window').width,
          },
        ]}
      >
        {campaigns.map((campaign, index) => (
          <CampaignCard
            title={campaign.title}
            leads={campaign.leads}
            won={campaign.won}
            inProgress={campaign.inProgress}
            success={campaign.success}
            leader={campaign.leader}
            winner={campaign.winner}
            startDate={campaign.startDate}
            endDate={campaign.endDate}
            onPress={() => {
              handleSelection(campaign.id)
            }}
          />
        ))}
      </ScrollView>
    </Layout>
  )
}

export default CampaignHome
// const styles = StyleSheet.create({
//   containerStyle: {
//     flexDirection: 'row',
//     flexWrap: 'wrap',
//     maxWidth: Dimensions.get('window').width - 280,
//     justifyContent: 'center',
//   },
//   mainContainer: {
//     maxWidth: Dimensions.get('window').width - 280,
//   },
//   heading: {
//     marginLeft: 62,
//     fontFamily: 'OpenSans_400Regular',
//     fontWeight: '600',
//     fontSize: 25,
//   },
//   title: {
//     fontSize: 12,
//     fontWeight: 'bold',
//     textAlign: 'center',
//   },
//   tabContainer: {
//     marginLeft: 62,
//     marginBottom: 15,
//   },
//   item: {
//     padding: 10,
//     marginVertical: 12,
//     marginRight: 10,
//     paddingHorizontal: 35,
//     borderRadius: 20,
//   },
// })
