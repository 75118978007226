import { Dimensions, StyleSheet } from "react-native";
import { horizontalScale } from "~helpers/basicStyles";

const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: Dimensions.get("window").width - 280,
    paddingLeft: 50,
  },
  headerStyle:{
          fontSize: 20,
          fontWeight: "700",
          color: "#413E3E",
          marginLeft: 60,
          marginBottom: 10,
        } ,
  topContainer: {
    flexDirection: "row",
    height: 100,
  },
  logoContainer: {
    position: "absolute",
    left: 10,
  },
  logoStyle: {
    height: 50,
    width: 100,
    marginVertical: 22,
    marginHorizontal: 22,
  },
  searchView: {
    width: "50%",
  },
  searchStyle: {
    width: "100%",
    height: "50%",
    borderRadius: 9,
    borderColor: "#979797",
    borderStyle: "solid",
    borderWidth: 1,
    marginTop: 22,
    shadowOffset: "none",
  },
  verticleLine: {
    height: "80%",
    backgroundColor: "red",
    width: 2,
    marginHorizontal: 10,
    justifyContent: "center",
  },
  rightContainer: {
    position: "absolute",
    right: 10,
    height: 50,
    marginVertical: 22,
    marginHorizontal: 30,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  iconStyle: {
    width: 30,
    height: 30,
    marginHorizontal: 10,
  },
  leftContainer: {
    position: "absolute",
    zIndex: 1,
  },
  profile: {
    marginLeft: 5,
  },
  viewStyle: {
    flexDirection: "row",
  },
  cardStyle: {
    width: 200,
  },
  cardView: {
    flexWrap: "wrap",
    marginVertical: 40,
  },
  cardViewStyle: {
    marginBottom: 25,
    marginRight: 20,
  },
});
export default styles;
