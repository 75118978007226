import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import { useTheme } from "~Contexts/Theme";

interface Props {
  title: string;
  onPress?: () => void;
  style?: ViewStyle;
}
const Link: React.FC<Props> = ({ title, style, ...rest }) => {
  return (
    <TouchableOpacity
      style={[
        {
          padding: 1,
        },
        style,
      ]}
      {...rest}
    >
      <Text
        style={[
          styles.linkStyle,
          { color: useTheme().theme.linkColor.lightColor },
          style,
        ]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export default Link;

const styles = StyleSheet.create({
  linkStyle: {
    fontSize: 16,
    fontWeight: "400",
    textDecorationLine: "underline",
  },
});
