import { Platform, StyleSheet, Text, View } from "react-native";
import React from "react";
import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import {
  ActivityDetail,
  CampaignDetail,
  CreateContact,
  CreateLead,
  CreateNews,
  CreatePerson,
  CreateTask,
  FeedDetail,
  Home,
  LeadDetail,
  NewsDetail,
  ProfileScreen,
  ProjectDetail,
} from "~Screen";
import News from "~Screen/News";
import Leads from "~Screen/Lead";
import LeadWorkspaces from "~Screen/LeadProject";
import Shop from "~Screen/Shop";
import Campaigns from "~Screen/Campaigns";
import Contacts from "~Screen/Contacts";
import Activities from "~Screen/Activities";
import Post from "~Screen/Post";
import Administration from "~Screen/Administration";
import Dashboard from "~Screen/Dashboard";
import LeadProjects from "~Screen/LeadProject";
import SalesFeed from "~Screen/SalesFeed";
import CreateActivity from "~Screen/Create/CreateActivity";
import CreateCompany from "~Screen/Create/CreateCompany";
import CreateCampaign from "~Screen/Create/CreateCampaign";
import CreateProject from "~Screen/Create/CreateProject";
import EditActivity from "~Screen/Edit/ActivityEdit";
const Stack = createStackNavigator();


const HomeStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        animationEnabled: Platform.OS === "web" ? false : true,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      <Stack.Screen name="Home" component={Home} />
      <Stack.Screen name="CreateLead" component={CreateLead} />
      <Stack.Screen name="CreateNews" component={CreateNews} />
      <Stack.Screen name="CreateTask" component={CreateTask} />
      <Stack.Screen name="CreateContact" component={CreateContact} />
      <Stack.Screen name="CreateActivity" component={CreateActivity} />
      <Stack.Screen name="CreateCompany" component={CreateCompany} />
      <Stack.Screen name="CreateCampaign" component={CreateCampaign} />
      <Stack.Screen name="CreatePerson" component={CreatePerson} />
      <Stack.Screen name="CreateProject" component={CreateProject} />
      <Stack.Screen name="ActivityDetail" component={ActivityDetail} />
      <Stack.Screen name="CampaignDetail" component={CampaignDetail} />
      <Stack.Screen name="FeedDetail" component={FeedDetail} />
      <Stack.Screen name="LeadDetail" component={LeadDetail} />
      <Stack.Screen name="NewsDetail" component={NewsDetail} />
      <Stack.Screen name="ProjectDetail" component={ProjectDetail} />
      <Stack.Screen name="Contacts" component={Contacts} />
      <Stack.Screen name="News" component={News} />
      <Stack.Screen name="Leads" component={Leads} />
      <Stack.Screen name="LeadProjects" component={LeadProjects} />
      <Stack.Screen name="Shop" component={Shop} />
      <Stack.Screen name="Campaigns" component={Campaigns} />
      <Stack.Screen name="Activities" component={Activities} />
      <Stack.Screen name="Sales" component={SalesFeed} />
      <Stack.Screen name="Post" component={Post} />
      <Stack.Screen name="Profile" component={ProfileScreen} />
      <Stack.Screen name="EditActivity" component={EditActivity} />
      {Platform.OS === "web" && (
        <Stack.Screen name="Administration" component={Administration} />
      )}
      <Stack.Screen name="Dashboard" component={Dashboard} />
    </Stack.Navigator>
  );
};

export default HomeStack;

const styles = StyleSheet.create({});
