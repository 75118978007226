import { useQuery } from "react-query";
import Services from "~Services";

export default function useHeader() {
  const { data } = useQuery(
    "bonusPoint",
    Services.bonusPoint.getMyRewardPoints
  );
  return {
    bonusPoint: parseInt(data?.pointsData.points || "0"),
    user: data?.userData,
  };
}
