import { Dimensions, StyleSheet, Text, View } from "react-native";
import React from "react";
import LeftMenu from "~components/molecules/LeftMenu/index.web";
import Header from "~components/molecules/Header/index.web";
import { useResolution } from "~helpers/utilities";
import { useNavigation } from "@react-navigation/native";

const screenMappings = {
  0: "Home",
  1: "News",
  2: "Activities",
  3: "Leads",
  4: "LeadProjects",
  5: "Campaigns",
  6: "Dashboard",
  7: "Administration",
};

interface LayoutProps {
  children: any;
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const res = useResolution();
  const navigation = useNavigation();

  const handleProfile = () => {};
  const handleSelection = (id) => {
    console.log("id", id);
    navigation.navigate(screenMappings[id]);
  };

  return (
    <View style={{ flexDirection: "row" }}>
      <LeftMenu handleSelection={handleSelection} res={res} />
      <View>
        <Header handleProfile={handleProfile} />
        <View
          style={{
            maxHeight: Dimensions.get("window").height - 105,
            backgroundColor: "#F5F5F5",
          }}
        >
          {children}
        </View>
      </View>
    </View>
  );
};

export default Layout;

const styles = StyleSheet.create({});
