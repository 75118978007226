import {
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React from "react";
import Modal from "react-native-modal";

interface ModalProps {
  isVisible: boolean;
  children: any;
  setter: any;
}

const Transparent = ({ isVisible, children, setter }) => {
  return (
    <Modal visible={isVisible}>
      <TouchableWithoutFeedback onPress={() => setter((prev) => !prev)}>
        <View
          style={{
            position: "absolute",
            top: -300,
            bottom: -300,
            right: -300,
            left: -300,
          }}
        />
      </TouchableWithoutFeedback>
      {children}
    </Modal>
  );
};

export default Transparent;

const styles = StyleSheet.create({});
