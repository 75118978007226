import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const EditActivity = () => {
  return (
    <View>
      <Text>EditActivity</Text>
    </View>
  )
}

export default EditActivity

const styles = StyleSheet.create({})