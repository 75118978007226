import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { CheckBox } from "react-native-web";
import React, { useState } from "react";
import Common from "~components/molecules/commonpages/index.web";
import { useNavigation } from "@react-navigation/native";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import AppLoading from "expo-app-loading";
import Button from "~components/atoms/Button";
import Input from "~components/atoms/TextInput";
import Link from "~components/atoms/Link";
import Dropdown from "~components/atoms/Dropdown";
import styles from "./style.web";
import { i18n } from "~Utils/i18n";
import { useCustomFonts } from "~helpers/fonts";
import { useResolution } from "~helpers/utilities";

const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .required("Please enter your email address"),
  name: Yup.string().required("Name is required"),
});

const SignUp = () => {
  const [accept, setAccept] = useState(false);
  const resolution = useResolution();
  const navigation = useNavigation();
  const [aspectRatio, setAspectRatio] = useState(0);
  const [fontsLoaded, error] = useCustomFonts();

  if (!fontsLoaded) {
    return <AppLoading />;
  }
  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
      }}
      validationSchema={SignUpSchema}
      onSubmit={(values) => {
        console.log("values", values);
        navigation.navigate("SignUpSuccess");
      }}
    >
      {({
        values,
        touched,
        isValid,
        handleChange,
        setFieldTouched,
        handleSubmit,
        errors,
      }) => (
        <Common>
          <View style={styles.container}>
            <View
              style={resolution !== "small" ? [{ marginVertical: 30 }] : []}
            >
              <Link
                title={i18n.t("signUp.signIn")}
                onPress={() => {
                  navigation.navigate("Login");
                }}
                style={styles.textEnd}
              />
              <Image
                onLoad={(e) => {
                  setAspectRatio(
                    e.nativeEvent.path[0].naturalWidth /
                      e.nativeEvent.path[0].naturalHeight
                  );
                }}
                source={{
                  uri: "https://s3.eu-north-1.amazonaws.com/live-media.leadx.no/Leadx-staging/users/1655113413413_users.png",
                }}
                style={[
                  styles.logoStyle,
                  {
                    aspectRatio: aspectRatio,
                  },
                ]}
                resizeMode={"contain"}
              />
            </View>

            <View style={styles.viewContainer}>
              <View style={styles.mainView}>
                <Text style={styles.text}>{i18n.t("signUp.emailAdd")}</Text>
                <View style={styles.inputStyle}>
                  <Input
                    style={styles.input}
                    placeholder={i18n.t("signUp.placeHolder1")}
                    value={values.email}
                    onChangeText={handleChange("email")}
                    onBlur={() => setFieldTouched("email")}
                  />
                  {touched.email && errors.email && (
                    <Text style={styles.error}>{errors.email}</Text>
                  )}
                </View>
                <Text style={styles.text}>{i18n.t("signUp.name")}</Text>
                <Input
                  style={styles.input}
                  placeholder={i18n.t("signUp.placeHolder2")}
                  value={values.name}
                  onChangeText={handleChange("name")}
                  onBlur={() => setFieldTouched("name")}
                />
                {touched.name && errors.name && (
                  <Text style={styles.error}>{errors.email}</Text>
                )}
              </View>
              <View>
                <Dropdown />
              </View>

              <View style={styles.checkBoxView}>
                <CheckBox
                  style={styles.checkBox}
                  value={accept}
                  onValueChange={() => setAccept(!accept)}
                />
                <Text style={styles.text1}>{i18n.t("signUp.accept")} </Text>
                <Link
                  title={i18n.t("signUp.terms")}
                  onPress={() => {
                    navigation.navigate("Login");
                  }}
                />
              </View>
              <View style={styles.buttonView}>
                <Button
                  style={styles.button}
                  title={i18n.t("signUp.proceed")}
                  onPress={handleSubmit}
                  disabled={
                    !accept ||
                    !isValid ||
                    values.email === "" ||
                    values.name === ""
                  }
                />
              </View>
            </View>
          </View>
        </Common>
      )}
    </Formik>
  );
};

export default SignUp;
