import apiURLs from "~constants/apiURLs";
import Api from "./Api";
import { IUser } from "./Types";

type isRegisteredResponse = {
  key?: string;
  self_user_reg_config_enabled: boolean;
};

interface ICred {
  username: string;
  password: string;
}

export interface IMFA {
  mfa_enabled: boolean;
  status: boolean;
  data?: ILoginResponse;
}

interface IRefreshToken {
  refresh_token: string;
}

interface ILoginResponse {
  token_info: {
    token_type: string;
    refresh_token: string;
    access_token: string;
    expires_in: number;
  };
  event: string;
  user: IUser;
}

interface IAuth {
  login(creds: ICred): Promise<IMFA>;
  verifyOTP(data: { email: string; otp: string }): Promise<any>;
  isRegistered(): Promise<isRegisteredResponse>;
  resendOTP(email: string): Promise<string>;
}

export default class Auth implements IAuth {
  async resendOTP(email: string): Promise<string> {
    const result = await Api.post("/login/resendOTP", { email });
    return result.data.data.message;
  }

  async isRegistered(): Promise<isRegisteredResponse> {
    console.log("is reg called");
    const data = await Api.post("/login/isRegister", {});
    console.log("data", data);
    return data.data.data;
  }

  async verifyOTP(creds: {
    email: string;
    otp: string;
  }): Promise<ILoginResponse> {
    const result = await Api.post("/login/verifyOTP", creds);
    const data = result.data;
    return { token_info: data.data, event: data.event, user: data.user };
  }

  async getNotificationCount(id: number): Promise<ILoginResponse> {
    const result = await Api.getWithToken("/userDevice/getNotificationCount");
    const data = result.data;
    return data;
  }

  async login(creds: { username: string; password: string }): Promise<IMFA> {
    const result = await Api.post("/login/checkAuth", creds);
    console.log("result", result);
    const data = result.data;
    if (data.mfa_enabled) {
      return data;
    }
    return {
      data: { token_info: data.data, event: data.event, user: data.user },
      mfa_enabled: false,
      status: true,
    };
  }

  //Response pending
  // async accessToken(payload: IRefreshToken): Promise<ILoginResponse> {
  //   const result = await Api.post(apiURLs.auth.accessToken), payload);
  //   const data = result.data;
  //   return { token_info: data.data, event: data.event, user: data.user };
  // }
}

export { ICred, IUser, ILoginResponse };
