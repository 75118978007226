import React from "react";
import { StyleSheet } from "react-native";
import SwitchToggle from "react-native-switch-toggle";

interface props {
  value: boolean;
  setValue: () => void;
  disable: boolean;
}

const Switch: React.FC<props> = ({ value, setValue, disable }) => {
  return (
    <SwitchToggle
      switchOn={value}
      onPress={() => {
        disable ? {} : setValue(!value);
      }}
      containerStyle={styles.containerStyle}
      circleStyle={styles.circleStyle}
      circleColorOff={"#F9F9F9"}
      circleColorOn={"#245D00"}
      backgroundColorOn={"#D9D9D9"}
      backgroundColorOff={"#D9D9D9"}
      duration={300}
    />
  );
};
const styles = StyleSheet.create({
  containerStyle: {
    width: 47,
    height: 18,
    borderRadius: 15,
    padding: 2,
  },
  circleStyle: {
    width: 22,
    height: 15,
    borderRadius: 15,
  },
});

export default Switch;
