import React from "react";
import { baseColors } from "~theme";
import { getIconType } from "./getIconType";

const Icon = (props) => {
  const { name = "", family = "", size, color = "black", ...rest } = props;
  const IconInstance = getIconType(family);
  return (
    <IconInstance
      name={name}
      size={size || 20}
      color={color || baseColors.borderColor}
      {...rest}
    />
  );
};

export default Icon;
