import {
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import React, { Children } from "react";
import { List } from "react-native-paper";
import Icon from "~components/molecules/Icon";

interface Props {
  children: any;
  label: string;
  style: ViewStyle;
  titleStyle: TextStyle;
}

const Accordion: React.FunctionComponent<Props> = ({
  children,
  label,
  style,
  titleStyle,
}) => {
  const [expanded, setExpanded] = React.useState(true);
  const handlePress = () => setExpanded(!expanded);
  return (
    <View>
      <List.Section>
        <List.Accordion
          titleStyle={[{ fontWeight: "700", color: "black" }, titleStyle]}
          style={[
            {
              borderWidth: 0.5,
              borderRadius: 5,
              backgroundColor: "white",
            },
            style,
          ]}
          title={label}
          right={(props) => {
            console.log("props", props);
            return (
              <Icon
                color={"black"}
                size={20}
                family="antdesign"
                name={!props.isExpanded ? "plus" : "minus"}
                // style={{ marginRight: 10 }}
              />
            );
          }}
        >
          {children}
        </List.Accordion>
      </List.Section>
    </View>
  );
};

export default Accordion;

const styles = StyleSheet.create({});
