import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  ViewStyle,
  FlatList,
  Dimensions,
} from "react-native";
import React, { FC, useState } from "react";
import LeadItem from "~components/atoms/PipelineList/index.web";
import Layout from "~components/atoms/Layout/index.web";
import Heading from "~components/atoms/Heading";
import { horizontalScale } from "~helpers/basicStyles";
import { useNavigation } from "@react-navigation/native";

const DATA = [
  {
    Header: "Enterprise Services",
    CompanyName: "Accenture",
    Stages: "Opportunity", colors: "#58D8A2"
  },
  {
    Header: "Research SME",
    CompanyName: "Norut Gruppen Tromso",
    Stages: "Opportunity", colors: "#E59F54"
  },
  {
    Header: "Lead For Leadx By Devendra Phadnis",
    CompanyName: "Leadx",
    Stages: "Opportunity", colors: "#F36B72"
  },
  {
    Header: "Architecture And Planning",
    CompanyName: "Surbana Cunsultants Pvt.Ltd.",
    Stages: "Opportunity", colors: "#58D8A2"
  },
  {
    Header: "Consulting Requirement",
    CompanyName: "HP",
    Stages: "Opportunity", colors: "#58D8A2"
  },
  {
    Header: "Real Estate SME",
    CompanyName: "Elevate",
    Stages: "Opportunity", colors: "#E59F54"
  },
  {
    Header: "Testing-Lang",
    CompanyName: "Hello Fresh",
    Stages: "Opportunity", colors: "#58D8A2"
  },
  {
    Header: "Test Language",
    CompanyName: "SHELL AIRCRAFT INTERNATIONAL",
    Stages: "Opportunity", colors: "#58D8A2"
  },
  {
    Header: "Sme-Package-New",
    CompanyName: "Shell",
    Stages: "Opportunity", colors: "#58D8A2"
  },
  {
    Header: "Brandom Services",
    CompanyName: "Selskap As",
    Stages: "Opportunity", colors: "#58D8A2"
  },
  {
    Header: "Tjeneste/Produkt",
    CompanyName: "Selskap as",
    Stages: "Opportunity", colors: "#58D8A2"
  },
  {
    Header: "Review, Accept & Assign Me!",
    CompanyName: "Leasds Leads Leads",
    Stages: "Opportunity", colors: "#58D8A2"
  },
  {
    Header: "Billigere Storm",
    CompanyName: "HP",
    Stages: "Opportunity", colors: "#58D8A2"
  },
  {
    Header: "Insurance Opportunity",
    CompanyName: "Shelle",
    Stages: "Opportunity", colors: "#58D8A2"
  },
  {
    Header: "Insurance Opportunity",
    CompanyName: "Shelle",
    Stages: "Opportunity", colors: "#58D8A2"
  },
];

const flatlists = [
  { id: "1", title: "All Leads" },
  { id: "2", title: "New" },
  { id: "3", title: "Lead" },
  { id: "4", title: "Opportunity" },
  { id: "5", title: "Proposal" },
  { id: "6", title: "Negotiation" },
  { id: "7", title: "Closed - Won" },
  { id: "8", title: "Closed - Lost" },
  { id: "9", title: "Parked" },
];

interface Props {
  item: string;
  onPress?: () => void;
  backgroundColor: ViewStyle;
  textColor: ViewStyle;
}

const Item: React.FC<Props> = ({
  item,
  onPress,
  backgroundColor,
  textColor,
}) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
    <Text style={[styles.title, textColor]}>{item.title}</Text>
  </TouchableOpacity>
);

const Lead = () => {
  const [selectedId, setSelectedId] = useState(null);

  const renderItem = ({ item }) => {
    const backgroundColor = item.id === selectedId ? "#06949E" : "#D9D9D9";
    const color = item.id === selectedId ? "white" : "black";
    return (
      <Item
        item={item}
        onPress={() => setSelectedId(item.id)}
        backgroundColor={{ backgroundColor }}
        textColor={{ color }}
      />
    );
  };
  return (
    <Layout>
      <Heading>LEADS</Heading>
      <ScrollView style={styles.container} stickyHeaderIndices={[0]}>
        <FlatList
          showsHorizontalScrollIndicator={false}
          style={{
            // backgroundColor: "white",
            maxWidth:
              Dimensions.get("window").width - (250 + horizontalScale(8)),
          }}
          horizontal
          data={flatlists}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          extraData={selectedId}
        />
        {DATA.map((item) => (
          <LeadItem item={item} />
        ))}
      </ScrollView>
    </Layout>
  );
};

export default Lead;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 1,
    paddingHorizontal: 10
  },
  itemImg: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  items: {
    backgroundColor: "#F5F5F5",
    marginVertical: 10,
    marginRight: 40,
    paddingHorizontal: 25,
    paddingVertical: 25,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    fontWeight: 400,
    shadowColor: "#03030320",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 5,
    borderRadius: 5,
  },
  names: {
    flexDirection: "row",
    backgroundColor: "#F5F5F5",
    color: "#000000",
    fontWeight: 200,
    justifyContent: "space-between",
  },
  item: {
    padding: 10,
    marginVertical: 12,
    marginRight: 10,
    paddingHorizontal: 35,
    borderRadius: 20,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
  },
  owner: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
    color: "#000000",
    fontWeight: 200,
  },
  arrow: {
    alignItems: "flex-end",
    height: 25,
    width: 13,
    marginTop: 20,
  },
  handShake: {
    height: 18,
    width: 25,
    flexDirection: "row",
    margin: 4,
  },
  sync: {
    height: 15,
    width: 15,
    flexDirection: "row",
    margin: 4,
  },
  delete: {
    height: 26,
    width: 19,
    margin: 3,
    marginTop: 20,
  },
  create: {
    height: 25,
    width: 25,
    margin: 5,
    marginTop: 20,
  },
  btn: {
    backgroundColor: "#33D325",
    height: 26,
    width: 59,
    textAlign: "center",
    borderRadius: 4,
    margin: 10,
    marginTop: 20,
  },
});
