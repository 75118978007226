import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
// import LinearGradient from 'react-native-linear-gradient'
import { i18n } from '~Utils/i18n'
import { LinearGradient } from 'expo-linear-gradient'

interface Props {
  title: string
  leads: string
  won: string
  inProgress: string
  success: string
  startDate: string
  endDate: string
  winner: string
  leader: string
  onPress?: () => void
}

const CampaignCard: React.FC<Props> = ({
  title,
  leads,
  won,
  inProgress,
  success,
  startDate,
  endDate,
  winner,
  onPress,
  leader,
}) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <Image
          style={{ width: '100%', height: 262 }}
          source={require('../../../Assets/temp/img1.png')}
          resizeMode={'stretch'}
        />

        <LinearGradient
          style={styles.textArea}
          colors={['#0000FF', '#0100FE', '#983795', '#F27121']}
          locations={[0.0168, 0.6145, 0.8556, 0.9812]}
          start={{ x: 0.1, y: 0.2 }}
          end={{ x: 1, y: 1 }}
        >
          <Text style={styles.campaignTitle}>{title}</Text>
          <View style={styles.circleArea}>
            <View style={styles.circle}>
              <Text>{leads}</Text>
              <Text>{i18n.t('campaignHome.leads')}</Text>
            </View>
            <View style={styles.circle}>
              <Text>{won}</Text>
              <Text>{i18n.t('campaignHome.won')}</Text>
            </View>
            <View style={styles.circle}>
              <Text>{inProgress}</Text>
              <Text>{i18n.t('campaignHome.inProgress')}</Text>
            </View>
            <View style={styles.circle}>
              <Text>{success}</Text>
              <Text>{i18n.t('campaignHome.success')}</Text>
            </View>
            <View style={styles.leaderCircle}>
              <View style={[styles.circle]}>
                <Image
                  source={require('../../../Assets/temp/Ellipse.png')}
                  style={{ height: 70, width: 70 }}
                  // resizeMode={'stretch'}
                />
              </View>
              <Text style={styles.leaderText}>{leader}</Text>
              {/*  */}
            </View>
          </View>

          <Text style={styles.date}>
            {i18n.t('campaignHome.fromDate')}
            {startDate} {i18n.t('campaignHome.tillDate')} {endDate}
          </Text>
        </LinearGradient>
      </View>
    </TouchableOpacity>
  )
}

export default CampaignCard

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 15,
    marginBottom: 30,
  },
  textArea: {
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  campaignTitle: {
    fontSize: 25,
    fontFamily: 'OpenSans_400Regular',
    fontWeight: '400',
    color: '#fff',
    marginBottom: 18,
    marginLeft: 12,
    marginTop: 10,
  },
  circleArea: {
    flexDirection: 'row',
  },
  circle: {
    height: 70,
    width: 70,
    backgroundColor: '#D9D9D9',
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15,
  },
  leaderCircle: {
    //   marginLeft: 'auto',
    marginLeft: 35,
    marginRight: 12,
  },
  leaderText: {
    alignSelf: 'center',
    color: '#FFFEFE',
    fontFamily: 'OpenSans_400Regular',
    fontWeight: '400',
    marginLeft: 15,
  },
  date: {
    color: '#FFFEFE',
    fontFamily: 'OpenSans_400Regular',
    fontWeight: '400',
    fontSize: 12,
    marginHorizontal: 12,
    marginBottom: 18,
  },
})
