import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
// @ts-ignore
import Modal from "react-native-modal";
import Icon from "~components/molecules/Icon";
import { i18n } from "~Utils/i18n";
import styles from "./style";

const createList = [
  {
    title: "News",
    screen: "CreateNews",
    name: "news",
  },
  {
    title: i18n.t("home.activities"),
    screen: "CreateActivity",
    name: "activity",
  },
  {
    title: "Lead",
    screen: "CreateLead",
    name: "lead",
  },

  {
    title: "Campaign",
    screen: "CreateCampaign",
    name: "campaign",
  },
  {
    title: "Contact",
    screen: "CreateContact",
    name: "contact",
  },
  {
    title: "Company",
    screen: "CreateCompany",
    name: "company",
  },
];

const ScrollableModal = ({ isVisible, setIsVisible }) => {
  const navigation = useNavigation();
  const [scrollOffset, setScrollOffset] = useState(0);
  const scrollViewRef = React.useRef();

  const handleOnScroll = (event) => {
    setScrollOffset(event.nativeEvent.contentOffset.y);
  };

  const handleScrollTo = (p) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo(p);
    }
  };
  const handleNavigation = (screen) => {
    setIsVisible(false);
    setTimeout(() => navigation.navigate(screen), 100);
  };

  return (
    <View>
      <Modal
        testID={"modal"}
        isVisible={isVisible}
        onSwipeComplete={() => {
          setIsVisible(false);
        }}
        swipeDirection={["down"]}
        scrollTo={handleScrollTo}
        scrollOffset={scrollOffset}
        scrollOffsetMax={400 - 300} // content height - ScrollView height
        propagateSwipe={true}
        style={styles.modal}
        animationInTiming={600}
        animationOutTiming={600}
        useNativeDriver={true}
        useNativeDriverForBackdrop={true}
      >
        <View style={styles.scrollableModal}>
          <TouchableOpacity onPress={() => setIsVisible(false)}>
            <Icon
              style={styles.iconStyle}
              name={"closecircle"}
              size={40}
              family={"antdesign"}
              color={"white"}
            />
          </TouchableOpacity>
          <ScrollView
            ref={scrollViewRef}
            onScroll={handleOnScroll}
            scrollEventThrottle={16}
          >
            <View style={styles.scrollableModalContent1}>
              <View style={styles.viewStyle}>
                {createList.map((item, index) => (
                  <>
                    {index < 3 && (
                      <TouchableOpacity
                        onPress={() => handleNavigation(item.screen)}
                      >
                        <View>
                          <Icon
                            name={item.name}
                            family={"custom"}
                            size={40}
                            style={styles.logoStyle}
                          />
                          <Text style={styles.textStyle}>{item.title}</Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </>
                ))}
              </View>
              <View style={styles.viewStyle1}>
                {createList.map((item, index) => (
                  <>
                    {index >= 3 && (
                      <TouchableOpacity
                        onPress={() => handleNavigation(item.screen)}
                      >
                        <View>
                          <Icon
                            name={item.name}
                            family={"custom"}
                            size={40}
                            style={styles.logoStyle}
                          />
                          <Text style={styles.textStyle}>{item.title}</Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </>
                ))}
              </View>
            </View>
          </ScrollView>
        </View>
      </Modal>
    </View>
  );
};

export default ScrollableModal;
