export const baseColors = {
  accent: '#5EB487',
  white: '#FFF',
  base1: 'rgba(246, 243, 238, 0.4)',
  base2: 'rgba(246, 243, 238, 1)',
  borderColor: 'rgba(130, 130, 130, 1)'
}

const colors = (isDarkMode = false) => {
  const finalColors = isDarkMode ? baseColors : baseColors
  return {
    ...finalColors,
    //  add custom colors here with different name
    success: finalColors.secondary
  }
}

const theme = {
  colors: colors()
}

const defaultTheme = (isDarkMode = false) => {
  return {
    colors: colors(isDarkMode)
  }
}

export { theme, defaultTheme }
