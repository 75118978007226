import { IRowsLightWithCount, IRowsWithCount } from "~Types/ApiResponse";
import Api from "./Api";
import apiUrl from "../constants/apiURLs";
import {ICountry, ILicense, ILeadSource, ICurrency, IFilter, ICategory, IActivityType, IActivityFilter} from "./Types";

export interface IMaster {
  getAllCountries(): Promise<IRowsWithCount<ICountry>>;
  getAllLicenses(): Promise<IRowsWithCount<ILicense>>;
  getAllCurrencies(payload:IFilter): Promise<IRowsLightWithCount<ICurrency>>;
  getAllCategories(payload:IFilter): Promise<IRowsLightWithCount<ICategory>>;
  addLeadCategory(payload:ICategory): Promise<IRowsLightWithCount<ICategory>>; 
  getLicenseTypes(): Promise<IRowsLightWithCount<ILicense>>;
  getActivityTypes(payload:IActivityFilter): Promise<IRowsLightWithCount<IActivityType>>;
}

export default class Master implements IMaster{
   async getAllCountries(): Promise<IRowsWithCount<ICountry>> {
    const result = await Api.post("/master/territory/country", {});
    return result.data;
  }

   async getAllLicenses(): Promise<IRowsWithCount<ILicense>> {
    const result = await Api.post("/master/getLicenseType", {});
    return result.data;
  }

   async getAllLeadSources(payload:IFilter): Promise<IRowsLightWithCount<ILeadSource>> {
    const result = await Api.post(apiUrl.master.getLeadSource, payload);
    return result.data;
  }

   async getAllCurrencies(payload:IFilter): Promise<IRowsLightWithCount<ICurrency>> {
    const result = await Api.post(apiUrl.master.getCurrencies, payload);
    return result.data;
  }

   async getAllCategories(payload:IFilter): Promise<IRowsLightWithCount<ICategory>> {
    const result = await Api.post(apiUrl.master.getCategories, payload);
    return result.data;
  }

   async addLeadCategory(payload:ICategory): Promise<IRowsLightWithCount<ICategory>> {
    const result = await Api.post(apiUrl.master.addLeadCategory, payload);
    return result.data;
  }

  async getLicenseTypes(): Promise<IRowsLightWithCount<ILicense>> {
    const result = await Api.post(apiUrl.master.getLicenseTypes, {});
    return result.data;
  }

  async getActivityTypes(payload:IActivityFilter): Promise<IRowsLightWithCount<IActivityType>> {
    const result = await Api.post(apiUrl.master.getActivityTypes, payload);
    return result.data;
}

}
