import { ILoginResponse } from "~Services";
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  USER_KEY,
} from "~Services/Api/constants";

import SecureStorage from "./SecureStorage";

function handleLogin(response: ILoginResponse) {
  console.log(response, "Response");
  SecureStorage.setItem(
    ACCESS_TOKEN_KEY,
    `bearer ${response.token_info.access_token}`
  );
  SecureStorage.setItem(REFRESH_TOKEN_KEY, response.token_info.refresh_token);
  SecureStorage.setItemJSON(USER_KEY, response.user);
}

export { handleLogin };
