import Master from "./Master";
import Auth, { ICred, IUser, ILoginResponse } from "./Auth";
import Account, { IOrg } from "./Account";
import Lead from "./Lead";
import Company from "./Company";
import SalesNews from "./Salesnews";
import Feed from "./Feed";
import User from "./User";
import BonusPoint from "./BonusPoint";

export default {
  master: new Master(),
  auth: new Auth(),
  account: new Account(),
  lead: new Lead(),
  company: new Company(),
  salesNews: new SalesNews(),
  feed: new Feed(),
  user: new User(),
  bonusPoint: new BonusPoint(),
};

export { ICred, IUser, ILoginResponse, IOrg };
