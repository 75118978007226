import { Platform } from "react-native";
import * as SecureStore from "expo-secure-store";
import CryptoJSWeb from "crypto-js";
import CryptoJSNative from "react-native-crypto-js";

import Constants from "expo-constants";
import AsyncStorage from "@react-native-async-storage/async-storage";

const CRYPTO_KEY = Constants.manifest?.extra?.CRYPTO_KEY || "";

const CryptoJS = Platform.OS === "web" ? CryptoJSWeb : CryptoJSNative;

export function encrypt(data: string) {
  return CryptoJS.AES.encrypt(data, CRYPTO_KEY).toString();
}

export function encryptJSON(data: object) {
  return encrypt(JSON.stringify(data));
}

export function decryptJSON(data: string): object | null {
  const decryptedData = decrypt(data);
  if (decryptedData) return JSON.parse(decryptedData);
  return null;
}

export function decrypt(data: string): string | null {
  let bytes = CryptoJS.AES.decrypt(data, CRYPTO_KEY);
  try {
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return null;
  }
}

async function getItem(key: string): Promise<string | null> {
  let data;
  if (Platform.OS !== "web") {
    console.log("Secure Key", key);
    data = await SecureStore.getItemAsync(key);
  } else {
    data = await AsyncStorage.getItem(key);
  }

  if (data) {
    data = decrypt(data);
  }
  return data;
}

async function setItem(key: string, value: string) {
  const data = encrypt(value);
  if (Platform.OS !== "web") {
    return SecureStore.setItemAsync(key, data);
  }
  return AsyncStorage.setItem(key, data);
}

export default {
  getItem,
  setItem,
  getItemJSON: async (key: string): Promise<object | null> => {
    let data = await getItem(key);
    if (!data) return null;
    try {
      return JSON.parse(data);
    } catch (error) {
      return null;
    }
  },
  setItemJSON: async (key: string, value: object) => {
    if (!key) return null;
    return setItem(key, JSON.stringify(value));
  },
};
