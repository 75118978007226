import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from "react-native";
import React from "react";
import { useTheme } from "~Contexts/Theme";

interface IButtonProps extends TouchableOpacityProps {
  title: string;
  textStyle?: ViewStyle;
  loading?: boolean;
}

const Button: React.FunctionComponent<IButtonProps> = ({
  title,
  style,
  textStyle,
  ...rest
}) => {
  const theme = useTheme().theme;
  return (
    <TouchableOpacity
      style={[
        styles(theme).buttonStyle,
        style,
        rest.disabled && styles(theme).disabledButton,
      ]}
      {...rest}
      onPress={!rest.loading ? rest.onPress : () => {}}
    >
      {rest.loading ? (
        <ActivityIndicator />
      ) : (
        <Text style={[styles(theme).textStyle, textStyle]}>{title}</Text>
      )}
    </TouchableOpacity>
  );
};

export default Button;

const styles = (theme) =>
  StyleSheet.create({
    textStyle: {
      fontSize: 16,
      fontWeight: "400",
      color: theme.buttonColor.buttonTitle,
    },
    buttonStyle: {
      padding: 15,
      alignItems: "center",
      borderRadius: 12,
      backgroundColor: theme.buttonColor.primeColor,
    },
    disabledButton: {
      backgroundColor: "#3B578D70",
    },
  });
