import { StyleSheet, Text, View, Image } from "react-native";
import Input from "~components/atoms/TextInput";
import Common from "~components/molecules/commonpages/index.web";
import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { i18n } from "~Utils/i18n";
import { useResolution } from "~helpers/utilities";
// import styles from './styleWeb.js'
import { horizontalScale } from "~helpers/basicStyles";
import Button from "~components/atoms/Button";
import Link from "~components/atoms/Link";
import { Formik } from "formik";
import * as Yup from "yup";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .required("Please enter your email address"),
});

const ForgotPassword = () => {
  const navigation = useNavigation();
  const resolution = useResolution();
  const [aspectRatio, setAspectRatio] = useState(0);
  const handleOnLoad = (e) => {
    setAspectRatio(
      e.nativeEvent.path[0].naturalWidth / e.nativeEvent.path[0].naturalHeight
    );
  };
  // const handleProceed = () => {
  //   navigation.navigate('ResetPassword')
  // }
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={(values) => {
        console.log("values", values);
        navigation.navigate("ResetPassword");
      }}
    >
      {({
        values,
        touched,
        isValid,
        handleChange,
        setFieldTouched,
        handleSubmit,
        errors,
      }) => (
        <Common>
          <View style={styles.container}>
            <View
              style={resolution !== "small" ? [{ marginVertical: 30 }] : []}
            >
              <Image
                onLoad={handleOnLoad}
                source={{
                  uri: "https://s3.eu-north-1.amazonaws.com/live-media.leadx.no/Leadx-staging/users/1655113413413_users.png",
                }}
                style={[
                  styles.logoStyle,
                  {
                    aspectRatio: aspectRatio,
                  },
                ]}
                resizeMode={"contain"}
              />

              <View style={styles.subContainer}>
                <Text style={[styles.heading, styles.textCenter]}>
                  {i18n.t("forgotPass.forgot")}
                </Text>
                <Text style={[styles.title, styles.textCenter]}>
                  {i18n.t("forgotPass.instruction")}
                </Text>
              </View>

              <Text style={{ marginHorizontal: 20 }}>
                {i18n.t("forgotPass.email")}
              </Text>
              <Input
                style={styles.input}
                placeholder={i18n.t("forgotPass.placeholder")}
                value={values.email}
                onChangeText={handleChange("email")}
                onBlur={() => setFieldTouched("email")}
              />
              {touched.email && errors.email && (
                <Text style={styles.error}>{errors.email}</Text>
              )}
              <Button
                style={[styles.button]}
                title={i18n.t("forgotPass.proceed")}
                onPress={handleSubmit}
                disabled={!isValid || values.email === ""}
              />
              <Link
                style={[styles.linkStyle]}
                title={i18n.t("forgotPass.resend")}
              />
            </View>
          </View>
        </Common>
      )}
    </Formik>
  );
};

export default ForgotPassword;

const styles = StyleSheet.create({
  logoStyle: {
    width: horizontalScale(70),
    alignSelf: "center",
  },
  container: {
    backgroundColor: "#fff",
    paddingHorizontal: 20,
    marginHorizontal: 15,
    justifyContent: "center",
    alignSelf: "center",
    borderRadius: 25,
    marginVertical: 10,
    elevation: 8,
    width: "70%",
    height: "85%",
  },
  subContainer: {
    marginTop: 18,
    paddingHorizontal: 60,
  },
  heading: {
    fontSize: 18,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    color: "rgba(0,0,0,1)",
    marginBottom: 2,
  },
  title: {
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    color: "rgba(0,0,0,1)",
    marginBottom: 35,
    marginTop: 25,
  },
  textCenter: {
    textAlign: "center",
  },
  input: {
    marginHorizontal: 20,
    paddingHorizontal: 20,
    marginTop: 10,
  },
  error: {
    marginHorizontal: 20,
    color: "red",
    marginBottom: 10,
  },
  button: {
    marginHorizontal: 20,
    marginTop: 10,
  },
  linkStyle: {
    textDecorationLine: "underline",
    fontSize: 12,
    textAlign: "right",
    marginTop: "9px",
    marginRight: "1rem",
  },
});
