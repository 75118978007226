import { Platform, StatusBar, StyleSheet, Text, View } from "react-native";
import React from "react";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { theme } from "~theme";
import { horizontalScale, verticalScale } from "~helpers/basicStyles";
import Header from "~components/molecules/Header";

interface Props {
  style?: any;
  bounces?: boolean;
  header?: boolean;
  children?: any;
}

const BaseScreen: React.FC<Props> = ({
  children,
  style,
  bounces,
  header = false,
}) => {
  return (
    <>
      {header && <Header />}
      <KeyboardAwareScrollView
        enableOnAndroid={true}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.KeyboardAvoidingViewContainerStyle}
        style={[styles.KeyboardAvoidingView, style]}
        bounces={bounces}
        extraScrollHeight={100}
      >
        <StatusBar
          animated={true}
          // backgroundColor="#61dafb"
          backgroundColor="black"
          showHideTransition={"slide"}
          hidden={false}
        />
        {children}
      </KeyboardAwareScrollView>
    </>
  );
};

export default BaseScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.accent,
  },
  KeyboardAvoidingView: {
    flex: 1,
    paddingTop: Platform.OS === "android" ? 0 : verticalScale(40),
    paddingHorizontal: horizontalScale(20),
  },
  KeyboardAvoidingViewContainerStyle: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
});
