import axios from "axios";
import Constants from "expo-constants";
import { DeviceEventEmitter } from "react-native";
import { IUser } from "~Services/Auth";
import SecureStorage, { decryptJSON, encryptJSON } from "~Utils/SecureStorage";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, USER_KEY } from "./constants";

const BASE_URL = Constants.manifest?.extra?.apiUrl;
const CRYPTO_KEY = Constants.manifest?.extra?.CRYPTO_KEY || "";
const ENVIRONMENT = Constants.manifest?.extra?.environment;

axios.defaults.withCredentials = true;

async function getUser(): Promise<IUser | null> {
  const data = await SecureStorage.getItemJSON(USER_KEY);
  if (!data) return null;
  return <IUser>data;
}

const hashCode = (s = "") => {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

const axiosInstance = axios.create({
  withCredentials: true,
  timeout: 150000,
  baseURL: BASE_URL,
});

const isDevEnv = ENVIRONMENT !== "production";

let isAlreadyFetchingAccessToken = false;
let subscribers: Function[] = [];

function onAccessTokenFetched(access_token: string | null) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback: Function) {
  subscribers.push(callback);
}

export const getNewAccessToken = async () => {
  const refreshToken = await SecureStorage.getItem(REFRESH_TOKEN_KEY);
  const body = {
    refresh_token: refreshToken,
  };
  const shouldEncrypt = !isDevEnv && CRYPTO_KEY !== undefined;
  const data = shouldEncrypt
    ? JSON.stringify({
        sar: encryptJSON(body),
      })
    : body;

  const headers: any = {
    authorization: await SecureStorage.getItem(ACCESS_TOKEN_KEY),
  };

  headers["Content-Type"] = "application/json;charset=UTF-8";
  headers["phk"] = hashCode(JSON.stringify(body));

  return axios
    .post(`${BASE_URL}/users/access_token`, data, {
      headers,
    })
    .then((res) => {
      let response;
      if (shouldEncrypt) {
        response = decryptJSON(res.data.data);
      } else {
        response = res.data.data;
      }
      const access_token = `bearer ${response.access_token}`;
      SecureStorage.setItem(ACCESS_TOKEN_KEY, access_token);
      return access_token;
    });
};

const whiteListedURLs = [
  `/salesNews/add`,
  `/salesNews/update`,
  `/users/updateAvatar`,
  `/login/checkTokenExist`,
  `/lead/addLeadDocument`,
  `/salesNews/addDocument`,
  "/users/bulkUpload",
  "/account/updateCompanyAvtaar",
];

const unEncryptedURLs = [
  `/lead/addLeadDocument`,
  "/users/bulkUpload",
  "/salesNews/addDocument",
];

axiosInstance.interceptors.request.use(
  async (config: any) => {
    // perform a task before the request is sent
    const token = await SecureStorage.getItem(ACCESS_TOKEN_KEY);
    config.headers["Authorization"] = await SecureStorage.getItem(
      ACCESS_TOKEN_KEY
    );
    let currentUser = await getUser();
    console.log(currentUser, "Current User");

    if (currentUser) {
      config.headers["account"] = currentUser.account_id;
    }

    console.log(config.data, "ok");
    config.headers["phk"] = hashCode(JSON.stringify(config.data));
    console.log(isDevEnv, "Is Dev environment");

    // extending the timeout for sales news (add & update) and profile updation for image
    if (whiteListedURLs.indexOf(config.url) !== -1 || isDevEnv) {
      config.timeout = 30000;
      if ("/users/downloadSampeFile" === config.url) {
        config.headers["responseType"] = "blob";
      }
      if (unEncryptedURLs.indexOf(config.url) !== -1) {
        config.headers["Content-Type"] = "multipart/form-data";
      }
      return config;
    } else {
      let data = config.data;
      if (CRYPTO_KEY !== undefined) {
        config.data = JSON.stringify({
          sar: encryptJSON(data),
        });
        config.headers["Content-Type"] = "application/json;charset=UTF-8";
      }
    }
    return config;
  },
  (error) => {
    // handle the error
    console.log("error error error", error);
    return Promise.reject(error);
  }
);

function prepareResponse(config: any) {
  config.data.statusCode = config.status;
  config.data.status = config.status === 200;

  if (config.data.statusCode === 200) {
    let data = config.data.data;
    if (!isDevEnv && CRYPTO_KEY !== undefined) {
      if (!data) {
        config.data.data = {};
      } else {
        // config.data.data = decryptJSON(data);
        config.data.data = data;
      }
    }
  }

  return config;
}

function redirectUserToLogin() {
  DeviceEventEmitter.emit("logout");
}

axiosInstance.interceptors.response.use(
  (config) => {
    // perform a task before the request is sent
    config = prepareResponse(config);
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest.url.includes("login")
    ) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        getNewAccessToken()
          .then((access_token) => {
            onAccessTokenFetched(access_token);
          })
          .catch((error) => {
            onAccessTokenFetched(null);
          })
          .finally(() => {
            isAlreadyFetchingAccessToken = false;
          });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber(async (access_token: string) => {
          if (!access_token) {
            redirectUserToLogin();
            const response = {
              data: {
                statusCode: 401,
                errorMessage: "Unauthorized",
                status: false,
              },
            };
            resolve(response);
          } else {
            originalRequest.headers.Authorization = access_token;
            const result = await axios(originalRequest);
            resolve(prepareResponse(result));
          }
        });
      });
      return retryOriginalRequest;
    } else if (error.response.status > 401 && error.response.status < 500) {
      redirectUserToLogin();
      const response = {
        data: {
          statusCode: error.response && error.response.status,
          errorMessage: error.response && error.response.data.errorMessage,
          status: false,
        },
      };
      return Promise.resolve(response);
    } else {
      console.log(error.response);
      const response = {
        data: {
          statusCode: error.response && error.response.status,
          errorMessage: error.response && error.response.data.errorMessage,
          status: false,
        },
      };
      return Promise.resolve(response);
    }
  }
);
export default axiosInstance;
