import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAdGGPTLFuUZ4Brazmlo69ufcVRG7olDec",
  authDomain: "leadx-a8420.firebaseapp.com",
  databaseURL: "https://leadx-a8420.firebaseio.com",
  projectId: "leadx-a8420",
  storageBucket: "leadx-a8420.appspot.com",
  messagingSenderId: "366631728782",
  appId: "1:366631728782:web:73298f92cbac9b5e83bbdf",
  measurementId: "G-5ENX3NSRRY",
};

initializeApp(firebaseConfig);

function getFirebaseDB() {
  return getDatabase();
}

export { getFirebaseDB };
