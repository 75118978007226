//#region - Master

export interface ICountry {
  created_at: string;
  dialing_code: string;
  id: number;
  is_city: string;
  iso_code: string;
  name: string;
  update_at?: string;
}

export interface ILicense {
  actual_name: string;
  created_at: string;
  created_by: null | number;
  display_name: string;
  id: number;
  is_active: boolean;
  update_at?: string;
}

export interface IFilter {
  arrayFilters: any[];
  selectFilters: any[];
  sort: Sort;
  paginate?: Paginate;
}

export interface Paginate {
  page: string;
  limit: string;
}

export interface Sort {
  field: string;
  sortOrder: string;
}
export interface ILeadSource {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date | null;
}

export interface ICurrency {
  id: number;
  short_name: string;
  long_name: string;
  created_at: Date;
  updated_at: Date | null;
}

export interface ICategory {
  id: number;
  name: string;
}

export interface IActivityType {
  count: number;
  rows: ActivityType[];
}

export interface ActivityType {
  id: number;
  name: string;
}

//#endregion

//#region - User
export interface IUser {
  id: number;
  account_id: number;
  role_id: number;
  license_type_id: number;
  role: {
    id: number;
    actual_name: string;
    display_name: string;
  };
  account: {
    id: number;
    name: string;
    dns: string;
    parent_account_id: number;
  };
  license_type: LicenseType;
  department: {
    id: number;
    name: string;
  };
  countryCode: {
    id: number;
    dialing_code: string;
  };
  user_avatar: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  name: string;
  name_without_initials: string;
  contact: string;
  country_code: number;
  enable_publishing_news: boolean;
  enable_publishing_news_child: boolean;
  has_revenue_field_access: boolean;
  has_create_access: boolean;
  initials: string;
  gender: string;
}

export interface IPermission {
  id: number;
  name: string;
}

//#endregion - User

//#region  - Account
export interface Account {
  id: number;
  name: string;
  logo: null | string;
  parent_account_id: number;
}

export interface ILabels {
  id: number;
  language: string;
  json: any;
  created_at: Date;
  updated_at: Date;
}

//#endregion - Account

//#region  - Lead

export interface ILead {
  category_id: number;
  lead_source_id: number;
  lead_title: string;
  company_id: number;
  expected_closing_date: Date;
  lead_value: string;
  currency_id: number;
  is_confidential: number;
  contact_id: number;
  contact_country_code: null;
  contact_person_phone: null;
  project_location: string;
  is_hand_over: number;
  assigned_to: string;
  organization_id: null;
  is_won: null;
  is_bell_ringed: number;
  document: Document[];
  linkedin_profile: null;
  additional_attributes: IAdditionalAttributes;
  notes: string;
  lead_current_status_id: number;
  id: number;
  created_at: Date;
  updated_at: Date;
  is_accepted: boolean;
  createdBy: User;
  assignedTo: User;
  contact_company: ContactCompany;
  contact_person: ContactPerson;
  lead_status: LeadStatus;
  currency: ICurrency;
  category: ICategory;
  lead_source: ILeadSource;
  account: Account;
  Documents: Document[];
  accountCreatedBy: User | null;
  account_id: number;
  LeadSynchronizationMappings: LeadSynchronizationMapping[];
  can_delete: boolean;
  can_update: boolean;
  last_sync: LastSync;
}

export interface LeadSynchronizationMapping {
  leadx_lead_id: number;
  leadxtocrm_last_sync_status: string;
  crmtoleadx_last_sync_status: string;
  leadxtocrm_last_sync_datetime: Date;
  crmtoleadx_last_sync_datetime: Date;
  integration_id: number;
}

export interface ILeadFilter {
  arrayFilters: ArrayFilter[];
  selectFilters: string[];
  sort: Sort;
}

export interface LastSync {
  crm_name?: string;
  status: string;
  last_sync?: Date;
}

interface LeadStatus {
  id: number;
  name: string;
}

export interface ISection {
  sections: Section[];
  is_contact_info_mandatory: boolean;
}

export interface Section {
  id: number;
  title: string;
  fields: SectionField[];
  order: number;
  type: string;
}

export interface SectionField {
  name: string;
  type: FieldType;
  label: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  placeholder?: string;
  disabled?: boolean;
  options?: FieldOption[];
}

export interface FieldOption {
  label: string;
  value: string;
}

export enum FieldType {
  Select = "Select",
  TextArea = "TextArea",
  TextBox = "TextBox",
}

export interface ILeadDocument {
  document_path: string;
  document_type: string;
  document_name: string;
}

export interface ILeadDocumentSource {
  document: any;
  document_name: string;
}

export interface Document {
  document_path: string;
  document_type: string;
  document_name: string;
}

export interface ContactCompany {
  id: number;
  company_name: string;
  countryCode: null;
}

export interface ContactPerson {
  initials: string;
  name: string;
  id: number;
  first_name: string;
  last_name: string;
  country_id: null;
  country_code: null;
  phone_number: string;
  email: string;
  countryCode: null;
}

export interface Currency {
  id: number;
  short_name: string;
}

export interface User {
  initials: string;
  first_name: string;
  last_name: string;
  name: string;
  name_without_initials: string;
  gender: string;
  id: number;
  user_avatar: null | string;
  license_type?: LicenseType;
  department: Account;
  role: Role;
  account_id?: number;
}

interface Role {
  id: number;
  display_name: string;
}

interface LicenseType {
  id: number;
  actual_name: string;
  display_name: string;
}

export interface IAdditionalAttributes {
  uniqueId: string;
  user_uniq_id: string;
  vat_code: string;
  product1: string;
  product2: string;
  product3: string;
  product4: string;
  product5: string;
  product6: string;
  product7: string;
  product8: string;
  product9: string;
  more_info: string;
}

//#endregion

//#region - Company & Contact

export interface IContactFilter {
  arrayFilters: ArrayFilter[];
  selectFilters: any[];
  sort: Sort;
  paginate: Paginate;
  organizationArray: number[];
}

export interface ICompanyFilter {
  arrayFilters: ArrayFilter[];
  selectFilters: any[];
  sort: Sort;
  paginate: Paginate;
}

export interface ICompany {
  count: number;
  rows: Company[];
}

export interface Company {
  id: number;
  account_id: number;
  company_name: string;
  company_code: null | string;
  office_address_line_1: null | string;
  office_address_line_2: null | string;
  zipcode: null | string;
  country_id: number | null;
  state_id: number | null;
  city_id: number | null;
  website: null | string;
  contact_person_name: null | string;
  contact_country_code: null | string;
  contact_person_phone: null | string;
  contact_person_email: null | string;
  notes: null | string;
  is_deleted: boolean;
  created_by: number | null;
  created_at: Date;
  updated_at: Date | null;
  user: User | null;
  country: Country | null;
  state: State | null;
  city: City | null;
  countryCode: CountryCode | null;
  from_brreg: boolean;
}

export interface City {
  name: string;
}

export interface Country {
  name: string;
  iso_code: string;
  is_city: string;
}

export interface CountryCode {
  id: number;
  dialing_code: string;
}

export interface State {
  name: string;
  state_code: string;
}

export interface User {
  initials: string;
  first_name: string;
  last_name: string;
  name: string;
  name_without_initials: string;
  gender: string;
  user_avatar: null | string;
}

export interface IContact {
  count: number;
  rows: Contact[];
}

export interface Contact {
  initials: string;
  name: string;
  id: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  country: null;
  state: null;
  city: null;
  countryCode: null;
  contact_company: ContactCompany;
  UserRef: null;
}

export interface ContactCompany {
  id: number;
  company_name: string;
}

//#endregion

//#region - Sales news
export interface ISalesNewsFilter {
  arrayFilters: ArrayFilter[];
  selectFilters: string[];
  organizationArray: number[];
  sort: Sort;
  paginate: Paginate;
}

export interface ISalesNews {
  count: number;
  rows: SalesNews[];
  all_publish_count: number;
  self_publish_count: number;
  self_draft_count: number;
}

interface SalesNews {
  id: number;
  title: string;
  cover_image: string;
  news_body: string;
  created_by: number;
  account_id: number;
  is_active: boolean;
  is_deleted: boolean;
  is_has_expiry: boolean;
  expiry_date: null;
  created_at: Date;
  updated_at: Date;
  user: SalesNewsUser;
  sales_news_organizations: SalesNewsOrg[];
  sales_news_accounts1: SalesNewsOrg[];
  account: Account;
  sales_news_license_types: SalesNewsLicenseType[];
}

interface SalesNewsOrg {
  id: number;
  sales_news_id: number;
  account_id: number;
}

interface SalesNewsLicenseType {
  id: number;
  sales_news_id: number;
  license_type_id: number;
}

interface SalesNewsUser {
  initials: string;
  first_name: string;
  last_name: string;
  name: string;
  name_without_initials: string;
  gender: string;
  user_avatar: null | string;
}

//#endregion - Sales news

//#region - Activity

export interface IActivityFilter {
  arrayFilters: ArrayFilter[];
  selectFilters: any[];
  sort: Sort;
  paginate: Paginate;
  type: string;
}
//#endregion - Activity

//#region - Feed

export interface IFeedFilter {
  arrayFilters: ArrayFilter[];
  selectFilters: string[];
  organizationArray: number[];
  sort: Sort;
  paginate: Paginate;
}

export interface IFeed {
  count: number;
  rows: Feed[];
}

export interface Feed {
  created_at: Date;
  from_another_account: boolean | null;
  user: FeedUser;
  account: Account;
  accountCreatedBy: Account | null;
  contact_company: ContactCompany;
  id: number;
  lead: Lead;
  read: boolean;
  action_type: ActionType;
  build_message: BuildMessage;
}

export enum ActionType {
  LeadToBeApproved = "LEAD_TO_BE_APPROVED",
  OtherOrgRingTheBell = "OTHER_ORG_RING_THE_BELL",
  RingTheBell = "RING_THE_BELL",
}

export interface BuildMessage {
  message: string[];
  data: string[];
}

export interface ContactCompany {
  id: number;
  company_name: string;
}

export interface Lead {
  id: number;
  lead_title: string;
  createdBy: FeedUser;
}

export interface FeedUser {
  initials: string;
  first_name: string;
  last_name: string;
  name: string;
  name_without_initials: string;
  gender: string;
  id: number;
  user_avatar: null | string;
  user_avatar_url?: null | string;
}

//#endregion - Feed

//#region - Filters
interface ArrayFilter {
  is_deleted: number;
  dateFrom?: Date;
  dateTo?: Date;
}

export interface Paginate {
  page: string;
  limit: string;
}

export interface Sort {
  field: string;
  sortOrder: string;
}
//#endregion - Filters
