import React, { createContext, useEffect } from "react";
import Services from "~Services";
import { useAuth } from "./Auth";

interface IPermissionContext {
  hasPermission: (requiredPermissions: string[]) => boolean;
}

const PermissionContext = createContext<IPermissionContext | null>(null);

export const PermissionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [permissions, setPermissions] = React.useState<any>({});
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      setPermissions({});
    } else {
      Services.user
        .getAllPermissions()
        .then((permissions) => {
          const hash: any = {};
          permissions.forEach((permission) => {
            hash[permission.name] = true;
          });
          setPermissions(hash);
        })
        .catch(() => {});
    }
  }, [isLoggedIn]);

  const obj = {
    hasPermission: (requiredPermissions: string[]) => {
      for (let i = 0; i < requiredPermissions.length; i++) {
        if (permissions[requiredPermissions[i]]) return true;
      }
      return false;
    },
  };

  return (
    <PermissionContext.Provider value={obj}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermission = () => {
  const ctx = React.useContext(PermissionContext);

  if (!ctx) {
    throw new Error("usePermission must be used within the PermissionProvider");
  }

  return ctx;
};
