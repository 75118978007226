import { StyleSheet, Text, View } from "react-native";
import React from "react";
import Layout from "~components/atoms/Layout/index.web";
import Accordion from "~components/atoms/Accordion";

const CreateNews = () => {
  return (
    <Layout>
      <Text>CreateNews</Text>
      <Accordion label="sgjhgdjgdgjd">
        <Text>gdfgdkfdjgfkg</Text>
      </Accordion>
    </Layout>
  );
};

export default CreateNews;

const styles = StyleSheet.create({});
