import { StyleSheet, Text, TouchableOpacity, View, ViewStyle, Image } from "react-native";
import React, { useState } from "react";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import style from "../../../../storybook/stories/CenterView/style";
import LinearGradient from "react-native-linear-gradient";
import Layout from "~components/atoms/Layout/index.web";
import { useCustomFonts } from "~helpers/fonts";
import AppLoading from "expo-app-loading";
import Button from "~components/atoms/Button";
import { Checkbox } from "react-native-paper";

const flatlistActivity =[
  {name: "All Activity" , id: "5"},
  {name: "My Activity", id: "6"}
];

const data =[
  {name: "Call Adam Smith", color: '#F36B72', desc: "Late", images : "Call"},
  {name: "Send Email To John",  color: '#E59F54', desc: "Due Today", images: "Mail"},
  {name: "Meeting With Will Smith", color: '#58D8A2', desc: "Tomorrow - 22nd Sept 2022", images: "Meeting"}
];
const heading = [
  {
    title: "Enterprise Services",
    status: "Project Statue : In Progress",
    stage: "Lead Stages : Negotition"
  }
];

const statistics = [
  {name: "Total Activities", count: "55"},
  {name: "Activities Completed", count: "7"},
  {name: "Activities In Progress", count: "5"},
];

const team =[
  {name: "John Smith", activity: "10", done: "3", panding: "7", index: 1},
  {name: "Alica Hopes", activity: "8", done: "2", panding: "6", index: 2},
  {name: "Emma Watson", activity: "6", done: "0", panding: "6", index: 3},
  {name: "Phillip Anderson", activity: "6", done: "0", panding: "6", index: 4},
];

const flatlists = [
  { id: "1", title: "Lead Information" },
  { id: "2", title: "Notes" },
  { id: "3", title: "Activities" },
  { id: "4", title: "Documents" },
];

const information = [
  {heading: "Lead Title", desc: "Title of The Lead"},
  {heading: "Company", desc: "ABC crop"},
  {heading: "Contact", desc: "Admin Smith"},
  {heading: "Contact - Phone", desc: "+47 1234568998"},
  {heading: "Contact-Email", desc: "Adamsmith@Abccorp.com"},
  {heading: "Lead Catagory", desc: "Catagory of The Lead"},
  {heading: "Expected Closing Date", desc: "31-Dec-2022"},
  {heading: "Project Location", desc: "Norway"},
  {heading: "Lead Value", desc: "25000000.00 NOK"},
];

const notes =[
  {name: "This is a great opportunity", desc: "01-sep-2022 | by : John Smith"},
  {name: "This is a great opportunity", desc: "01-sep-2022 | by : John Smith"},
  {name: "This is a great opportunity", desc: "01-sep-2022 | by : John Smith"},
  {name: "This is a great opportunity", desc: "01-sep-2022 | by : John Smith"},
  {name: "This is a great opportunity", desc: "01-sep-2022 | by : John Smith"},
];

const images = [
  {image : require('../../../Assets/Images/LeadProjectImg.png')},
  {image : require('../../../Assets/Images/LeadProjectImg.png')},
  {image : require('../../../Assets/Images/LeadProjectImg.png')},
];
interface Props {
  item: string;
  onPress?: () => void;
  backgroundColor: ViewStyle;
  textColor: ViewStyle;
}

const Item: React.FC<Props> = ({
  item,
  onPress,
  backgroundColor,
  textColor,
}) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
    <Text style={[styles.title, textColor]}>{item.title}</Text>
  </TouchableOpacity>
);

const ProjectDetail = () => {
  const [selectedId, setSelectedId] = useState();
  const [fontsLoaded, error] = useCustomFonts();
  const [accept, setAccept] = useState(false);

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  const renderItem = ({ item }) => {
    const backgroundColor = item.id === selectedId ? "#06949E" : "#D9D9D9";
    const color = item.id === selectedId ? "white" : "black";
    return (
      <Item
        item={item}
        onPress={() => setSelectedId(item.id)}
        backgroundColor={{ backgroundColor }}
        textColor={{ color }}
      />
    );
  };
  return (
    <Layout>
    <ScrollView>
    <View style={styles.container}>
    
      <View style={styles.firstCard}>
        {heading.map((item)=>(
          <View style={styles.card}>
            <Image
                style={styles.img}
                source={require('../../../Assets/Images/leadProjects.png')}
              />
              <View>
                <Text style={styles.name}>{item.title}</Text>
                <View style={styles.stages}>
                  <Text style={styles.status}>{item.status}</Text>
                  <Text style={styles.status}>{item.stage}</Text>
                </View>
              </View>
              <View style={styles.profile}>
              </View>
          </View>
        ))}
      </View>
      <View style={styles.twoCards}>
        <View style={styles.secondCard}>
          <Text style={styles.cardName}>Project Statistics</Text>
          {statistics.map((data) => (
            <View style={styles.nameCount}>
              <Text>{data.name}</Text>
              <View>
                <Text>{data.count}</Text>
               </View>
            </View>
          ))}
        </View>
        <View style={styles.thirdCard}>
          <Text style={styles.cardName}>Team</Text>
          <View style={styles.cardHeading}>
            <Text style={styles.cardName}>Activities</Text>
            <Text style={styles.cardName}>Done</Text>
            <Text style={styles.cardName}>Pending</Text>
          </View>
            {team.map((data)=>(
              <View style={styles.nameCount}>
                <View style={{flexDirection:'row', alignItems:'center'}}>
                <Image
                  style={styles.star}
                  source={require('../../../Assets/Images/star.png')}
                />
                <Image
                  style={styles.img}
                  source={require('../../../Assets/Images/LeadProjectImg.png')}
                />
                </View>
                <Text style={styles.allName}>{data.name}</Text>
                <Text>{data.activity}</Text>
                <Text>{data.done}</Text>
                <Text>{data.panding}</Text>
              </View>
            ))}
        </View>
      </View>
      <View style={styles.flatlist}>
      <TouchableOpacity>
          <FlatList
            horizontal
            data={flatlists}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            extraData={selectedId}
          />
        </TouchableOpacity>
      </View>
      <View>
          {selectedId === "1" && (
            <View style={styles.lastCard}>
              {information.map((item)=>(
                <View style={styles.leadInfo}>
                  <Text style={styles.names}>{item.heading}</Text>
                  <Text style={styles.desc}>{item.desc}</Text>
                </View>
              ))}
            </View>
          )}
          {selectedId === "2" && (
            <View style={styles.notes}>
            {notes.map((item)=>(
              <View style={styles.leadInfoImg}>
                <Image style={styles.img} source={require('../../../Assets/Images/LeadProjectImg.png')} />
                <View>
                <Text style={styles.names}>{item.name}</Text>
                <Text style={styles.desc}>{item.desc}</Text>
                </View>
              </View>
            ))}
          </View>
          )}
          {selectedId === "3" && (
            <View style={styles.notes}>
              <View style={styles.flatlist}>
              <FlatList
                horizontal
                data={flatlistActivity}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                extraData={selectedId}
              />
              </View>
            {data.map((item)=>(
              <View style={styles.cards}>
                <View style={[styles.colors,{backgroundColor:item.color}]}></View>
                <Image
                  source={require(`../../../Assets/Images/${item.images}.png`)}
                  style={styles.image} 
                  resizeMode={"contain"}
                />
                <View>
                <Text style={{fontWeight: 'bold',marginTop: 22}}>{item.name}</Text>
                <Text>{item.desc}</Text>
                </View>
                <View style={styles.checkbox}>
                  <Image style={styles.imgs} source={require('../../../Assets/Images/LeadProjectImg.png')} />
                  <Checkbox
                    style={styles.checkboxImg}
                    value={accept}
                    onValueChange={() => setAccept(!accept)}
                  />
                </View>
              </View>
            ))}
          </View>
          )}
          {selectedId === "4" && (
            <View style={styles.notes}>
              <Button title="Edit" style={styles.btn}/>
              {images.map((data)=> (
                <View style={styles.documentImg}>
                  <Image source={data.image} style={styles.img}/>
                <View style={{flexDirection: 'row'}}>
                  <View style={styles.circle}></View>
                  <View style={styles.circle}></View>
                  <View style={styles.circle}></View>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
    </View>
    </ScrollView>
    </Layout>
  );
};

export default ProjectDetail;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 10,
  },
  firstCard: {
    backgroundColor: '#439368',
    marginVertical: 10,
    marginRight: 30,
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    shadowColor: "#03030320",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 5,
    borderRadius: 5,
  },
  profile: {
    borderRadius: 30,
    backgroundColor: '#D9D9D9',
    height: 50,
    width: 50,
    marginLeft: '57%'
  },
  card: {
    flexDirection: 'row'
  },
  star: {
    height: 20,
    width:20
  },
  img: {
    height: 50,
    width: 60,
    marginRight: 8
  },
  name: {
    fontFamily: "OpenSans_400Regular",
    fontWeight: 'bold',
    color: 'white',
    marginLeft: 40,
    margin:5,
  },
  status: {
    color: 'white',
    marginLeft: 40
  },
  stages: {
    fontFamily: "OpenSans_400Regular",
    marginTop: 10,
    flexDirection: 'row'
  },
  secondCard: {
    backgroundColor: 'white',
    marginVertical: 10,
    width: '45%',
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    shadowColor: "#03030320",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 5,
    borderRadius: 5,
   
  },
  cardHeading: {
    fontFamily: "OpenSans_400Regular",
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginLeft: '60%'
  },
  cardName: {
    fontFamily: "OpenSans_400Regular",
    fontWeight: 'bold',
    fontSize: 17,
    marginVertical: 10
  },
  allName: {
    marginRight: 90
  },
  nameCount: {
    flexDirection: 'row',
    marginTop: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '5%'
  },
  thirdCard: {
    backgroundColor: 'white',
    marginVertical: 10,
    marginRight: 30,
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderColor: "#DCDADA",
    borderWidth: 0.5,
    color: "#000000",
    shadowColor: "#03030320",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 5,
    borderRadius: 5,
    width: '50%'
  },
  twoCards: {
   flexDirection: 'row',
   justifyContent: 'space-between'
  },
  flatlist: {
    backgroundColor: 'white',
    borderRadius: 5,
    marginRight: 30
  },
  item: {
    padding: 10,
    marginVertical: 12,
    marginHorizontal: 30,
    width: "90%",
    borderRadius: 20,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "OpenSans_400Regular",
  },
  lastCard: {
    backgroundColor: 'white',
    marginVertical: 10,
    marginRight: 30,
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    shadowColor: "#03030320",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 5,
    borderRadius: 5,
    height: '60%',
    flexWrap: 'wrap',
    marginBottom: 50
  },
  leadInfo: {
    margin:5,
    marginBottom: 10,
  },
  names: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 15,
    fontWeight: 'bold',
    margin: 3,
  },
  desc: {
    fontFamily: "OpenSans_400Regular",
  },
  notes: {
    backgroundColor: 'white',
    marginVertical: 10,
    marginRight: 30,
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    borderRadius: 5,
    height: '90%',
    flexWrap: 'wrap',
    marginBottom: 50
  },
  leadInfoImg: {
    margin:5,
    marginBottom: 10,
    flexDirection: 'row'
  },
  documentImg: {
    margin:5,
    marginBottom: 10,
    marginTop: 2
  },
  circle: {
    borderRadius: 30,
    backgroundColor: '#D9D9D9',
    height: 50,
    width: 50,
    marginLeft: '5%'
  },
  btn: {
    marginLeft: '95%',
    width: '5%'
  },
  colors: {
    backgroundColor: '#F36B72',
    height: '100%',
    width: '1%',
    marginRight: 20,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  cards: {
    backgroundColor: "white",
    marginVertical: 7,
    marginRight: 50,
    borderWidth: 0.5,
    borderColor: "#DCDADA",
    color: "#000000",
    fontWeight: 400,
    shadowColor: "#03030320",
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 5,
    borderRadius: 10,
    flexDirection: 'row',
    height: 80,
  },
  imgs: {
    height: 50,
    width: 60,
  },
  image: {
    height: 25,
    width: 25,
    marginTop: 30,
    marginRight: 10
  },
  checkbox: {
    flexDirection: 'row',
    marginLeft: '60%',
    marginTop: 20
  },
  checkboxImg: {
    marginRight: 18,
  }
});