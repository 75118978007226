import { StyleSheet, Animated,Text, View } from 'react-native'
import React, { useEffect, useRef } from 'react'

interface AlertProps {
    visible: boolean;
    text: string;
    type?: string;
}

const Alert: React.FunctionComponent<AlertProps> = ({visible,text,type}) => {
const myRef = useRef(new Animated.Value(500));

useEffect(() => {
    if(visible){
  Animated.timing(myRef.current,{
      toValue:0,
      duration:400
  }).start(
    ()=>
      {setTimeout(()=>
        Animated.timing(myRef.current,{
          toValue:500,
          duration:400
      }).start(),2000)}
  )}
}, [visible])

  return (
    <Animated.View style={[styles.viewStyle,{
        transform:[{translateX:myRef.current}],
    },
    type ==='success' && {borderColor:'green'}
    ]}>
      <Text style={[styles.textStyle,type ==='success' && {borderLeftColor:'green'}]}>{text}</Text>
    </Animated.View>
  )
}

export default Alert

const styles = StyleSheet.create({
    viewStyle:{backgroundColor:'white',
    paddingVertical:10,
    paddingRight:25,
    borderRadius:5,
    right:10,
    top:20,
    position:'absolute',
    maxWidth:500,
    flexDirection:'row',
    borderColor:'#F96666',
    borderWidth:1,
    shadowOffset: {width:2,height:2},
    shadowOpacity: 0.25,
    shadowRadius: 6
    },
    textStyle: {borderLeftWidth:5,borderLeftColor:'#F96666',paddingLeft:10}
})