import Api from "./Api";

interface IBonusPointResponse {
  pointsData: PointsData;
  userData: UserData;
}

export interface PointsData {
  user_id: number;
  points: string;
}

export interface UserData {
  initials: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  name: string;
  name_without_initials: string;
  gender: string;
  id: number;
  email: string;
  user_avatar: string;
  license_type_id: number;
}

interface IBonusPoint {
  getMyRewardPoints(): Promise<IBonusPointResponse>;
}

export default class BonusPoint implements IBonusPoint {
  async getMyRewardPoints(): Promise<IBonusPointResponse> {
    const response = await Api.post("/bonusPoint/getUserPointsTotal", {});
    return response.data;
  }
}
