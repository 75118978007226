import {
  Animated,
  Button,
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import ScrollableModal from "~components/atoms/Modals/Scrollable";
import Icon from "../Icon";
import Notifications from "~Screen/Notifications";
import HomeStack from "../HomeStack";
import { i18n } from "~Utils/i18n";
import Pulse from "react-native-pulse";
import useNotificationCountLiveSync from "~Screens/Entry/useNotificatinCountLiveSync";
import NotificationMobile from "../NotificationMobile";
import {
  SafeAreaProvider,
  useSafeAreaInsets,
} from "react-native-safe-area-context";

const MainTabs = () => {
  const notification = useNotificationCountLiveSync();
  const Tab = createBottomTabNavigator();
  const [isModalVisible, setModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const RotateValueHolder = useState(new Animated.Value(0))[0];
  const RotateData = RotateValueHolder.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "45deg"],
  });
  const StartImageRotateFunction = () => {
    Animated.timing(RotateValueHolder, {
      toValue: open ? 0 : 1,
      duration: 200,
      useNativeDriver: true,
    }).start();
  };
  const insets = useSafeAreaInsets();

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };
  return (
    <SafeAreaProvider>
      <View
        style={{
          ...styles.viewContainer,
          paddingTop: insets.top,
          // paddingBottom: insets.bottom,
        }}
      >
        <Tab.Navigator
          screenOptions={{
            headerShown: false,
            tabBarShowLabel: false,
            tabBarStyle: [
              {
                display: "flex",
              },
              null,
            ],
          }}
        >
          <Tab.Screen
            name="HomeScreens"
            component={HomeStack}
            options={{
              tabBarIcon: ({ focused }) => (
                <View style={styles.iconView}>
                  <Icon
                    name={"home"}
                    family="simple-line-icon"
                    color={focused ? "#ffa500" : "#AAB7B8"}
                  />
                </View>
              ),
            }}
          />

          <Tab.Screen
            name="Notifications"
            component={Notifications}
            options={{
              tabBarIcon: ({ focused }) => (
                <NotificationMobile
                  value={notification.value}
                  focused={focused}
                />
              ),
            }}
          />
        </Tab.Navigator>
        <TouchableOpacity
          onPress={toggleModal}
          activeOpacity={0.5}
          style={styles.createBtnStyle}
        >
          <>
            <Icon family="antdesign" color={"blue"} name={"plus"} />
          </>
        </TouchableOpacity>
        <View>
          <ScrollableModal
            isVisible={isModalVisible}
            setIsVisible={setModalVisible}
          />
        </View>
      </View>
    </SafeAreaProvider>
  );
};

export default MainTabs;

const styles = StyleSheet.create({
  viewContainer: {
    flex: 1,
    // justifyContent: "center",
  },
  iconView: {
    paddingHorizontal: 60,
  },
  createBtnStyle: {
    position: "absolute",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    width: 50,
    borderRadius: 25,
    bottom: 30,
    zIndex: 2,
    backgroundColor: "#FFFFFF",
    shadowRadius: 6,
    shadowOpacity: 0.25,
    shadowColor: "grey",
    textShadowOffset: { width: 2, height: 2 },
    elevation: 3,
  },
  textStyle: {
    color: "#AAB7B8",
    fontSize: 12,
  },
});
