import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  FlatList,
  ScrollView,
} from "react-native";
import React, { useState } from "react";
import Layout from "~components/atoms/Layout/index.web";
import { i18n } from "~Utils/i18n";
import { useCustomFonts } from "~helpers/fonts";
import AppLoading from "expo-app-loading";
import Input from "~components/atoms/TextInput";
import Button from "~components/atoms/Button";
import Switch from "~components/atoms/Switch";
import Documents from "~components/atoms/Documents";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Accordion from "~components/atoms/Accordion";
import CampaignAccordion from "~components/atoms/CampaignAccordion";

const Organizations = [
  {
    id: "1",
    title: "Org 1",
  },
  {
    id: "2",
    title: "Org 2",
  },
  {
    id: "3",
    title: "Org 3",
  },
];
const Roles = [
  {
    id: "1",
    title: "Manager",
  },
  {
    id: "2",
    title: "Professional",
  },
  {
    id: "3",
    title: "Basic",
  },
  {
    id: "4",
    title: "Lite",
  },
];

const formSchema = Yup.object().shape({});

const Item = ({ item, onPress, backgroundColor, textColor }) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
    <Text style={[styles.title, textColor]}>{item.title}</Text>
  </TouchableOpacity>
);

const CreateCampaign = () => {
  const [organizations, setOrganizations] = useState("1");
  const [roles, setRoles] = useState("1");

  const renderCampaignItem = ({ item }) => {
    const backgroundColor = item.id === organizations ? "#F36B72" : "#D9D9D9";
    const color = item.id === organizations ? "white" : "#50555C";
    return (
      <Item
        item={item}
        onPress={() => setOrganizations(item.id)}
        backgroundColor={{ backgroundColor }}
        textColor={{ color }}
      />
    );
  };

  const renderRolesItem = ({ item }) => {
    const backgroundColor = item.id === roles ? "#F36B72" : "#D9D9D9";
    const color = item.id === roles ? "white" : "#50555C";
    return (
      <Item
        item={item}
        onPress={() => setRoles(item.id)}
        backgroundColor={{ backgroundColor }}
        textColor={{ color }}
      />
    );
  };

  // Fonts Loading
  const [fontsLoaded, error] = useCustomFonts();
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={formSchema}
      onSubmit={(values) => console.log("values", values)}
    >
      {({
        handleChange,
        setFieldValue,
        isValid,
        handleBlur,
        setFieldTouched,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
        <Layout>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={styles.container}>
              {/* Heading */}
              <Text style={styles.heading}>
                {i18n.t("createCampaign.heading")}
              </Text>

              {/* Campaign name input */}
              <Text style={styles.title}>
                {i18n.t("createCampaign.campaignName")}
              </Text>
              <Input />

              {/* Description Input */}
              <Text style={styles.title}>
                {i18n.t("createCampaign.aboutCampaign")}
              </Text>
              <Input multiline={true} numberOfLines={8} />

              {/* Upload Image */}
              {/* <Documents /> */}

              {/* Organizations */}
              <Text style={styles.title}>{i18n.t("createCampaign.org")}</Text>
              <View style={[styles.subContainer, { flexDirection: "row" }]}>
                <FlatList
                  numColumns={4}
                  data={Organizations}
                  renderItem={renderCampaignItem}
                  keyExtractor={(item) => item.id}
                  extraData={organizations}
                />
              </View>

              {/* Roles */}
              <Text style={styles.title}>{i18n.t("createCampaign.roles")}</Text>
              <View style={[styles.subContainer, { flexDirection: "row" }]}>
                <FlatList
                  numColumns={4}
                  data={Roles}
                  renderItem={renderRolesItem}
                  keyExtractor={(item) => item.id}
                  extraData={roles}
                />
              </View>

              {/* Goals */}
              <Text style={styles.title}>{i18n.t("createCampaign.goal")}</Text>
              <View style={styles.subContainer}>
                <CampaignAccordion />
              </View>

              {/* Campaign Schedule */}
              <Text style={styles.title}>
                {i18n.t("createCampaign.campaignSchedule")}
              </Text>
              <View style={styles.subContainer}>
                {/* Manual Start/End */}
                <View style={{ flexDirection: "row", marginBottom: 20 }}>
                  <Switch setValue={() => {}} />
                  <Text style={[styles.text, { marginLeft: 10 }]}>
                    {i18n.t("createCampaign.startAndEnd")}
                  </Text>
                </View>
                {/* Schedule */}
                <View style={{ flexDirection: "row", marginBottom: 20 }}>
                  <Switch setValue={() => {}} />
                  <Text style={[styles.text, { marginLeft: 10 }]}>
                    {i18n.t("createCampaign.schedule")}
                  </Text>
                </View>
                {/* Calendars */}
                <View style={{ flexDirection: "row", marginBottom: 20 }}>
                  <Text style={[styles.text, { marginLeft: 10 }]}>
                    {i18n.t("createCampaign.start")}
                  </Text>

                  <Text style={[styles.text, { marginLeft: 10 }]}>
                    {i18n.t("createCampaign.end")}
                  </Text>
                </View>
              </View>

              {/* Save Button */}
              <View style={{ alignItems: "center" }}>
                <Button
                  style={{ width: 100 }}
                  title={i18n.t("createCampaign.save")}
                />
              </View>
            </View>
          </ScrollView>
        </Layout>
      )}
    </Formik>
  );
};

export default CreateCampaign;

const styles = StyleSheet.create({
  container: {
    borderRadius: 9,
    borderWidth: 0.5,
    borderColor: "#333",
    marginHorizontal: 150,
    padding: 30,
    backgroundColor: "#fff",
    marginBottom: 30,
  },
  heading: {
    fontSize: 32,
    fontWeight: 600,
    fontFamily: "OpenSans_400Regular",
    alignSelf: "center",
  },
  title: {
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
    fontWeight: 600,
    marginBottom: 5,
  },
  subContainer: {
    padding: 12,
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: "#666",
    marginBottom: 32,
  },
  item: {
    paddingHorizontal: 30,
    paddingVertical: 6,
    borderRadius: 30,
    marginRight: 12,
  },
  text: {
    fontSize: 14,
    fontFamily: "OpenSans_400Regular",
    color: "#413E3E",
  },
});
