import React, { createContext } from "react";

const themes = {
  light: {
    foreground: "#000000",
    background: "#eeeeee",
    textColor: {
      card: "#FFFFFF",
      title: "#000000",
      placeholder: "#808080",
    },
    buttonColor: {
      primeColor: "#3B578D",
      buttonTitle: "#FFFFFF",
    },
    linkColor: {
      highlightColor: "#337E57",
      lightColor: "#0ab58b",
    },
    boderColor: {
      inputBorder: "#00000080",
      dropdownBorder: "#979797",
    },
    baseColor: {
      base1: "#5675B9",
    },
    card: {
      cardColor: "#5674B8",
    },
    accent: "#5EB487",
  },
  dark: {
    foreground: "#ffffff",
    background: "#222222",
    textColor: {
      card: "#FFFFFF",
      title: "#000000",
      placeholder: "#808080",
    },
    buttonColor: {
      primeColor: "#3B578D",
      buttonTitle: "#FFFFFF",
    },
    linkColor: {
      highlightColor: "#337E57",
      lightColor: "#0ab58b",
    },
    boderColor: {
      inputBorder: "#00000080",
      dropdownBorder: "#979797",
    },
    baseColor: {
      base1: "#5675B9",
    },
    card: {
      cardColor: "#5674B8",
    },
    accent: "#5EB487",
  },
};

type ITheme = typeof themes.dark;
interface IThemeContext {
  theme: ITheme;
  setTheme(themeType: ThemeType): void;
}

const ThemeContext = React.createContext<IThemeContext | null>(null);
type ThemeType = "light" | "dark";

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = React.useState<ThemeType>("light");

  const localTheme = themes[theme];

  return (
    <ThemeContext.Provider value={{ theme: localTheme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const ctx = React.useContext(ThemeContext);

  if (!ctx) {
    throw new Error("useTheme must be used within the ThemeProvider");
  }

  return ctx;
};
