import {
  Dimensions,
  Image,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import React, { useState } from "react";
import { useTheme } from "~Contexts/Theme";
import { useResolution } from "~helpers/utilities";

interface Props {
  children: any;
}

const Common: React.FC<Props> = ({ children }) => {
  const [aspectRatio, setAspectRatio] = useState(0);
  const { width, height } = useWindowDimensions();
  const res = useResolution();

  return (
    <View>
      <View style={[styles.viewContainer, { height: height }]}>
        {res !== "xsmall" && (
          <View style={styles.viewStyle}>
            <Image
              source={require("../../../Assets/Images/background.png")}
              style={styles.imageStyle}
            />
          </View>
        )}
        <View
          style={[
            styles.rightContainer,
            { backgroundColor: useTheme().theme.baseColor.base1 },
          ]}
        >
          {children}
        </View>
      </View>
    </View>
  );
};

export default Common;

const styles = StyleSheet.create({
  viewContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
  },
  imageStyle: {
    width: "70%",
    height: "70%",
    marginTop: 150,
    alignSelf: "center",
    // alignItems: "center",
  },
  viewStyle: { flex: 1 },
  rightContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
