import { StyleSheet, Text, View } from "react-native";
import React from "react";
import SelectDropdown from "react-native-select-dropdown";
import Icon from "~components/molecules/Icon";

const org = ["Egypt", "Canada", "Australia", "Ireland", "India"];
const Dropdown = ({ list, setter, defaultText }) => {
  return (
    <View>
      <SelectDropdown
        renderDropdownIcon={(isOpened) => {
          return (
            <Icon
              name={isOpened ? "chevron-up" : "chevron-down"}
              color={"grey"}
              size={12}
              family={"font-awesome"}
            />
          );
        }}
        buttonStyle={styles.buttonStyle}
        defaultButtonText={defaultText}
        buttonTextStyle={styles.buttonTextStyle}
        dropdownStyle={styles.dropdownStyle}
        rowStyle={styles.rowStyle}
        data={list}
        onSelect={(selectedItem, index) => {
          console.log(selectedItem, index);
        }}
        buttonTextAfterSelection={(selectedItem, index) => {
          setter(selectedItem);
          return selectedItem;
        }}
        rowTextForSelection={(item, index) => {
          return item;
        }}
      />
    </View>
  );
};

export default Dropdown;

const styles = StyleSheet.create({
  buttonStyle: {
    width: "100%",
    height: 55,
    borderColor: "#979797",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRadius: 9,
    alignSelf: "center",
    backgroundColor: "#FFFFFF",
  },
  buttonTextStyle: {
    textAlign: "left",
    color: "#808080",
    fontSize: 14,
  },
  dropdownStyle: {
    borderColor: "#979797",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRadius: 9,
    backgroundColor: "#FFFFFF",
    paddingVertical: 15,
  },
  rowStyle: {
    borderBottomColor: "#979797",
    paddingVertical: 15,
  },
});
