import { StyleSheet, Text, View, Image } from 'react-native'
import Link from '~components/atoms/Link'
import Common from '~components/molecules/commonpages/index.web'
import { useNavigation } from '@react-navigation/native'
import React, { useState, useEffect } from 'react'
import { i18n } from '~Utils/i18n'
import { useResolution } from '~helpers/utilities'
// import styles from './styleWeb.js'
import { horizontalScale } from '~helpers/basicStyles'

const SignInSuccess = () => {
  const resolution = useResolution()
  const navigation = useNavigation()
  const [aspectRatio, setAspectRatio] = useState(0)
  const handleOnLoad = (e) => {
    setAspectRatio(
      e.nativeEvent.path[0].naturalWidth / e.nativeEvent.path[0].naturalHeight
    )
  }
  useEffect(() => {
    setTimeout(() => {
      console.log('nav')
      navigation.navigate('Home')
    }, 3000)
  }, [])
  return (
    <Common>
      <View style={styles.container}>
        <View style={resolution !== 'small' ? [{ marginVertical: 30 }] : []}>
          <Image
            onLoad={handleOnLoad}
            source={{
              uri: 'https://s3.eu-north-1.amazonaws.com/live-media.leadx.no/Leadx-staging/users/1655113413413_users.png',
            }}
            style={[
              styles.logoStyle,
              {
                aspectRatio: aspectRatio,
              },
            ]}
            resizeMode={'contain'}
          />
          <View style={styles.textCenter}>
            <Text style={styles.title}>{i18n.t('signInSuccess.success')}</Text>
            <Image
              style={styles.img}
              source={require('../../Assets/Images/Success.png')}
            />
            <Text style={styles.message}>
              {i18n.t('signInSuccess.redirect')}
            </Text>
          </View>
        </View>
      </View>
    </Common>
  )
}

export default SignInSuccess

const styles = StyleSheet.create({
  logoStyle: {
    width: horizontalScale(70),
    alignSelf: 'center',
  },
  container: {
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    marginHorizontal: 15,
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: 25,
    marginVertical: 10,
    elevation: 8,
    width: '70%',
    height: '85%',
  },
  img: {
    width: 50,
    height: 50,
    alignSelf: 'center',
    marginBottom: 25,
  },
  message: {
    fontSize: 18,
    fontFamily: 'OpenSans_400Regular',
    fontWeight: '400',
    color: 'rgba(0,0,0,1)',
  },
  title: {
    fontSize: '18px',
    fontFamily: 'OpenSans_400Regular',
    fontWeight: '400',
    color: 'rgba(0,0,0,1)',
    marginBottom: 25,
    marginTop: 20,
  },
  textCenter: {
    textAlign: 'center',
  },
})
