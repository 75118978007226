import { StyleSheet } from "react-native";
import { horizontalScale } from "~helpers/basicStyles";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    paddingHorizontal: 20,
    marginHorizontal: 15,
    justifyContent: "center",
    alignSelf: "center",
    borderRadius: 25,
    marginVertical: 10,
    elevation: 8,
    width: "70%",
  },
  headerStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 35,
    marginHorizontal: 20,
  },
  viewStyle: {
    // justifyContent: "center",
  },
  viewContainer: {
    alignItems: "center",
  },
  heading: {
    fontSize: 25,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    color: "rgba(0,0,0,1)",
    marginBottom: 2,
  },
  title: {
    fontSize: 20,
    fontFamily: "OpenSans_400Regular",
    fontWeight: "400",
    color: "rgba(0,0,0,1)",
    marginBottom: 35,
  },
  text: {
    marginBottom: 10,
    fontWeight: "700",
    fontSize: 16,
    fontFamily: "OpenSans_400Regular",
  },
  inputStyle: {
    marginBottom: 20,
  },
  accountStyle: {
    color: "#8D8D8D",
    fontSize: 16,
  },
  mainView: {
    marginBottom: 20,
    marginHorizontal: 20,
  },
  input: {
    paddingHorizontal: 15,
  },
  linkStyle: {
    textDecorationLine: "underline",
    fontSize: 14,
    alignSelf: "flex-end",
  },
  viewLink: {
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 30,
  },
  buttonView: {
    // marginTop: 30,
    marginBottom: 50,
  },
  button: {
    marginHorizontal: 20,
  },
  imageStyle: {
    width: 190,
    marginBottom: 10,
    height: 190,
  },
  imageView: {
    alignSelf: "center",
  },
  logoStyle: {
    width: horizontalScale(70),
    alignSelf: "center",
  },
  test: {
    alignSelf: "flex-end",
    fontSize: 14,
  },
  testWrap: {
    flexWrap: "wrap",
  },
});

export default styles;
