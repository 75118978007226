import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import Fancy from "../Modals/Fancy";
import Button from "~components/atoms/Button";
import Icon from "~components/molecules/Icon";
import { useNavigation } from "@react-navigation/native";

const PopUp = ({ toggle, setToggle, children }) => {
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <Fancy visible={toggle}>
        <>
          <TouchableOpacity
            onPress={() => setToggle(false)}
            style={styles.View}
          />
          {children}
        </>
      </Fancy>
    </View>
  );
};

export default PopUp;

const styles = StyleSheet.create({
  View: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  container: {
    padding: 40,
  },
});
