import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import Profile from "../Avatar";
import { getInitials } from "~helpers/utilities";
import moment from "moment";
import styles from "./style.web.js";

interface Props {
  name: string;
  description?: string;
  timeSince?: string;
  onPress?: () => void;
}
const CardItem: React.FC<Props> = ({
  name,
  description,
  timeSince,
  onPress,
}) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.cardStyle}>
      <View style={{ flexDirection: "row" }}>
        <View>
          <Image
            style={styles.imageStyle}
            source={{
              uri: "https://www.apac-insider.com/wp-content/uploads/2021/11/Business-Growth-1.jpg",
            }}
            // resizeMode={"contain"}
          />
        </View>
        <View style={{ marginLeft: 10, maxWidth: "78%" }}>
          <Text numberOfLines={1} style={styles.nameStyle}>
            {name}
          </Text>
          <Text style={styles.descStyle} numberOfLines={2}>
            {
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            }
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default CardItem;
