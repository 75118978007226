import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  cardStyle: {
    borderColor: "grey",
    borderWidth: 1,
    borderRadius: 8,
    marginHorizontal: 22,
    marginBottom: 10,
    backgroundColor: "white",
    padding: 10,
  },
  viewStyle: {
    top: 5,
    right: 10,
    flexDirection: "row",
  },
  timeStyle: {
    color: "grey",
    fontSize: 10,
  },
  profileView: {
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  imageStyle: {
    width: 90,
    height: 90,
    borderRadius: 8,
  },
  labelView: {
    marginLeft: 10,
  },
  nameStyle: {
    fontWeight: "700",
    fontSize: 14,
  },
  orgStyle: {
    fontSize: 12,
  },
  descStyle: {
    color: "grey",
    fontSize: 12,
    marginTop: 10,
    // padding: 10,
  },
});
export default styles;
