import { StyleSheet } from "react-native";
import React from "react";
import { Avatar } from "react-native-paper";

const Profile = ({ imageURL, initials, size, style }) => {
  return (
    <>
      {imageURL ? (
        <Avatar.Image
          source={{
            uri: imageURL,
          }}
          size={size}
          style={[{ backgroundColor: "grey" }, style]}
        />
      ) : (
        <Avatar.Text
          size={size}
          label={initials}
          style={[{ backgroundColor: "grey" }, style]}
        />
      )}
    </>
  );
};

export default Profile;

const styles = StyleSheet.create({});
