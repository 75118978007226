import { Dimensions, StyleSheet, ScrollView } from "react-native";
import React from "react";
import { useResolution } from "~helpers/utilities";
import Layout from "~components/atoms/Layout/index.web";
import NewsCards from "~components/atoms/NewsCards/index.web";
import Heading from "~components/atoms/Heading";

const newsList = [
  { heading: "Vedanta news", index: 1 },
  { heading: "Vedanta news", index: 2 },
  { heading: "Vedanta news", index: 3 },
  { heading: "Vedanta news", index: 4 },
  { heading: "Vedanta news", index: 5 },
];

// const width = Dimensions.get("window").width/2;
const News = () => {
  const res = useResolution();
  return (
    <Layout>
      <Heading style={{ paddingLeft: 55 }}>NEWS</Heading>
      <ScrollView
        contentContainerStyle={[
          styles.containerStyle,
          res === "xsmall" && {
            maxWidth: Dimensions.get("window").width,
          },
        ]}
        showsVerticalScrollIndicator={false}
      >
        {newsList.map((data) => (
          <NewsCards data={data} />
        ))}
      </ScrollView>
    </Layout>
  );
};

export default News;

const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: Dimensions.get("window").width - 280,
    paddingLeft: 50,
  },
  cardHeading: {
    fontWeight: "bold",
    fontSize: 15,
    marginBottom: 10,
  },
  img: {
    height: 200,
    width: "100%",
    borderRadius: 5,
    alignSelf: "center",
  },
  desc: {
    color: "grey",
    marginVertical: 10,
  },
});
