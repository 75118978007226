import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import * as React from "react";
import { useSelector } from "react-redux";
import { Home, Login, ProfileScreen, ResetPassword, SignUp } from "~Screen";
import MainTabs from "~components/molecules/BottomTab";
import { useAuth } from "~Contexts/Auth";
import MFA from "~Screen/MfaScreen";
import { Platform } from "react-native";
import HomeStack from "~components/molecules/HomeStack";
import MyPipeline from "~Screen/MyPipeline";
import SignUpSuccess from "~Screen/SignUpSuccess";
import ResetSuccess from "~Screen/ResetSuccess";
import SignInSuccess from "~Screen/SignInSuccess";
import ForgotPassword from "~Screen/ForgotPassword";

const Stack = createStackNavigator();

export const AppNavigation = () => {
  const { isLoggedIn } = useAuth();

  const renderAuthStack = () => {
    return (
      <>
        <Stack.Screen name="Login" component={Login} />
        <Stack.Screen name="MFA" component={MFA} />
        <Stack.Screen name="SignUp" component={SignUp} />
        <Stack.Screen name="ResetPassword" component={ResetPassword} />
        <Stack.Screen name="SignUpSuccess" component={SignUpSuccess} />
        <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
        <Stack.Screen name="ResetSuccess" component={ResetSuccess} />
        <Stack.Screen name="SignInSuccess" component={SignInSuccess} />
      </>
    );
  };

  const renderHome = () => {
    if (Platform.OS === "web") {
      return <Stack.Screen name="LeadX" component={HomeStack} />;
    } else {
      return <Stack.Screen name="HomeTabs" component={MainTabs} />;
    }
  };
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        animationEnabled: Platform.OS === "web" ? false : true,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
    >
      {!isLoggedIn ? renderAuthStack() : renderHome()}
    </Stack.Navigator>
  );
};
