import React from "react";
import { ref, onValue } from "firebase/database";
import { getFirebaseDB } from "~Utils/getFirebaseDB";
import Services from "~Services";
import SecureStorage from "~Utils/SecureStorage";
import { USER_KEY } from "~Services/Api/constants";

export default function useNotificationCountLiveSync() {
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    const db = getFirebaseDB();

    //async for securestorage
    const caller = async () => {
      await SecureStorage.getItemJSON(USER_KEY).then((res) => {
        console.log("response is here", res);

        if (!res) return;

        const id: number = res.id;
        console.log("id", id);

        const reference = ref(db, `notifications/${id}`);
        onValue(reference, (snapshot) => {
          console.log(snapshot.val());
          setValue(snapshot.val());
        });
      });
    };
    //calling async operation
    caller();
  }, []);

  return { value };
}
