import Api from "./Api";
import apiUrl from "../constants/apiURLs";
import { IRowsLightWithCount } from "~Types/ApiResponse";
import { Account, IAdditionalAttributes, IPermission, IUser } from "./Types";

export interface ILicenseTypeIds {
  license_type_ids: number[];
}

interface IUserByAccount {
  count: number;
  rows: UserByAccount[];
}

interface UserByAccount {
  initials: string;
  first_name: string;
  last_name: string;
  name: string;
  name_without_initials: string;
  gender: string;
  id: number;
  user_avatar: null | string;
  license_type: LicenseType;
}

interface LicenseType {
  id: number;
  actual_name: ActualName;
  display_name: DisplayName;
}

enum ActualName {
  Administrator = "ADMINISTRATOR",
  Basic = "BASIC",
  ExternalAPI = "EXTERNAL_API",
  Lite = "LITE",
  Manager = "MANAGER",
  Professional = "PROFESSIONAL",
}

enum DisplayName {
  Administrator = "Administrator",
  Basic = "Basic",
  ExternalAPI = "External API",
  Lite = "Lite",
  Manager = "Manager",
  Professional = "Professional",
}

interface IAccountId {
  account_id: number;
}

export interface IAccountResponse {
  data: Account[];
  status: boolean;
  msg: string;
}

export interface IUserInterface {
  getAdditionalAttributes(): Promise<
    IRowsLightWithCount<IAdditionalAttributes>
  >;
  getUserListByLicenseType(
    payload: ILicenseTypeIds
  ): Promise<IRowsLightWithCount<IUser>>;
  getUserListByAccount(
    payload: IAccountId
  ): Promise<IRowsLightWithCount<IUserByAccount>>;
  getAllPermissions(): Promise<IPermission[]>;
  getAllAccounts(): Promise<IRowsLightWithCount<IAccountResponse>>;
}

export default class User implements IUserInterface {
  async getAdditionalAttributes(): Promise<
    IRowsLightWithCount<IAdditionalAttributes>
  > {
    const result = await Api.post(apiUrl.user.additionalAttributes, {});
    return result.data;
  }

  async getUserListByLicenseType(
    payload: ILicenseTypeIds
  ): Promise<IRowsLightWithCount<IUser>> {
    const result = await Api.post(apiUrl.user.getUserList, payload);
    return result.data;
  }

  async getUserListByAccount(
    payload: IAccountId
  ): Promise<IRowsLightWithCount<IUserByAccount>> {
    const result = await Api.post(apiUrl.user.getUsersByAccount, payload);
    return result.data;
  }

  async getAllPermissions(): Promise<IPermission[]> {
    const result = await Api.post(apiUrl.user.getAllPermissions, {});
    return result.data;
  }

  async getAllAccounts(): Promise<IRowsLightWithCount<IAccountResponse>> {
    const result = await Api.post(apiUrl.user.getAllAccounts, {});
    return result.data;
  }
}
