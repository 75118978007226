import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  modal: {
    justifyContent: "flex-end",
    margin: 0,
    zIndex: 1,
  },
  scrollableModal: {
    backgroundColor: "white",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  scrollableModalContent1: {
    backgroundColor: "white",
    padding: 40,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  scrollableModalText1: {
    fontSize: 20,
    color: "white",
  },
  scrollableModalContent2: {
    height: 200,
    backgroundColor: "#A9DCD3",
    alignItems: "center",
    justifyContent: "center",
  },
  scrollableModalText2: {
    fontSize: 20,
    color: "white",
  },
  iconStyle: {
    position: "absolute",
    alignSelf: "center",
    zIndex: 100,
    top: -50,
    borderRadius: 40,
  },
  logoStyle: {
    alignSelf: "center",
  },
  viewStyle: {
    flexDirection: "row",
    marginBottom: 60,
    justifyContent: "space-between",
    marginHorizontal: 15,
  },
  viewStyle1: {
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 30,
  },
  textStyle: {
    fontSize: 14,
    fontWeight: "700",
    marginTop: 8,
  },
});
export default styles;
