import { Image, Text, TouchableOpacity, View } from "react-native";
import React, { useEffect, useState } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import Common from "~components/molecules/commonpages/index.web";
import Input from "~components/atoms/TextInput";
import Button from "~components/atoms/Button";
import styles from "./style.web";
import { i18n } from "~Utils/i18n";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Services from "~Services";
import { useMutation } from "react-query";
import Alert from "~components/atoms/Alert";
import { maskEmail } from "~helpers/utilities";
import { handleLogin } from "~Utils/handleLogin";
import Auth from "~Services/Auth";
import { useAuth } from "~Contexts/Auth";

const MFASchema = Yup.object().shape({
  code: Yup.string()
    .min(6, "Code is 6 characters long")
    .max(6, "Code is 6 characters long")
    .required("Code is required"),
});

const MFA = () => {
  const navigation = useNavigation();
  const [aspectRatio, setAspectRatio] = useState(0);
  const route = useRoute();
  const auth = useAuth();
  const email = route?.params?.email;
  const {
    error,
    mutate: resendOTP,
    data,
    isError,
    isSuccess,
  } = useMutation((email: string) => Services.auth.resendOTP(email), {
    onSuccess: (data) => {
      console.log("data", data);
    },
  });

  const {
    error: verifyError,
    mutate: verifyOTP,
    data: verifyData,
    isLoading,
    isError: verifyIsError,
    isSuccess: verifyIsSuccess,
  } = useMutation((payload) => Services.auth.verifyOTP(payload), {
    onSuccess: (data) => {
      console.log("data", data);
      handleLogin(data);
      auth.makeUserLogin();
    },
  });
  console.log("error, success", isError, isSuccess);
  useEffect(() => {
    handleResendOTP();
  }, []);
  const handleVerify = (otp) => {
    verifyOTP({ email, otp: otp });
  };
  const handleResendOTP = () => {
    resendOTP(email);
  };

  return (
    <Formik
      initialValues={{
        code: "",
      }}
      validationSchema={MFASchema}
      onSubmit={(values) => {
        handleVerify(values.code);
      }}
    >
      {({
        handleChange,
        setFieldValue,
        isValid,
        handleBlur,
        setFieldTouched,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
        <Common>
          <View style={styles.container}>
            <View style={{ marginVertical: 30 }}>
              <Image
                onLoad={(e) => {
                  setAspectRatio(
                    e.nativeEvent.path[0].naturalWidth /
                      e.nativeEvent.path[0].naturalHeight
                  );
                }}
                source={{
                  uri: "https://s3.eu-north-1.amazonaws.com/live-media.leadx.no/Leadx-staging/users/1655113413413_users.png",
                }}
                style={[
                  styles.logoStyle,
                  {
                    aspectRatio: aspectRatio,
                  },
                ]}
                resizeMode={"contain"}
              />
            </View>
            <Text style={styles.centerHeading}>{i18n.t("mfa.enterCode")}</Text>
            <Text style={styles.centerHeading}>
              {i18n.t("mfa.email")}
              {maskEmail(email)}
            </Text>
            <Text style={styles.inputText}>{i18n.t("mfa.code1")}</Text>
            <Input
              style={styles.input}
              placeholder={i18n.t("mfa.placeHolder")}
              secureTextEntry={true}
              value={values.code}
              onChangeText={handleChange("code")}
              onBlur={() => setFieldTouched("code")}
            />
            {touched.code && errors.code && (
              <Text style={styles.error}>{errors.code}</Text>
            )}
            <View style={styles.viewCenter}>
              <View>
                <Text style={styles.validationStyle}>
                  {i18n.t("mfa.valid")}
                </Text>
              </View>
              <View>
                <Text style={styles.validationStyle}>
                  {i18n.t("mfa.code2")}
                </Text>
                <TouchableOpacity onPress={handleResendOTP}>
                  <Text style={styles.linkStyle}>
                    {i18n.t("mfa.resendCode")}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.buttonView}>
              <Button
                loading={isLoading}
                style={styles.button}
                title={i18n.t("mfa.verify")}
                onPress={handleSubmit}
                disabled={!isValid || values.code === ""}
              />
            </View>
          </View>
          {isSuccess && (
            <Alert visible={isSuccess} text={data} type="success" />
          )}
          {isError && error instanceof Error && (
            <Alert visible={isError} text={error.message} />
          )}
          {verifyIsError && verifyError instanceof Error && (
            <Alert visible={verifyIsError} text={verifyError.message} />
          )}
        </Common>
      )}
    </Formik>
  );
};

export default MFA;
