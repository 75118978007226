import {
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import Icon from "../Icon";
import { useResolution } from "~helpers/utilities";
import { useTheme } from "~Contexts/Theme";
import { i18n } from "~Utils/i18n";

const nameMappings = {
  home: i18n.t("leftmenu.home"),
  news: i18n.t("leftmenu.newsfeeds"),
  activity: i18n.t("leftmenu.activities"),
  lead: i18n.t("leftmenu.lead"),
  leadProjects: "Lead Projects",
  campaigns: i18n.t("leftmenu.campaigns"),
  dashboard: "Dashboard",
  admin: i18n.t("leftmenu.administration"),
};
interface DrawerProps {
  setter: any;
}
const imageNames = [
  { name: "home", screen: "Home" },
  { name: "news", screen: "News" },
  { name: "activity", screen: "Activity" },
  { name: "lead", screen: "Lead" },
  { name: "leadProjects", screen: "LeadProjects" },
  { name: "campaigns", screen: "Campaigns" },
  { name: "dashboard", screen: "Dashboard" },
  { name: "admin", screen: "Administration" },
];
const Drawer: React.FunctionComponent<DrawerProps> = ({ setter }) => {
  const res = useResolution();
  const theme = useTheme().theme;
  const handleSelection = (id) => {
    setter(id);
  };
  return (
    <View style={[styles.containerStyle]}>
      <Image
        source={{
          uri: "https://s3.eu-north-1.amazonaws.com/live-media.leadx.no/Leadx-staging/users/1655113413413_users.png",
        }}
        style={[styles.imageStyle, res === "xsmall" && { width: 70 }]}
        resizeMode={"contain"}
      />
      <View>
        {imageNames.map((image, index) => (
          <View key={image.name}>
            <TouchableOpacity
              style={styles.imageView}
              onPress={() => handleSelection(index)}
            >
              <>
                <Image
                  source={require(`../../../Assets/Images/${image.name}.png`)}
                  style={styles.iconStyle} // Left menu icons
                  resizeMode={"contain"}
                />
                <Text style={styles.textStyle}>{nameMappings[image.name]}</Text>
              </>
            </TouchableOpacity>
          </View>
        ))}
      </View>
      <View style={styles.logoView}>
        <Text style={styles.footerStyle}>Powered by </Text>
        {/* <Image
          // onLoad={handleOnLoad}
          source={{
            uri: "https://s3.eu-north-1.amazonaws.com/live-media.leadx.no/Leadx-staging/users/1655113413413_users.png",
          }}
          style={[
            {
              // aspectRatio: aspectRatio,
              width: 110,
              height: 45,
            },
          ]}
          resizeMode={"contain"}
        /> */}
        <Text style={{ fontWeight: "700", color: "grey" }}>LeadX</Text>
      </View>
    </View>
  );
};

export default Drawer;

const styles = StyleSheet.create({
  containerStyle: {
    width: 250,
    backgroundColor: "white",
    paddingLeft: 40,
    height: Dimensions.get("window").height - 5,
  },
  imageStyle: {
    height: 65,
    width: 125,
    marginVertical: 22,
  },
  imageView: {
    marginVertical: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  iconStyle: {
    width: 30,
    height: 30,
  },
  textStyle: {
    marginLeft: 30,
    fontWeight: "600",
  },
  logoView: {
    flexDirection: "row",
    bottom: 15,
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
  },
  footerStyle: {
    fontSize: 14,
    color: "grey",
  },
  logoStyle: {
    fontSize: 18,
    color: "grey",
    fontWeight: "900",
    fontStyle: "italic",
  },
});
