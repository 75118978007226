import account from "./Account.svg";
import password from "./Password.svg";
import signin from "./signin.svg";
import news from "./news.svg";
import activity from "./activity.svg";
import lead from "./lead.svg";
import notification from "./notification.svg";
import campaign from "./campaign.svg";
import dashboard from "./dashboard.svg";
import leadproject from "./leadproject.svg";
import search from "./search.svg";
import feeds from "./feeds.svg";
import chat from "./chat.svg";
import contact from "./contact.svg";
import company from "./company.svg";
import hifi from "./hifi.svg";
import userpic from "./userpic.svg";
import filter from "./filter.svg";
import sync from "./sync.svg";
import edit from "./edit.svg";

export const Icons = {
  account,
  password,
  signin,
  lead,
  news,
  activity,
  notification,
  search,
  campaign,
  leadproject,
  dashboard,
  feeds,
  chat,
  contact,
  company,
  hifi,
  userpic,
  filter,
  sync,
  edit
};
