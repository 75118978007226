import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import Modal from "react-native-modal";
import LinearGradient from "react-native-linear-gradient";

interface FancyModalProps {
  visible: boolean;
  children: any;
}

const Fancy: React.FunctionComponent<FancyModalProps> = ({
  visible,
  children,
}) => {
  return (
    <Modal
      testID={"modal"}
      isVisible={visible}
      backdropColor="#000"
      backdropOpacity={0.8}
      animationIn="zoomInDown"
      animationOut="zoomOutDown"
      animationInTiming={600}
      animationOutTiming={600}
      backdropTransitionInTiming={600}
      backdropTransitionOutTiming={1000}
      hideModalContentWhileAnimating={true}
      useNativeDriver={true}
      useNativeDriverForBackdrop={true}
    >
      {children}
    </Modal>
  );
};

export default Fancy;

const styles = StyleSheet.create({});
