import Api from "./Api";
import apiUrl from "../constants/apiURLs";
import { IRowsLightWithCount } from "~Types/ApiResponse";
import { ILead, ISection, ILeadDocument, ILeadDocumentSource } from "./Types";

export interface ILeadInterface {
  getAllSections(): Promise<IRowsLightWithCount<ISection>>;
  createLead(paylod: ILead): Promise<IRowsLightWithCount<ISection>>;
  addLeadDocument(
    paylod: ILeadDocumentSource
  ): Promise<IRowsLightWithCount<ILeadDocument>>;
  getCardView(
    payload: ICardView
  ): Promise<IRowsLightWithCount<ICardViewResult>>;
}

interface ICardView {
  revenueType: string;
  organizationArray: number[];
  user_id: number;
  license_type: string;
}

interface ICardViewResult {
  account: Account;
  cards: Card[];
}

interface Account {
  id: number;
  logo: string;
  name: string;
  currency: Currency;
}

interface Currency {
  id: number;
  short_name: string;
}

interface Card {
  title: string;
  subtitle: string;
  info: Info;
  hero_icon_url: string;
  kpis: Kpi[];
}

interface Info {
  title: string;
  content: string;
}

interface Kpi {
  label: string;
  value: string;
  highlighted: boolean;
  icon_url: null | string;
}

export interface ILeadFilter {
  from: string;
  arrayFilters: ArrayFilter[];
  selectFilters: string[];
  sort: Sort;
  paginate: Paginate;
  organizationArray: number[];
  user_id: number;
  license_type: string;
}

export interface ArrayFilter {
  lead_current_status_id: number[];
}

export interface Paginate {
  page: string;
  limit: string;
}

export interface Sort {
  field: string;
  sortOrder: string;
}

export interface ILeadSummaryResponse {
  count: number;
  rows: LeadSummary[];
}

export interface LeadSummary {
  id: number;
  lead_title: string;
}

export default class Lead implements ILeadInterface {
  async getAllSections(): Promise<IRowsLightWithCount<ISection>> {
    const result = await Api.post(apiUrl.lead.getSections, {});
    return result.data;
  }

  async createLead(paylod: ILead): Promise<IRowsLightWithCount<ISection>> {
    const result = await Api.postWithToken(apiUrl.lead.createLead, paylod);
    return result.data;
  }

  async getLeadsByFilter(
    paylod: ILeadFilter
  ): Promise<IRowsLightWithCount<ILead>> {
    const result = await Api.post(apiUrl.lead.getLeadsByFilter, paylod);
    return result.data;
  }

  async addLeadDocument(
    paylod: ILeadDocumentSource
  ): Promise<IRowsLightWithCount<ILeadDocument>> {
    const result = await Api.post(apiUrl.lead.addLeadDocument, paylod);
    return result.data;
  }

  async getCardView(
    payload: ICardView
  ): Promise<IRowsLightWithCount<ICardViewResult>> {
    const result = await Api.post(apiUrl.lead.getCardView, payload);
    return result.data;
  }

  async getLeadsByCompany(
    payload: ILeadFilter
  ): Promise<IRowsLightWithCount<ILeadSummaryResponse>> {
    const result = await Api.post(apiUrl.lead.getLeadsByCompany, payload);
    return result.data;
  }
}
