import { StyleSheet, Text, View } from "react-native";
import React from "react";

const NewsDetail = () => {
  return (
    <View>
      <Text>NewsDetail</Text>
    </View>
  );
};

export default NewsDetail;

const styles = StyleSheet.create({});
