import { Image, StyleSheet, Text, View } from "react-native";
import React from "react";
import Layout from "~components/atoms/Layout/index.web";
import Heading from "~components/atoms/Heading";
import Input from "~components/atoms/TextInput";
import * as Yup from "yup";
import { Formik, yupToFormErrors } from "formik";
import Button from "~components/atoms/Button";
import Dropdown from "~components/atoms/Dropdown";

const leadFormSchema = Yup.object().shape({
  leadTitle: Yup.string().required("Please enter Lead title"),
  company: Yup.string().required("Company name is require"),
  contact: Yup.string(),
  email: Yup.string().email(),
  phone: Yup.string().max(10, 'Phone number should not excced 10 numbers.').required(),
  projectLocation: Yup.string(),
  leadSource: Yup.string(),
  linkedinURL: Yup.string().required("Company name is require"),
});

const basicInfo = [
  {
    title: "Lead Title", index: 1, 
    placeholder: "Enter Lead Title", formId: "leadTitle"
  },
  {
    title: "Company", index: 2, 
    placeholder: "Enter or Select Company", formId: "company"
  },
  {
    title: "Contact", index: 3, 
    placeholder: "Enter or Select Contact", formId: "contact"
  },
  {
    title: "Email", index: 4, 
    placeholder: "Enter Email Address",formId: "email"
  },
  {
    title: "Phone / Mobile", index: 5, 
    placeholder: "Enter Phone or Mobile Number",formId: "phone"
  },
];

const additionalDetails = [
  {
    name: "Company", index: 1, formId: "company", 
    placeholder: "Enter Lead Title"
  },
  {
    name: "Project Location", index: 1, formId: "projectLocation",
    placeholder: "Enter Lead Title"
  },
  {
    name: "Lead source", index: 1, formId: "leadSource",
    placeholder: "Enter Lead Title"
  },
  {
    name: "Linkedin URL", index: 1, formId: "linkedinURL",
    placeholder: "Enter Lead Title"
  },
];

const leadAssignment = [
  {name: "Organization"},
  {name: "Team Member"}
];

const CreateLead = () => {
  return (
    <Formik
      validationSchema={leadFormSchema}
      initialValues={{
        leadTitle: "",
        company: "",
        contact: "",
        email: "",
        phone: "",
        projectLocation: "",
        leadSource: "",
        linkedinURL:""
      }}
      onSubmit={(values) => {
        console.log("values", values);
      }}
    >
      {({
        handleChange,
        setFieldValue,
        isValid,
        handleBlur,
        setFieldTouched,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
    <Layout>
      <View style={styles.container}>
        <View style={styles.cancel}>
          <Text style={{marginBottom: 5}}>Cancel</Text>
          <Image style={styles.img} source={require('../../../Assets/Images/cancel.png')}/>
        </View>
      <View style={styles.box}>
      <Heading style={styles.heading}>POST A LEAD</Heading>
      <View>
      <Text style={styles.basicInfo}>Basic Information</Text>
      <View style={styles.underline}></View>
        {basicInfo.map((data)=> (
          <View style={styles.boxInfo}>
            <Text style={styles.label}>{data.title}</Text>
            <Input
              placeholder={data.placeholder}
              style={styles.input}
              value={values[data.formId]}
              onChangeText={handleChange(`${data.formId}`)}
              onBlur={handleBlur(`${data.formId}`)}
            />
             {touched[data.formId] && errors[data.formId] && (
                      <Text style={{ color: "red" }}>
                        {errors[data.formId]}
                      </Text>
                    )}
          </View>
        ))}
      </View>
        <Text style={styles.basicInfo}>Upload Documents</Text>
        <Button title="Browse" style={styles.browseBtn} />
        <Text style={styles.fileFormat}>Only .jpg,, .png, .doc, .ppt, .pdf</Text>
        <View>
        <Text style={styles.basicInfo}>Pipeline</Text>
        <View style={styles.underline}></View>
        </View>
        <View>
        <Text style={styles.basicInfo}>Additional Details (Optional)</Text>
        <View style={styles.underline}></View>
          {additionalDetails.map((data)=>(
            <View style={styles.boxInfo}>
              <Text>{data.name}</Text>
              <Input
              placeholder={data.placeholder}
              style={styles.input}
              value={values[data.formId]}
              onChangeText={handleChange(`${data.formId}`)}
              onBlur={handleBlur(`${data.formId}`)}
            />
             {touched[data.formId] && errors[data.formId] && (
                      <Text style={{ color: "red" }}>
                        {errors[data.formId]}
                      </Text>
                    )}
            </View>
          ))}
        </View>
        <View>   
        <Text style={styles.basicInfo}>Lead Assignment</Text>
        <View style={styles.underline}></View>
        <View style={styles.inputs}>
        {leadAssignment.map((data)=>(
          <View style={styles.dropdownBtn}>
            <Text style={styles.dropdown}>{data.name}</Text>
            <Dropdown list={undefined} setter={undefined} defaultText={undefined}/>
          </View>
        ))}
        </View>
        </View>  
        <Button style={styles.btn} title="Send"/>

        </View>   
      </View>
    </Layout>
      )}
      </Formik>
  );
};

export default CreateLead;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: '25%',
    paddingVertical: 50
  },
  img: {
    height:15,
    width:15,
    marginLeft: 8
  },
  cancel: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 20
  },
  heading: {
    marginLeft: '40%'
  },
  basicInfo: {
    fontWeight: 'bold',
    marginLeft: 20,
    margin: 5,
  },
  underline: {
    height: '0.5%',
    marginHorizontal: '3%',
    backgroundColor: '#D9D9D9'
  },
  box: {
    backgroundColor: 'white',
    marginVertical: 8,
    marginHorizontal: 20,
    borderWidth: 0.5,
    borderRadius: 10
  },
  boxInfo: {
    marginVertical: 5,
    marginHorizontal: 20,
  },
  input: {
    height: 25
  },
  label: {
    fontWeight: 'bold'
  },
  browseBtn: {
    width: '25%',
    marginLeft: 40,
    backgroundColor: '#D9D9D9',
    color: 'black',
    marginTop: 8
  },
  fileFormat: {
    color: '#D9D9D9',
    marginLeft: 30,
  },
  inputs: {
    flexDirection: 'row',
    marginVertical: 8,
    marginHorizontal: 20,
    borderWidth: 0.5,
    borderRadius: 5
  },
  dropdown: {
    fontWeight: 'bold',
    margin:5
  },
  dropdownBtn: {
    marginLeft: '15%',
    marginVertical: 10
  },
  btn:{
    width: 70,
    height: 50,
    marginLeft: '40%'
  }
});
